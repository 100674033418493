<div>
  <eb-navbar-logout> </eb-navbar-logout>
</div>
<div class="container pt-lg-5 pb-lg-5">
  <form class="fleet-cards mt-4 mx-lg-0 pt-lg-4 pb-lg-4 row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4 col-mw-login-modal">
      <div
        class="border-0 card login-info p-5 pb-lg-4 pl-lg-4 pr-lg-4 pt-lg-4 shadow text-center"
        style="border-top: 6px solid #f72717 !important; border-radius: 15px">
        <div class="align-items-center mt-lg-2 row">
          <div class="text-center">
            <img src="assets/images/logout.svg" alt="logout" />
          </div>
        </div>
        <div class="align-items-center mt-lg-4 row">
          <div class="text-center pl-0 pr-0">
            <h3 class="font-weight-600">You've been successfully logged out!</h3>
          </div>
        </div>
        <div class="align-items-center mt-lg-4 row">
          <h6 class="para-nrmal-txt">Still have things to do?</h6>
        </div>
        <div class="align-items-center mt-3 mx-0 row">
          <div class="col-12 col-xl-12 text-center text-lg-center">
            <button
              (click)="login()"
              aria-label="login"
              class="bold btn btn-primary d-inline font-12 font-ubuntu font-weight-bold pb-lg-2 pl-lg-3 pr-lg-3 pt-lg-2 text-center">
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </form>
</div>
