import { ActionReducerMap } from '@ngrx/store';
import * as fromCard from './card/card.reducer';
import * as fromClaim from './claim/claim.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromContent from './content-management/content-management.reducer';
import * as fromMember from './member/member.reducer';
import * as fromProduct from './product/product.reducer';
import * as fromShoppingCart from './shopping-cart/shopping-cart.reducer';
import * as fromSubsidy from './subsidy/subsidy.reducer';
export interface AppState {
  claimFeature: fromClaim.ClaimState;
  cardFeature: fromCard.CardState;
  memberFeature: fromMember.MemberState;
  companyFeature: fromCompany.CompanyState;
  contentManagementFeature: fromContent.ContentManagementState;
  productFeature: fromProduct.ProductState;
  shoppingCartFeature: fromShoppingCart.ShoppingCartState;
  subsidyFeature: fromSubsidy.SubsidyState;
}

export const appReducers: ActionReducerMap<AppState> = {
  claimFeature: fromClaim.claimReducer,
  cardFeature: fromCard.cardReducer,
  memberFeature: fromMember.memberReducer,
  companyFeature: fromCompany.companyReducer,
  contentManagementFeature: fromContent.contentManagementReducer,
  productFeature: fromProduct.productReducer,
  shoppingCartFeature: fromShoppingCart.shoppingCartReducer,
  subsidyFeature: fromSubsidy.subsidyReducer,
};
