import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContent from '../content-management/content-management.reducer';

export interface State {
  contentFeature: fromContent.ContentManagementState;
}
export const ContentManagementReducers: ActionReducerMap<State> = {
  contentFeature: fromContent.contentManagementReducer,
};

const getContentFeatureState = createFeatureSelector<fromContent.ContentManagementState>('contentManagementFeature');

export const getContent = createSelector(getContentFeatureState, state => {
  return state.content;
});
