<div
  class="toast show fade"
  [ngClass]="getToastrTypeClass(toastr)"
  role="alert"
  aria-live="assertive"
  aria-atomic="true">
  <div class="toast-header" [ngClass]="getToastrTypeClass(toastr)">
    <i class="fas fa-lg me-2" [ngClass]="getToastrIconClass(toastr)"></i>
    <strong class="me-auto">{{ toastr.toUpperCase() }}</strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="notificationRef.close()"></button>
  </div>
  <div class="toast-body">{{ text }}</div>
</div>
