import { Component } from '@angular/core';

@Component({
  selector: 'eb-navbar-logout',
  templateUrl: './navbar-logout.component.html',
  styleUrls: ['./navbar-logout.component.scss']
})
export class NavbarLogoutComponent {

}
