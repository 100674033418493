import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivationCardDTO } from '../models/app';
import {
  CreditToPCCCFundTransferObject,
  DebitCardDTO,
  LostReportFilterDTO,
  PCCCReportLostResponseDTO,
  PurseDTO,
  TransactionDTO,
  TransactionSummaryDTO,
} from '../models/card';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  apiName = 'cardService';
  cards$!: Observable<DebitCardDTO[]>;
  private cards: DebitCardDTO[] = [];
  private selectedCard: DebitCardDTO;
  constructor(private http: HttpClient) {}
  public get getCards(): DebitCardDTO[] {
    return this.cards;
  }

  public set setCards(cards: DebitCardDTO[]) {
    this.cards = cards;
  }
  public get getSelectedCard(): DebitCardDTO {
    return this.selectedCard;
  }

  public set setSelectedCard(card: DebitCardDTO) {
    this.selectedCard = card;
  }
  /**
   * @return Success
   */
  getCardsByIdMember(idMember: number): Observable<DebitCardDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/card/{idMember}/cards';
    url_ = url_.replace('{idMember}', idMember.toString());
    return this.http.get<DebitCardDTO[]>(url_);
  }
  /**
   * @return Success
   */
  getCardByIdCard(idCard: number): Observable<DebitCardDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/card/{idCard}';
    url_ = url_.replace('{idCard}', idCard.toString());
    return this.http.get<DebitCardDTO>(url_);
  }
  /**
   * @return Success
   */
  getDebitCardByIdCard(idCard: number): Observable<DebitCardDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/card/debit-card/{idCard}';
    url_ = url_.replace('{idCard}', idCard.toString());
    return this.http.get<DebitCardDTO>(url_);
  }
  /**
   * @return Success
   */
  getPurseByIdMember(idMember: number): Observable<PurseDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Purse/{idMember}';
    url_ = url_.replace('{idMember}', idMember.toString());
    return this.http.get<PurseDTO[]>(url_);
  }

  /**
   * @param startDate (optional)
   * @param endDate (optional)
   * @return Success
   */
  getTransactionSummaryByCard(
    idCard: number,
    startDate: string | undefined,
    endDate: string | undefined
  ): Observable<TransactionSummaryDTO> {
    let url_ = AppConfigService.apiURL + '/Transaction/transaction-summary-by-card?';
    url_ += 'IdCard=' + idCard + '&';
    url_ += 'StartDate=' + startDate + '&';
    url_ += 'EndDate=' + endDate;
    return this.http.get<TransactionSummaryDTO>(url_);
  }
  /**
   * @param startDate (optional)
   * @param endDate (optional)
   * @return Success
   */
  getTransactionSummaryByMember(
    idMember: number,
    startDate?: string | undefined,
    endDate?: string | undefined
  ): Observable<TransactionSummaryDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Transaction/transaction-summary-by-member?';
    url_ += 'IdMember=' + idMember + '&';
    url_ += 'StartDate=' + startDate + '&';
    url_ += 'EndDate=' + endDate;
    return this.http.get<TransactionSummaryDTO>(url_);
  }
  /**
   * @param startDate (optional)
   * @param endDate (optional)
   * @return Success
   */
  transactionsByCard(
    idCard: number,
    startDate?: string | undefined,
    endDate?: string | undefined
  ): Observable<TransactionDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Transaction/transactions-by-card?';
    url_ += 'IdCard=' + idCard;
    if (startDate) url_ += '&StartDate=' + startDate;
    if (endDate) url_ += '&EndDate=' + endDate;
    return this.http.get<TransactionDTO[]>(url_);
  }
  /**
   * @param startDate (optional)
   * @param endDate (optional)
   * @return Success
   */
  transactionsByMember(
    idMember: number,
    startDate?: string | undefined,
    endDate?: string | undefined
  ): Observable<TransactionDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Transaction/transactions-by-member?';
    url_ += 'IdMember=' + idMember + '&';
    url_ += 'StartDate=' + startDate + '&';
    url_ += 'EndDate=' + endDate;
    return this.http.get<TransactionDTO[]>(url_);
  }

  reportLost(reportLost: LostReportFilterDTO): Observable<PCCCReportLostResponseDTO> {
    let url_ = `${AppConfigService.apiURL(this.apiName)}/Card/ReportLost`;
    return this.http.post<PCCCReportLostResponseDTO>(url_, reportLost);
  }

  requestPin(idCard: number): Observable<string> {
    let url_ = `${AppConfigService.apiURL(this.apiName)}/Card/requestPin/${idCard}`;
    return this.http.get<string>(url_);
  }
  activateCard(idMember: number, idCard: number): Observable<ActivationCardDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Card/ActivateCard?';
    url_ += 'idMember=' + idMember + '&';
    url_ += 'idCard=' + idCard;
    return this.http.post<ActivationCardDTO>(url_, null);
  }
  getCardAlert(idCard: number, idMember: number) {
    const url: string =
      AppConfigService.apiURL(this.apiName) + '/Card/' + idCard.toString() + '/Alert?IdMember=' + idMember.toString();
    return this.http.get(url);
  }

  getSyncCardBalance(idCard: number): Observable<any> {
    const url: string = AppConfigService.apiURL(this.apiName) + '/Card/' + idCard.toString() + '/synccards';
    return this.http.get(url);
  }

  getFlexPurseBalance(idMember: number): Observable<any> {
    const url: string = AppConfigService.apiURL(this.apiName) + '/purse/' + idMember.toString() + '/flexpursebalance';
    return this.http.get(url);
  }

  isCardLockedForReplacement(idCard: number, idMember: number): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/card/iscardlockedforreplacement?';
    if (idCard) {
      url_ += 'IdCard=' + idCard;
    }
    if (idMember) {
      url_ += '&IdMember=' + idMember;
    }
    return this.http.post<boolean>(url_, null);
  }
  creditToPCCCTransfer(creditToPCCCFundTransferReqList: CreditToPCCCFundTransferObject): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/card/transfercredittopccc';
    return this.http.post<boolean>(url_, creditToPCCCFundTransferReqList);
  }
}
