import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { MemberService } from './services/member.service';
import { AuthService } from './services/shared-services/auth.service';
import { ErrorHandlerService } from './services/shared-services/error-handler.service';
import { SpinnerService } from './services/shared-services/spinner.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService,
    private spinnerService: SpinnerService,
    private memberService: MemberService
  ) {}
  removeRequest(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.spinnerService.isLoading.next(this.requests.length > 0);
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/geocode') === -1) {
      if (
        request.url.indexOf('/user/validate-company/{tpaCompanyId}') === -1 ||
        request.url.indexOf('/user/member-enrollment-info') === -1 ||
        request.url.indexOf('/user/create-user') === -1 ||
        request.url.indexOf('/user/email-confirm') === -1 ||
        request.url.indexOf('/user/resend-email') === -1
      ) {
        const token = this.authService.getAccessToken();
        if (request.url.indexOf('/SSO') >= 0) {
          request = request.clone({
            headers: request.headers.set('Authorization', 'Basic V2ViU2VydmljZXNAd2lyZWRjb21tdXRlLmNvbTpXaHlOb3QxMjMk'),
          });
        } else if (token) {
          request = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + token),
          });
        }
      }
      const token = this.authService.getAccessToken();
      if (request.url.indexOf('/SSO') >= 0) {
        const memberData = this.memberService.getMemberData();
        request = request.clone({
          headers: request.headers.set('Authorization', 'Basic V2ViU2VydmljZXNAd2lyZWRjb21tdXRlLmNvbTpXaHlOb3QxMjMk'),
        });
      } else if (token) {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });

      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Headers', 'X-Requested-With,content-type'),
      });
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,PUT,DELETE'),
      });
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', '*'),
      });
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Credentials', 'true'),
      });
    }
    if (
      request.url.indexOf('/token') === -1 &&
      request.url.indexOf('/taproductsearch') === -1 &&
      request.url.indexOf('/Order') === -1 &&
      request.url.indexOf('/transactions-by-card') === -1 &&
      request.url.indexOf('/claim-history') === -1 &&
      request.url.indexOf('/synccards') === -1
    ) {
      this.requests.push(request);
      this.spinnerService.isLoading.next(true);
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event && event.body) {
            // call method to log the trace with event.Ok
            this.removeRequest(request);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (request.url.indexOf('/token') === -1) {
          this.removeRequest(request);
          this.errorHandlerService.handleError(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        if (request.url.indexOf('/token') === -1) {
          this.removeRequest(request);
        }
      })
    );
  }
}
