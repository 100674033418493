<div class="container">
  <form class="row eb-cards mt-4" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">
      <div
        class="border-0 card login-info p-5 pl-lg-4 pr-lg-4 pt-lg-4 pb-lg-4"
        style="border-top: 6px solid #f72717 !important">
        <div>
          <h3 class="font-weight-bold text-lg-center text-nowrap">Your Commuter Benefits Accounts</h3>
        </div>

        <div class="align-items-baseline mx-0 row">
          <div class="col-lg-7 pr-lg-2 text-right">
            <p class="small text-decoration-underline text-muted" [routerLink]="'/employee-verification'">
              Setup My Account
            </p>
          </div>
          <div class="col-lg-4 pl-lg-0">
            <img src="assets/account.png" alt="account" style="height: 18px" />
          </div>
        </div>
        <input
          type="text"
          placeholder="username"
          id="userName"
          name="userName"
          formControlName="userName"
          class="form-control mt-3 mt-lg-0"
          style="height: 45px; border-radius: 8px" />
        <div class="row ml-1 error" *ngIf="loginForm.get('userName')?.touched && loginForm.get('userName')?.errors">
          {{ loginForm.get('userName')?.errors }}
        </div>
        <input
          type="password"
          placeholder="password"
          id="password"
          name="password"
          formControlName="password"
          class="form-control mt-4 input_weight"
          style="border-radius: 8px" />
        <div class="row ml-1 error" *ngIf="loginForm.get('password')?.touched && loginForm.get('pass')?.errors">
          {{ loginForm.get('password')?.errors }}
        </div>
        <div class="align-items-center mt-3 mx-0 row">
          <div class="col-xl-12 pr-0 text-lg-center">
            <button class="bold btn btn-primary d-inline font-12 pl-lg-5 pr-lg-5 text-center w-auto" aria-label="login">
              Login
            </button>
          </div>
        </div>
        <div class="align-items-center mt-4 mx-0 row">
          <div class="col-xl-6 pl-0">
            <a class="font-weight-bold mb-0 small text-decoration-underline" [routerLink]="'/username-recover'"
              >Forgot your username?</a
            >
          </div>
          <div class="col-xl-6 text-lg-right">
            <a class="font-weight-bold mb-0 small text-decoration-underline" [routerLink]="'/password-reset'"
              >Forgot your password?</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </form>
</div>
