import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCompany from '../company/company.reducer';

export interface State {
  companyFeature: fromCompany.CompanyState;
}
export const companyReducers: ActionReducerMap<State> = {
  companyFeature: fromCompany.companyReducer,
};

const getCompanyFeatureState = createFeatureSelector<fromCompany.CompanyState>('companyFeature');

export const getCompanySettings = createSelector(getCompanyFeatureState, state => {
  return state.companySettings;
});
export const idMember = createSelector(getCompanyFeatureState, state => {
  return state.idMember;
});

export const getCompany = createSelector(getCompanyFeatureState, state => {
  return state.company;
});
