<div>
  <header>
    <div class="navbar f-navbar navbar-expand-lg onboarding-navbar">
      <nav class="f-navbar navbar navbar-expand-lg">
        <div class="mdb-navbar-brand">
          <a class="navbar-brand">
            <img src="assets/images/edenred_logo_new.svg" alt="logo" aria-label="logo" /><span class="program-label">
              Employee Benefits
            </span>
          </a>
        </div>
      </nav>
    </div>
  </header>
</div>
