import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { DebitCardDTO } from '../core/models/card';
import { CompanySettingDTO } from '../core/models/company';
import { MemberDTO } from '../core/models/member';
import { CardService } from '../core/services/card.service';
import { CompanyService } from '../core/services/company.sevice';
import { MemberService } from '../core/services/member.service';
import { ModalCssClass } from '../shared/app.constants';
import { CardStatus, PMIType } from '../shared/app.enums';
import { VideoComponent } from './video/video.component';

@Component({
  selector: 'eb-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit, OnDestroy {
  isDashboardMenu = true;
  isTrnPrkPCCCAllow: boolean;
  modalRef: any | MdbModalRef<VideoComponent>;
  companySubscription: Subscription;
  memberSubscription: Subscription;
  cardSubscription: Subscription;
  companySettings: CompanySettingDTO;
  showPersonalCardvideo: boolean;
  prkcrIsAllow: boolean;
  allowMobileWallet: boolean;
  isClaimPresent: boolean;
  member: MemberDTO;
  parkingCRAllow: boolean;
  allowCompanyOfferedParking: boolean;
  isActiveCard: boolean;
  isFlexPurseEnabled: boolean;
  isAlight = false;
  isEmpResGuide = false;
  constructor(
    private modalService: MdbModalService,
    private company: CompanyService,
    private memberService: MemberService,
    private cardService: CardService
  ) {}

  openVideoPlayer(title: string, url: string) {
    this.modalRef = this.modalService.open(VideoComponent, {
      data: { title: title, videoUrl: url },
      modalClass: ModalCssClass.LargeModal,
    });
    this.modalRef.onClose.subscribe((message: string) => {});
  }

  openGuide(guideType: string): void {
    if (guideType === 'ITPF') {
      window.open('/assets/documents/Flex_Guide_2024.pdf', '_blank');
    } else if (guideType === 'ERG') {
      window.open('/assets/documents/Employee_Resource_Guide_Alight.pdf', '_blank');
    } else if (guideType === 'ERG2') {
      window.open('/assets/documents/Employee_Resource_Guide.pdf', '_blank');
    } else if (guideType === 'MAQ') {
      window.open('/assets/documents/Mobile_App_Quick_Start_Guide.pdf', '_blank');
    }
  }

  ngOnInit(): void {
    this.memberSubscription = this.memberService.member$?.subscribe(res => {
      if (res) {
        this.member = res; // alight check
        switch (this.member.tpaCode) {
          case 'HEW':
            this.isAlight = true;
            break;
          case 'ALP':
          case 'PAX':
          case 'CCO':
          case 'CCD':
          case 'FID':
          case 'ITU':
          case 'LIV':
          case 'SNJ':
          case 'NYS':
          case 'SMI':
          case 'UNV':
            this.isEmpResGuide = true;
            break;
        }
      }
    });

    this.companySubscription = this.company.companySettings$?.subscribe(resCompanySettings => {
      if (resCompanySettings) {
        this.companySettings = resCompanySettings;
        if (this.companySettings?.idCompanyPmi === PMIType.ECP) {
          this.showPersonalCardvideo = true;
        }
        this.prkcrIsAllow = this.companySettings.prkcrIsAllow;
        if (this.companySettings?.pptIsAllowMobileWallet && this.member?.last4DigitsPCCC) {
          this.allowMobileWallet = true;
        }
        if (this.companySettings.crIsAllow || this.companySettings.bikeCrIsAllow || this.companySettings.prkcrIsAllow) {
          this.isClaimPresent = true;
        }
        this.parkingCRAllow = this.companySettings?.prkcrIsAllow;
        this.allowCompanyOfferedParking = this.companySettings?.allowCompanyOfferedParking;
        this.isFlexPurseEnabled = this.companySettings?.isFlexPurseEnabled;
      }
    });

    this.cardSubscription = this.cardService.cards$.subscribe((res: DebitCardDTO[]) => {
      if (res && res.length > 0) {
        let activeCardMobileWallet = res.filter((card: DebitCardDTO) => card.statusDescription === CardStatus.Active);
        if (activeCardMobileWallet.length > 0) {
          if (activeCardMobileWallet[0]) {
            this.isActiveCard = true;
          }
        }
      }
    });

    this.isTrnPrkPCCCAllow = this.company.isTrnPrkPCCCAllow;
  }

  ngOnDestroy() {
    if (this.cardSubscription) {
      this.cardSubscription.unsubscribe();
    }
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
}
