import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CompanyDTO } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { PageTitle } from 'src/app/shared/app.constants';

@Component({
  selector: 'eb-employee-verification',
  templateUrl: './employee-verification.component.html',
})
export class EmployeeVerificationComponent implements OnInit {
  @ViewChild('errorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  employeeVerificationForm!: FormGroup;
  isNysCompany: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userRegistrationService: UserRegistrationService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.titleService.setTitle(PageTitle.Registration);
  }

  ngOnInit() {
    this.employeeVerificationForm = this.fb.group({
      tpaCompanyId: new FormControl('', [Validators.required]),
    });
  }
  verifyCompany() {
    this.notificationService.targetElement = this.errorContainer;
    const tpaCompanyId = this.employeeVerificationForm.get('tpaCompanyId')?.value;
    this.authService.validateCompany(tpaCompanyId).subscribe((response: CompanyDTO) => {
      if (response) {
        this.userRegistrationService.setCompanyData = response;
        if (response.tpaCode === 'NYS') {
          this.isNysCompany = true; 
          this.router.navigate(['/nys-employee-verification']);
        } 
        else {
          this.isNysCompany = false;
          this.router.navigate(['/tell-us-about-you']);
        }
      }
    });
  }  
}
