import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ClaimDTO,
  ClaimDetailDTO,
  ClaimDocumentsDTO,
  ClaimHistoryDTO,
  ClaimSettingsDTO,
  CreateClaimDTO,
  CrmIssue,
  CrmIssueContractDTO,
  CrmIssueDTO,
  CrmLostPassDTO,
  CrmStatusDTO,
  IssueDocumentDTO,
  MemberBBAdjustmentDTO,
  SaveAndReadReceiptDTO,
  documentResponse,
  crmGrhClaimReasonDTO,
  uploadDocObject,
  PreTaxCapDTO,
  TPACompanyCRMSettingDTO,
} from '../models/claim';
import { AppConfigService } from './shared-services/app-config.service';
import { HttpOptions } from '@ngrx/data/src/dataservices/interfaces';
import { OrderProductInfo } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  apiName = 'claimService';
  claims$!: Observable<MemberBBAdjustmentDTO>;
  private claim: ClaimDTO;
  private orderDetails: OrderProductInfo;

  public set setOrderDetails(item: OrderProductInfo) {
    this.orderDetails = item;
  }
  public get getOrderDetails() {
    return this.orderDetails;
  }
  constructor(private http: HttpClient) {}
  /**
   * @return Success
   */
  getClaimHistoryByMember(idMember: number, recordCount: number): Observable<ClaimHistoryDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/claim-history/{IdMember}';
    url = url.replace('{IdMember}', idMember.toString());
    if (recordCount) {
      url += '?recordCount=' + recordCount;
    }
    return this.http.get<ClaimHistoryDTO>(url);
  }
  /**
   * @return Success
   */
  getClaimBBAdjustment(idMember: number): Observable<MemberBBAdjustmentDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/bb-adjustment-balance/{IdMember}';
    url = url.replace('{IdMember}', idMember.toString());
    return this.http.get<MemberBBAdjustmentDTO>(url);
  }
  /**
   * @return Success
   */
  getClaimDetail(idClaim: number): Observable<ClaimDetailDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/claim-details/{idClaim}';
    url = url.replace('{idClaim}', idClaim ? idClaim.toString() : '');
    return this.http.get<ClaimDetailDTO>(url);
  }
  getIssueDocument(idIssue: number): Observable<ClaimDocumentsDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/issue/{idIssue}/documents';
    url = url.replace('{idClaim}', idIssue.toString());
    return this.http.get<ClaimDocumentsDTO>(url);
  }
  getDocumentContent(idContent: string, sourceType: string): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/documents/{sourceType}/{idContent}';
    url = url.replace('{idContent}', idContent?.toString());
    url = url.replace('{sourceType}', sourceType?.toString());
    return this.http.get<ClaimDocumentsDTO>(url, { responseType: 'blob' as 'json' });
  }

  saveNewReceipt(documentDetails: IssueDocumentDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/issue/document';
    return this.http.post<any>(url, documentDetails);
  }
  /**
   * @return Success
   */
  getClaimSettings(idMember: number, claimTypeId: number): Observable<ClaimSettingsDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/claim-settings?idMember=' + idMember.toString();
    if (claimTypeId) {
      url = url + '&claimTypeId=' + claimTypeId.toString();
    }
    return this.http.get<ClaimSettingsDTO>(url);
  }

  addClaim(claim: CreateClaimDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/claim/claim';
    return this.http.post<any>(url, claim);
  }

  SaveAndReadReceipt(formData: FormData, claimTypeId: number): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/Receipt';
    return this.http.post<any>(url, formData);
  }

  TransferBBBalance(formData: MemberBBAdjustmentDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/Claim/TransferBBBalance';
    return this.http.post<any>(url, formData);
  }

  public set setClaimItem(claim: ClaimDTO) {
    this.claim = claim;
  }

  public get getClaimItem(): ClaimDTO {
    return this.claim;
  }
  getLostPass(idOrderProducts: number): Observable<CrmLostPassDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/getlostpass';
    if (idOrderProducts) {
      url_ = url_ + '?idOrderProducts=' + idOrderProducts;
    }
    return this.http.get<CrmLostPassDTO[]>(url_);
  }

  addLostPass(CRMIssue: CrmIssueDTO): Observable<number> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/addlostpass';
    return this.http.post<number>(url_, CRMIssue);
  }

  uploadDocuments(formData: FormData): Observable<documentResponse> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/documents/upload';
    return this.http.post<documentResponse>(url_, formData);
  }
  isLostPassExist(idOrder: number, idOrderProducts: number, idIssueType: number): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/isissueexist';
    if (idOrder) {
      url_ = url_ + '?idOrder=' + idOrder;
    }
    if (idOrderProducts) {
      url_ = url_ + '&idOrderProducts=' + idOrderProducts;
    }
    if (idIssueType) {
      url_ = url_ + '&idIssueType=' + idIssueType;
    }
    return this.http.get<boolean>(url_);
  }
  getCRMStatus(idStatus: number): Observable<CrmStatusDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/{idStatus}/getstatus';
    if (idStatus) {
      url_ = url_.replace('{idStatus}', idStatus.toString());
    }
    return this.http.get<CrmStatusDTO>(url_);
  }
  getCrmIssueDetails(idIssue: number, sourceType: string): Observable<CrmIssueContractDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/crmissue/{idIssue}/getcrmissuedetails';
    if (idIssue) {
      url_ = url_.replace('{idIssue}', idIssue.toString());
    }
    if (sourceType) {
      url_ = url_ + '?sourceType=' + sourceType;
    }
    return this.http.get<CrmIssueContractDTO>(url_);
  }

  getExpenseType(idMember: number): Observable<any> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/claim/member/{idMember}/expensetype';
    url_ = url_.replace('{idMember}', idMember.toString());
    return this.http.get<any>(url_);
  }
  getGrhClaimReason(): Observable<crmGrhClaimReasonDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/claim/grh/claimreason';
    return this.http.get<crmGrhClaimReasonDTO[]>(url_);
  }

  getPreTaxCapConfig(): Observable<PreTaxCapDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/claim/config/pretaxcap';
    return this.http.get<PreTaxCapDTO>(url);
  }
  getGRHSettingsByMember(idMember: number): Observable<TPACompanyCRMSettingDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/claim/grh/settings/{IdMember}';
    url = url.replace('{IdMember}', idMember.toString());
    return this.http.get<TPACompanyCRMSettingDTO>(url);
  }
}
