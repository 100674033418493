<div class="container">
  <div class="eb-cards mt-4 mx-0 row">
    <div class="col-md-2 col-xl-4"></div>
    <div class="col-md-8 col-xl-4">
      <div class="border-0 card login-info p-2">
        <h4
          class="font-24 font-weight-bold mt-3 mt-lg-4 mt-md-3 text-center text-lg-center text-md-center text-xl-center">
          Verify your account
        </h4>
        <div class="mt-3 mt-lg-3 mt-md-3 mx-0 row">
          <div class="col-lg-12">
            <p class="font-16">
              We’ll need to verify your account via your email address <strong>{{ emailAddress }}</strong> to complete
              your registration.
            </p>
            <p class="font-16 mt-3">
              We’ve sent an email to <strong>{{ emailAddress }}</strong
              >. Please click the link in the email to finish your account verification.
            </p>
            <p class="toast-primary font-16 mt-3 p-3 border-left-4 d-flex rounded-end">
              <img src="assets/images/error_outline.svg" alt="error" class="mr-3" />
              <span>Make sure to verify as soon as possible! The link will expire in 30 minutes.</span>
            </p>
          </div>
          <div class="mb-3 mb-lg-4 mb-md-3 mt-2 mt-lg-2 employee_verification">
            <p class="font-16 mb-1">Didn’t receive an email?</p>
            <button
              aria-label="Email"
              class="bold btn btn-primary d-inline font-16 mt-2 mt-md-2 pl-4 pr-4 text-center w-auto"
              (click)="emailResent()">
              Re-Send Email
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xl-4"></div>
  </div>
</div>
