import { CartDetailDTO } from 'src/app/core/models/shopping-cart';
import { ShoppingCartActionTypes, ShoppingCartActions } from './shopping-cart.action';

export interface ShoppingCartState {
  shoppingCartDetails: CartDetailDTO[];
  idMember: number;
  error: string;
}

const initialState: ShoppingCartState = {
  shoppingCartDetails: [],
  idMember: undefined,
  error: '',
};

export function shoppingCartReducer(state = initialState, action: ShoppingCartActions): ShoppingCartState {
  switch (action.type) {
    case ShoppingCartActionTypes.SHOPPINGCART_LOAD_SUCCESS:
    case ShoppingCartActionTypes.SHOPPINGCART_LOAD_UPDATE:
      return {
        ...state,
        shoppingCartDetails: action.data,
        error: '',
      };
    case ShoppingCartActionTypes.SHOPPINGCART_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.data.error,
      });
    }
    default:
      return state;
  }
}
