import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCard from './card.reducer';

export interface State {
  cardFeature: fromCard.CardState;
}
export const reducers: ActionReducerMap<State> = {
  cardFeature: fromCard.cardReducer,
};

const getCardFeatureState = createFeatureSelector<fromCard.CardState>('cardFeature');

export const getCards = createSelector(getCardFeatureState, state => {
  return state.cards;
});

export const idMember = createSelector(getCardFeatureState, state => {
  return state.idMember;
});
