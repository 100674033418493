<div class="container">
  <div class="row eb-cards mt-4 mx-0">
    <div class="col-lg-2 col-md-2 col-xl-4"></div>
    <div class="col-12 col-lg-8 col-md-8 col-xl-4">
      <div class="border-0 card login-info p-2 border_radius_15px">
        <h3 class="font-weight-bold mt-2 mt-lg-4 text-center text-lg-center text-md-center">New User Registration</h3>
        <div class="m-3 m-lg-3 m-md-3 mx-0 row" *ngIf="!enableWebAppVersionV6">
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-lg-3 mb-xl-2 mt-3 mt-lg-3 mt-xl-0 pl-0 pr-0">
            <div class="border-0">
              <div class="mx-0 p-2 pb-3 pt-3 row mt-3 error-message-container">
                <div class="col-xl-12 col-12">
                  <p class="font-16 font-weight-normal mb-0 pt-2 error-text">
                    <span
                      >You are not authorized to access this version of the site at this time. Please contact your
                      company administrator.</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
            <a
              style="color: #000; padding: 11px"
              class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
              [routerLink]="'/'">
              Back
            </a>
          </div>
        </div>
        <div class="row mx-0 pl-0 pr-0 col-lg-12">
          <ng-template #errorContainer> </ng-template>
        </div>
        <div class="mt-3 mt-lg-3 mt-md-3 mx-0 row" *ngIf="enableWebAppVersionV6">
          <div class="col-lg-3 col-md-2 col-xl-2"></div>
          <div class="col-lg-6 col-md-8 col-xl-8">
            <div class="step-container d-flex justify-content-between mt-3 mt-lg-4">
              <div class="step-circle-wb mb-0">1</div>
              <div class="step-line">-----</div>
              <div class="step-circle mb-0">2</div>
              <div class="step-line">-----</div>
              <div class="step-without-circle mb-0">3</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-2 col-xl-2"></div>
          <div class="col-lg-12 col-md-12 employee_verification">
            <h4 class="font-weight-bold text-center text-lg-center text-md-center">Tell us about you</h4>
          </div>

          <form [formGroup]="tellUsAboutYouForm">
            <mdb-form-control>
              <input
                mdbInput
                formControlName="firstName"
                type="text"
                class="form-control mt-4 mt-lg-4 input-height"
                (input)="dynamicFieldValidation()" />
              <label mdbLabel class="form-label" for="firstName">First Name</label>
            </mdb-form-control>
            <div
              class="error edenred-border-8"
              *ngIf="tellUsAboutYouForm.get('firstName').invalid && tellUsAboutYouForm.get('firstName').touched">
              <span *ngIf="tellUsAboutYouForm.get('firstName').hasError('required')">First Name is required.</span>
            </div>
            <mdb-form-control>
              <input
                mdbInput
                formControlName="lastName"
                type="text"
                class="form-control mt-3 mt-lg-3 input-height"
                (input)="dynamicFieldValidation()" />
              <label mdbLabel class="form-label" for="lastName">Last Name</label>
            </mdb-form-control>
            <div
              class="error edenred-border-8"
              *ngIf="tellUsAboutYouForm.get('lastName').invalid && tellUsAboutYouForm.get('lastName').touched">
              <span *ngIf="tellUsAboutYouForm.get('lastName').hasError('required')">Last Name is required.</span>
            </div>
            <mdb-form-control>
              <input
                mdbInput
                formControlName="homeZipCode"
                type="text"
                class="form-control mt-3 mt-lg-3 input-height"
                minlength="5"
                maxlength="10"
                ebOnlyNumber
                ebZipCode
                (input)="dynamicFieldValidation()" />
              <label mdbLabel class="form-label" for="homeZipCode">Home Zip Code</label>
            </mdb-form-control>
            <div
              class="error edenred-border-8"
              *ngIf="tellUsAboutYouForm.get('homeZipCode').invalid && tellUsAboutYouForm.get('homeZipCode').touched">
              <span *ngIf="tellUsAboutYouForm.get('homeZipCode').hasError('required')">Home Zip Code is required.</span>
              <span *ngIf="tellUsAboutYouForm.get('homeZipCode').hasError('pattern')">Invalid Zip Code.</span>
            </div>
            <div class="employee_verification">
              <p class="font-weight-bold text-center text-lg-center text-md-center">OR</p>
            </div>
            <mdb-form-control>
              <input
                mdbInput
                formControlName="tpaEmployeeId"
                id="tpaEmployeeId"
                type="text"
                class="form-control mt-3 mt-lg-3 input-height"
                (input)="dynamicFieldValidation()" />
              <label mdbLabel class="form-label" for="tpaEmployeeId"> Employee ID</label>
            </mdb-form-control>
            <div
              class="error edenred-border-8"
              *ngIf="
                tellUsAboutYouForm.get('tpaEmployeeId').invalid && tellUsAboutYouForm.get('tpaEmployeeId').touched
              ">
              <span *ngIf="tellUsAboutYouForm.get('tpaEmployeeId').hasError('required')"> Employee ID required.</span>
            </div>

            <div
              class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
              <!-- <button
                class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                [routerLink]="'/employee-verification'">
                Back
              </button> -->
              <a
                style="color: #000; padding: 11px"
                class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                [routerLink]="'/employee-verification'">
                Back
              </a>
              <button
                aria-label="verifyEnrollment"
                class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
                type="submit"
                (click)="verifyEnrollment()"
                [disabled]="!tellUsAboutYouForm.valid || !isFormValid">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</div>
