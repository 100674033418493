import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';

import { CompanyDTO, CompanySettingDTO } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.sevice';
import { AppState } from '../app.reducer';
import * as companyAction from './company.action';

@Injectable()
export class CompanyEffect {
  constructor(private companyService: CompanyService, private action$: Actions, private store: Store<AppState>) {}

  loadCompanySettings$ = createEffect(() =>
    this.action$.pipe(
      ofType(companyAction.CompanyActionTypes.COMPANY_SETTINGS_LOAD),
      switchMap((action: any) => {
        return this.companyService.getCompanySettings(action.idMember).pipe(
          map((companyData: CompanySettingDTO) => {
            return new companyAction.CompanySettingsLoadSuccess(companyData);
          }),
          catchError(err => of(new companyAction.CompanySettingsLoadFail(err)))
        );
      })
    )
  );

  loadCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(companyAction.CompanyActionTypes.COMPANY_LOAD),
      switchMap((action: any) => {
        return this.companyService.getCompany().pipe(
          map((companyData: CompanyDTO[]) => {
            return new companyAction.CompanyLoadSuccess(companyData);
          }),
          catchError(err => of(new companyAction.CompanyLoadFail(err)))
        );
      })
    )
  );
}
