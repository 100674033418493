import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromShoppingCart from '../shopping-cart/shopping-cart.reducer';

export interface State {
  shoppingCartFeature: fromShoppingCart.ShoppingCartState;
}
export const shoppingCartReducers: ActionReducerMap<State> = {
  shoppingCartFeature: fromShoppingCart.shoppingCartReducer,
};

const getShoppingCartFeatureState = createFeatureSelector<fromShoppingCart.ShoppingCartState>('shoppingCartFeature');

export const getShoppingCartByIdMember = createSelector(getShoppingCartFeatureState, state => {
  return state.shoppingCartDetails;
});

export const idMember = createSelector(getShoppingCartFeatureState, state => {
  return state.idMember;
});
