import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmDTO } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';

@Component({
  selector: 'eb-profile-setup',
  templateUrl: './profile-setup.component.html',
})
export class ProfileSetupComponent implements OnInit {
  confirmEmail: EmailConfirmDTO | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userRegistrationService: UserRegistrationService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.confirmEmail = {
        username: params['user'],
        code: params['token'],
      };
      this.userRegistrationService.setUserNameData = this.confirmEmail.username;
      this.handleUnauthenticatedUser();
    });
  }

  handleUnauthenticatedUser() {
    if (this.authService.isAuthenticated) {
      this.router.navigateByUrl('/dashboard/home');
    } else {
      this.authService.confirmEmail(this.confirmEmail).subscribe(
        (result: any) => {
          if (!result.succeeded) {
            if (result.errors && result.errors.length > 0) {
              const errorCode = result.errors[0].code;
              if (errorCode === 'EmailAlreadyConfirmed') {
                this.router.navigate(['/']);
              } else {
                this.router.navigate(['/verify-account']);
              }
            } else {
              this.router.navigate(['/verify-account']);
            }
          }
        },
        () => {
          this.router.navigate(['/verify-account']);
        }
      );
    }
  }
}
