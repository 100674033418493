import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { CompanySettingDTO, CustomizedBrandingInfo } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.sevice';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'eb-help-modal.component',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements OnInit, OnDestroy, AfterViewInit {
  brandingInfo: CustomizedBrandingInfo = {};
  companySubscription: Subscription;
  company: CompanySettingDTO = {};
  memberSubscription: Subscription;
  brandingTime = '';

  constructor(
    public modalRef: MdbModalRef<HelpModalComponent>,
    private companyService: CompanyService,
    private memberService: MemberService,
    private router: Router
  ) {}

  ngOnInit() {
    this.companySubscription = this.companyService.companySettings$?.subscribe(res => {
      this.company = res;
      setTimeout(() => {
        this.loadBrandingInfo();
      }, 10);
    });
    this.memberSubscription = this.memberService.member$?.subscribe(res => {
      if (res) {
        if (res.tpaCode === 'RCD') {
          this.brandingTime = 'M-F 7am-7pm CT';
        } else {
          this.brandingTime = 'M-F 8am-8pm ET';
        }
      }
    });
  }

  ngAfterViewInit(): void {}

  loadBrandingInfo(): void {
    this.companySubscription = this.companyService.getBrandingInfo(this.company.idCompany).subscribe(resp => {
      if (resp) {
        this.brandingInfo = resp;
        if (this.brandingInfo.operationHours) {
          this.brandingTime = this.brandingInfo.operationHours;
        }
      }
    });
  }

  redirectToFaqs() {
    this.modalRef.close();
    this.router.navigateByUrl('/guides-n-faq');
  }

  ngOnDestroy(): void {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
}
