import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ContentManagementDTO, ParkingProductContentDTO, TransitProductContentDTO } from '../models/content-management';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ContentManagementService {
  public pageContent$!: Observable<ContentManagementDTO[]>;
  apiName = 'contentManagementService';
  constructor(private http: HttpClient) {}
  /**
   * @return Success
   */
  getPageContent(contentDestCode: string, contentPagesCode?: string): Observable<ContentManagementDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/ContentManagement/{contentDestCode}';
    url = url.replace('{contentDestCode}', contentDestCode.toString());
    if (contentPagesCode) {
      url = url + 'contentPagesCode =' + contentPagesCode.toString();
    }
    return this.http.get<ContentManagementDTO[]>(url);
  }

  getTransitProductTips(
    idProduct: number,
    idTransitAuthority?: number,
    contentClass?: string
  ): Observable<TransitProductContentDTO> {
    let url =
      AppConfigService.apiURL(this.apiName) +
      '/ContentManagement/transitproduct/tips?idProduct=' +
      idProduct.toString();

    if (idTransitAuthority) {
      url = url + '&IdTransitAuthority=' + idTransitAuthority.toString();
    }
    if (contentClass) {
      url = url + '&ContentClass=' + contentClass.toString();
    }
    return this.http.get<TransitProductContentDTO>(url);
  }

  getParkingProductTips(
    idProduct: number,
    productTypeCode?: string,
    contentClass?: string
  ): Observable<ParkingProductContentDTO> {
    let url =
      AppConfigService.apiURL(this.apiName) +
      '/ContentManagement/parkingproduct/tips?idProduct=' +
      idProduct.toString();

    if (productTypeCode) {
      url = url + '&ProductTypeCode=' + productTypeCode.toString();
    }
    if (contentClass) {
      url = url + '&ContentClass=' + contentClass.toString();
    }
    return this.http.get<ParkingProductContentDTO>(url);
  }
}
