import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { SpinnerService } from 'src/app/core/services/shared-services/spinner.service';

@Component({
  selector: 'eb-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SpinnerComponent {
  loading = false;

  constructor(
    private spinnerService: SpinnerService,
    private cd: ChangeDetectorRef
  ) {
    this.spinnerService.isLoading.subscribe(v => {
      this.loading = v;
    });
  }
  ngOnInit = () => {};
}
