import { Action } from '@ngrx/store';
import { ContentManagementDTO } from 'src/app/core/models/content-management';

export enum ContentManagementActionTypes {
  CONTENT_MANAGEMENT_LOAD = 'CONTENT_MANAGEMENT_LOAD',
  CONTENT_MANAGEMENT_LOAD_SUCCESS = 'CONTENT_MANAGEMENT_LOAD_SUCCESS',
  CONTENT_MANAGEMENT_LOAD_FAIL = 'CONTENT_MANAGEMENT_LOAD_FAIL',
}

export class ContentManagementLoad implements Action {
  readonly type = ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD;
  constructor() {}
}

export class ContentManagementLoadSuccess implements Action {
  readonly type = ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD_SUCCESS;
  constructor(public data: ContentManagementDTO[]) {}
}

export class ContentManagementLoadFail implements Action {
  readonly type = ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

export type ContentManagementActions = ContentManagementLoad | ContentManagementLoadSuccess | ContentManagementLoadFail;
