import { Action } from '@ngrx/store';
import { MemberBBAdjustmentDTO } from 'src/app/core/models/claim';

export enum ClaimActionTypes {
  CLAIM_LOAD = 'CLAIM_LOAD',
  CLAIM_LOAD_SUCCESS = 'CLAIM_LOAD_SUCCESS',
  CLAIM_LOAD_FAIL = 'CLAIM_LOAD_FAIL',
}

export class ClaimLoad implements Action {
  readonly type = ClaimActionTypes.CLAIM_LOAD;
  constructor(public idMember: number) {}
}

export class ClaimLoadSuccess implements Action {
  readonly type = ClaimActionTypes.CLAIM_LOAD_SUCCESS;
  constructor(public data: MemberBBAdjustmentDTO) {}
}

export class ClaimLoadFail implements Action {
  readonly type = ClaimActionTypes.CLAIM_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

// Union the valid types
export type ClaimActions = ClaimLoad | ClaimLoadSuccess | ClaimLoadFail;
