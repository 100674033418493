import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

// MDB Modules
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbLoadingModule } from 'mdb-angular-ui-kit/loading';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { NgxCurrencyModule } from 'ngx-currency';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccessDeniedComponent } from './authentication/access-denied/access-denied.component';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { EmployeeVerificationComponent } from './authentication/user-registration/employee-verification.component';
import { IntroduceUserComponent } from './authentication/user-registration/introduce-user.component';
import { MyProfileComponent } from './authentication/user-registration/my-profile.component';
import { ProfileSetupComponent } from './authentication/user-registration/profile-setup.component';
import { ResendEmailComponent } from './authentication/user-registration/resend-email.component';
import { NYSEmployeeVerificationComponent } from './authentication/user-registration/nys-employee-verification.component';
import { NYSTellUsAboutYouComponent } from './authentication/user-registration/nys-tell-us-about-you.component';
import { SetUpYourAccountComponent } from './authentication/user-registration/set-up-your-account.component';
import { TellUsAboutYouComponent } from './authentication/user-registration/tell-us-about-you.component';
import { VerifyAccountComponent } from './authentication/user-registration/verify-account.component';
import { JwtInterceptor } from './core/jwt-interceptor';
import { RetryInterceptor } from './core/retry-interceptor';
import { ServicesModule } from './core/services.module';
import { AppConfigService } from './core/services/shared-services/app-config.service';
import { appInitializerFactory } from './core/services/shared-services/app-initializer-factory';
import { AuthService } from './core/services/shared-services/auth.service';
import { NotificationService } from './core/services/shared-services/notification.service';
import { ebCurrencyMaskConfig } from './shared/app.constants';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SharedModule } from './shared/shared.module';
import { AppDispatcher } from './store/app.dispatcher';
import { AppStoreModule } from './store/appStore.module';
import { FaqComponent } from './faq/faq.component';
import { VideoComponent } from './faq/video/video.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    LogoutComponent,
    AccessDeniedComponent,
    LoginComponent,
    EmployeeVerificationComponent,
    NYSEmployeeVerificationComponent,
    NYSTellUsAboutYouComponent,
    TellUsAboutYouComponent,
    SetUpYourAccountComponent,
    VerifyAccountComponent,
    IntroduceUserComponent,
    MyProfileComponent,
    ResendEmailComponent,
    ProfileSetupComponent,
    FaqComponent,
    VideoComponent,
    AuthCallbackComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    MdbLoadingModule,
    MdbDatepickerModule,
    HttpClientModule,
    SharedModule,
    OAuthModule.forRoot(),
    ServicesModule,
    NgIdleKeepaliveModule.forRoot(),
    InfiniteScrollModule,
    GoogleMapsModule,
    NgxCurrencyModule.forRoot(ebCurrencyMaskConfig),
    AppStoreModule,
  ],
  providers: [
    Title,
    ServicesModule,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppConfigService, AuthService, NotificationService, Router, ActivatedRoute, OAuthService],
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    AppDispatcher,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
