<div class="container">
  <div class="row eb-cards mt-4">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">
      <div class="border-0 card login-info p-5 pl-lg-4 pr-lg-4 pt-lg-4 pb-lg-4">
        <div>
          <h3 class="font-weight-bold text-lg-left">Reset your password</h3>
        </div>
        <div class="align-items-baseline mx-0 row">
          <div class="col-lg-12 text-lg-left pl-lg-0 reset-pwd">
            <p class="font-weight-normal mt-lg-2">
              To reset your password, please enter your username below. You will receive an email with a temporary
              password.
            </p>
          </div>
        </div>
        <input
          type="text"
          placeholder="username"
          id="userName"
          name="userName"
          class="form-control mt-3 mt-lg-0 input-height" />
        <!--<div class="row ml-1 error" *ngIf="(passwordResetForm.get('userName')?.touched && passwordResetForm.get('userName')?.errors)">
          {{passwordResetForm.get('userName')?.errors}}
        </div>-->

        <div class="align-items-center mt-3 mx-0 row">
          <div class="col-xl-9 pr-0 text-lg-right">
            <!-- <button id="linkBack" name="linkBack" class="btn btn-secondary d-inline font-12 pe-4 ps-4 text-black" [routerLink]="'/login'"> Back
            </button> -->
          </div>
          <div class="col-xl-3 pl-lg-0 pr-0 text-lg-right">
            <button
              aria-label="Submit"
              class="bold btn btn-primary d-inline font-12 text-center w-auto"
              (click)="navigateToDashboard(this.passwordResetForm.getRawValue())">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</div>
