import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ebZipCode]',
})
export class ZipCodeDirective {
  static getFormattedZipCode(zipCode: string) {
    if (!zipCode) {
      return '';
    } else if (zipCode.length <= 5) {
      return zipCode.replace(/^(\d{0,5})/, '$1');
    } else {
      return zipCode.replace(/^(\d{0,5})(\d{0,4})(.*)/, '$1-$2');
    }
  }

  static getRawZipCode(zipCode: string) {
    return zipCode.replace(/\D/g, '');
  }

  constructor(public model: NgControl) {}

  @HostListener('keyup', ['$event']) onkeyup(event) {
    const oldvalue = event.target.value;
    let newVal = oldvalue.replace(/\D/g, '');

    newVal = ZipCodeDirective.getFormattedZipCode(newVal);

    this.model.valueAccessor.writeValue(newVal);
  }
}
