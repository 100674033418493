import { ContentManagementService } from '../content-management.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

export function appInitializerFactory(
  appConfigService: AppConfigService,
  authService: AuthService,
  notificationService: NotificationService,
  contentManagementService: ContentManagementService
): () => Promise<void> {
  return async (): Promise<void> => {
    try {
      const fullUrl = window.location.href;
      const urlParams = new URLSearchParams(fullUrl.split('?')[1]);
      const code = urlParams.get('code');
      const virtualPath = urlParams.get('virtual_path');
      const tpa = urlParams.get('tpa');
      // Load app configuration
      await appConfigService.load();
      authService.setAuthConfig(code, virtualPath, tpa);

      // Continue with the rest of the initializations
      const configDeps: Promise<any>[] = [authService.runInitialLoginSequence(), notificationService.setConfig()];

      // Await all the configuration dependencies
      await Promise.all(configDeps);

      // Refresh token and wait for it to complete
      await authService.loadUserProfile();
    } catch (error) {
      // Handle or log the error
      console.error('Error during app initialization:', error);
      throw error; // Rethrow the error to ensure the rejection of the promise
    }
  };
}
