import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { CookieService } from 'ngx-cookie-service';
import { CardService } from './services/card.service';
import { ClaimService } from './services/claim.service';
import { ContentManagementService } from './services/content-management.service';
import { MemberService } from './services/member.service';
import { OrderService } from './services/order.service';
import { ParkingService } from './services/parking.service';
import { AppConfigService } from './services/shared-services/app-config.service';
import { AuthGuard } from './services/shared-services/auth-guard.service';
import { AuthService } from './services/shared-services/auth.service';
import { MapsService } from './services/shared-services/map.service';
import { ShoppingCartService } from './services/shopping-cart.service';
import { SubsidyService } from './services/subsidy.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, CommonModule, RouterModule],
  exports: [],
  providers: [
    AuthService,
    MdbNotificationService,
    AuthGuard,
    OrderService,
    CardService,
    CookieService,
    ClaimService,
    AppConfigService,
    MemberService,
    SubsidyService,
    ShoppingCartService,
    ContentManagementService,
    ParkingService,
    MapsService,
  ],
  bootstrap: [],
})
export class ServicesModule {
  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error('ServicesModule is already loaded. Import it in the AppModule only');
    }
  }
}
