import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitle } from 'src/app/shared/app.constants';

@Component({
  selector: 'eb-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private route: Router, private titleService: Title) {
    this.titleService.setTitle(PageTitle.Logout);
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['post_logout_redirect_uri']) {
        window.location.href = params['post_logout_redirect_uri'];
      }
    });
  }
  login(): void {
    this.route.navigateByUrl('/');
  }
}
