import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eb-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnDestroy, OnInit {
  passwordResetForm!: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {
    console.info('hit HomeComponent');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  subscriptions: Subscription[] = [];

  ngOnInit(): void {}

  navigateToDashboard = (val: any) => {};
}
