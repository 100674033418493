import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';

import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { AppState } from '../app.reducer';
import * as shoppingCartAction from './shopping-cart.action';
@Injectable()
export class ShoppingCartEffect {
  constructor(
    private shoppingCartService: ShoppingCartService,
    private action$: Actions,
    private store: Store<AppState>
  ) {}

  loadShoppingCartDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(shoppingCartAction.ShoppingCartActionTypes.SHOPPINGCART_LOAD),
      switchMap((action: any) => {
        return this.shoppingCartService.getTempShoppingCartByIdMember(action.idMember).pipe(
          map(shoppingCartData => {
            return new shoppingCartAction.ShoppingCartLoadSuccess(shoppingCartData);
          }),
          catchError(err => of(new shoppingCartAction.ShoppingCartLoadFail(err)))
        );
      })
    )
  );
}
