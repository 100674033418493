<div class="eb-cards row mx-0 pl-0 pr-0">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="help-menu-header mx-0 p-2 row">
        <div class="col-lg-10 col-xl-9 col-md-10 col-10 pl-0 pl-xl-1 pt-xl-2 pb-xl-2">
          <h5 class="modal-title font-weight-bold" id="messageModalLabel">{{ title }}</h5>
        </div>
        <div class="col-lg-2 col-xl-3 col-md-2 text-right col-2 pr-xl-0 pr-md-0 pr-lg-0">
          <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="closePopup()">
            <img src="assets/images/close.png" alt="icon" />
          </button>
        </div>
      </div>
      <div class="modal-body mt-2 pl-2 pl-md-2 pl-xl-2 pr-2 pr-md-2">
        <div class="row mx-0">
          <video
            controls
            (canplay)="pauseVideo(videoplayer)"
            autoplay="true"
            preload="auto"
            crossorigin="anonymous"
            controlslist="nodownload"
            #videoPlayer>
            <source src="{{ videoUrl }}" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
</div>
