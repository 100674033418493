import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AuthAcceptTerms,
  CompanyDTO,
  CreateUserDTO,
  MemberEnrollmentRequest,
  MemberEnrollmentResponse,
  UpdateMemberEmailAddressDTO,
} from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { MessageType, PageTitle } from 'src/app/shared/app.constants';
import { TermsType } from 'src/app/shared/app.enums';

@Component({
  selector: 'eb-set-up-your-account',
  templateUrl: './set-up-your-account.component.html',
})
export class SetUpYourAccountComponent implements OnInit {
  @ViewChild('errorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  setUpYourAccountForm!: FormGroup;
  userName: string;
  firstName: string;
  lastName: string;
  emailAsUsername: boolean = false;
  emailAddress: string;
  companySettings: CompanyDTO;
  idMember: number;
  onCheckMarketingCommunication = false;
  tpaCode: string;
  backRoute: string;
  nysFirstName: string;
  nysLastName: string;
  nysEmail: string;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private userRegistrationService: UserRegistrationService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.titleService.setTitle(PageTitle.Registration);
  }

  ngOnInit() {
    this.notificationService.targetElement = this.errorContainer;
    this.companySettings = this.userRegistrationService.getCompanyData;
    let userNameEnroll = this.userRegistrationService.getUserNameSubject;
    let companyData = this.userRegistrationService.getCompanyData;
    let personalInfo = this.userRegistrationService.getPersonalInfo();
    if (companyData) {
      this.tpaCode = companyData?.tpaCode;
      this.backRoute = this.tpaCode === 'NYS' ? '/nys-tell-us-about-you' : '/tell-us-about-you';
    }
    if (personalInfo) {
      this.nysFirstName = personalInfo?.firstName;
      this.nysLastName = personalInfo?.lastName;
      this.nysEmail = personalInfo?.email;
    }
    this.setUpYourAccountForm = this.fb.group({
      confirmEmail: new FormControl(
        { value: '', disabled: !this.companySettings?.pptIsAllowEditCredential },
        [Validators.required, Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]
      ),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    if (companyData?.tpaCode === 'NYS') {
      let confirmEmailAddress = this.isInvalidEmail(personalInfo?.email) ? '' : personalInfo?.email;
      this.setUpYourAccountForm.patchValue({ confirmEmail: confirmEmailAddress });
    } else {
      let confirmEmailAddress = this.isInvalidEmail(userNameEnroll?.emailAddress) ? '' : userNameEnroll?.emailAddress;
      this.setUpYourAccountForm.patchValue({ confirmEmail: confirmEmailAddress });
    }

    if (this.companySettings?.pptIsEmailAsUserName) {
      this.setUpYourAccountForm.addControl(
        'email',
        new FormControl({ value: '', disabled: !this.companySettings?.pptIsAllowEditCredential }, 
          [ Validators.required,Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
        ])
      );

      if (companyData?.tpaCode === 'NYS') {
        this.setUpYourAccountForm.patchValue({ email: personalInfo?.email });
      } else {
        if (userNameEnroll?.emailAddress.toLowerCase().indexOf('noemail.com') > -1) {
          this.setUpYourAccountForm.patchValue({ email: '' });
          this.setUpYourAccountForm.get('email').enable();
        } else {
          this.setUpYourAccountForm.patchValue({ email: userNameEnroll?.emailAddress });
        }
      }
    } else {
      this.setUpYourAccountForm.addControl(
        'userName',
        new FormControl({ value: '', disabled: !this.companySettings?.pptIsAllowEditCredential }, [
          Validators.required,
          Validators.minLength(8),
        ])
      );
      if (this.companySettings?.tpaCompanyID.toLowerCase() !== 'google') {
        this.setUpYourAccountForm.patchValue({ userName: userNameEnroll?.userName });
      }
    }

    this.userName = userNameEnroll?.userName;
    this.emailAddress = userNameEnroll?.emailAddress;
    this.firstName = userNameEnroll?.firstName;
    this.lastName = userNameEnroll?.lastName;
    this.idMember = userNameEnroll?.idMember;
  }

  isInvalidEmail(email: string): boolean {
    const invalidEmail = [
      '@noemail.com',
      '@noemail7.com',
      '@noname.com',
      'email@email.com',
      'email@noemail7.com',
      'noname@noname.com',
    ];
    return !email || invalidEmail.some(domain => email.endsWith(domain));
  }

  isPasswordValid(type: string | string[]): boolean {
    const password = this.setUpYourAccountForm.get('password')?.value;
    if (Array.isArray(type)) {
      return type.every(t => this.isPasswordValid(t));
    }
    switch (type) {
      case 'length':
        return password.length >= 8;
      case 'uppercase':
        return /[A-Z]/.test(password);
      case 'lowercase':
        return /[a-z]/.test(password);
      case 'number':
        return /\d/.test(password);
      case 'special':
        return /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);
      default:
        return false;
    }
  }

  onNext() {
    if (this.setUpYourAccountForm.valid) {
      const emailAddress: UpdateMemberEmailAddressDTO = {
        idMember: this.idMember,
        emailAddress: this.setUpYourAccountForm.get('confirmEmail').value,
      };
      this.userRegistrationService.setEmail(this.setUpYourAccountForm.get('confirmEmail').value);
      this.authService.addEmailAddress(emailAddress).subscribe((response: any) => {
        if (response) {
          const createUserPayload: CreateUserDTO = {
            username: this.companySettings?.pptIsEmailAsUserName
              ? this.setUpYourAccountForm.get('confirmEmail')?.value
              : this.setUpYourAccountForm.get('userName')?.value,
            password: this.setUpYourAccountForm.get('password')?.value,
            confirmPassword: this.setUpYourAccountForm.get('confirmPassword')?.value,
            redirectUri: window.location.origin + '/profile-setup',
            firstName: this.tpaCode === 'NYS' ? this.nysFirstName : this.firstName,
            lastName: this.tpaCode === 'NYS' ? this.nysLastName : this.lastName,
            idMember: this.idMember,
          };
          let acceptTerms: AuthAcceptTerms = {};
          acceptTerms.idMember = this.idMember;
          acceptTerms.termType = TermsType.PPandTOFU;
          acceptTerms.isMarketingCommunicationsAccepted = this.onCheckMarketingCommunication;

          this.authService.createUser(createUserPayload).subscribe((result: any) => {
            if (result.succeeded) {
              this.authService.acceptTerms(acceptTerms).subscribe(res => {
                if (res) {
                }
              });
              this.userRegistrationService.setUpdatedUsername = createUserPayload.username;
              this.router.navigate(['/resend-email']);
            } else if (result.errors && result.errors[0].code === 'UserExists') {
              this.notificationService.displayMessage(
                'This user has already been registered. Please try again or contact our helpful Customer Service team at 888-235-9223.',
                MessageType.Error
              );
            }
          });
        }
      });
    }
  }
  onCheckBox($event: any): void {
    this.onCheckMarketingCommunication = $event.target.checked;
  }
}
