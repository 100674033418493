import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MessageType, Messages } from 'src/app/shared/app.constants';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private notificationService: NotificationService) {
    super();
  }

  override handleError(error: HttpErrorResponse): void {
    let errorMessage = '';

    // if (error.error instanceof ErrorEvent) {
    //   errorMessage = `Error: ${error.error.message}`;
    // } else {
    //   errorMessage = `Error: ${error.message}`;
    // }
    switch (error.status) {
      case 404:
        errorMessage = Messages.apiError;
        break;
      case 400:
        let errorMess = Messages.apiError;
        if (error.error && typeof error.error === 'object') {
          if (Array.isArray(error.error)) {
            errorMess = error?.error[0]?.ruleMessage;
          }
        } else if (error.error && typeof error.error === 'string') {
          errorMess = error?.error;
        }
        this.notificationService.displayMessage(errorMess, MessageType.Error);
        break;
      case 401:
        errorMessage = Messages.apiError;
        break;
      case 500:
        errorMessage = Messages.apiError;
        break;
      default:
        errorMessage = Messages.apiError;
    }
    // show this error message in notification - uncomment below line
    if (errorMessage) {
      this.notificationService.globalError = true;
      this.notificationService.displayGlobalMessage(errorMessage, MessageType.Error);
    }
    // call log service method to log the trace
  }
}
