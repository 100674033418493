<div class="container">
  <div class="row eb-cards mt-4">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">
      <div class="border-0 card login-info p-2">
        <h4 class="font-weight-bold text-lg-center mt-4 mt-lg-4 font-24">Hello {{ name }}</h4>
        <div class="row mx-0 mt-lg-3">
          <div class="col-lg-12">
            <p class="font-16">We look forward to providing you an exceptional employee benefits experience.</p>
            <p class="font-16 mt-3 mt-lg-3">
              Our passion for customers is what drives us every day. We’re happy to support you via phone or email if
              you have any questions.
            </p>
            <p class="font-16 mt-3 mt-lg-3">
              Let’s get started by confirming your contact info and delivery address. This will make future interactions
              on our site easy.
            </p>
          </div>
          <div class="text-lg-end float-lg-end mt-4 mt-lg-4 mb-4 mb-lg-4">
            <a href="#" aria-label="Skip" class="font-16" [routerLink]="'/profile-setup'">>Skip</a>
            <button
              class="bold btn btn-primary d-inline font-16 text-center w-auto pl-4 pr-4"
              aria-label="Continue"
              [routerLink]="'/my-profile'">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</div>
