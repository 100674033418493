import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';

@Component({
  selector: 'eb-user-detail-modal.component',
  templateUrl: './user-detail-modal.component.html',
  styleUrls: ['./user-detail-modal.component.scss'],
})
export class UserDetailModalComponent implements OnInit, AfterViewInit {
  isAuthenticated: boolean = false;

  constructor(
    private route: Router,
    private authService: AuthService,
    public modalRef: MdbModalRef<UserDetailModalComponent>
  ) {}

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated;
  }

  ngAfterViewInit(): void {}

  logout(): void {
    this.authService.logout();
  }
  login(): void {
    this.route.navigateByUrl('/');
  }

  openEditMemberProfile() {
    this.route.navigateByUrl('/member-profile/member-profile-home');
    this.closeEditProfile();
  }

  closeEditProfile(data?: any): void {
    this.modalRef.close(data);
  }
}
