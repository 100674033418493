import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartProductDTOExtended, TransitAuthorityDetailExtended } from '../models/app';
import { TransportationTypeDTO } from '../models/member';
import { OrderProductInfo } from '../models/order';
import {
  ProductCustomField,
  ProductDeliveryMethodDTO,
  ProductDetailInfo,
  ProductLostReasonDTO,
  ProductSimpleInfoWithTermAndCondition,
  SmartCardReplacementDTO,
  TAPreferredProductDTO,
  TransitAuthorityDetail,
  TransitStation,
  TransitStationLineDTO,
} from '../models/product';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  apiName = 'productService';
  products$: Observable<ProductSimpleInfoWithTermAndCondition[]> = of(undefined);
  private productType: string;
  private transportationCategory: string;
  private product: ProductSimpleInfoWithTermAndCondition;
  private transportationType: TransportationTypeDTO;
  private preTransportationType: TransportationTypeDTO;
  private shoppingCartproduct: CartProductDTOExtended;
  private idGroupVentra: number;
  private orderDetails: OrderProductInfo;
  private isRecurring: boolean;

  public set setOrderDetails(item: OrderProductInfo) {
    this.orderDetails = item;
  }
  public get getOrderDetails() {
    return this.orderDetails;
  }
  public get getProductType() {
    return this.productType;
  }

  public set setProductType(productType: string) {
    this.productType = productType;
  }

  public get getTransportationCategory(): string {
    return this.transportationCategory;
  }

  public set setTransportationCategory(transportationCategory: string) {
    this.transportationCategory = transportationCategory;
  }

  public get getProduct(): ProductSimpleInfoWithTermAndCondition {
    return this.product;
  }

  public set setProduct(product: ProductSimpleInfoWithTermAndCondition) {
    this.product = product;
  }

  public get getIsRecurring(): boolean {
    return this.isRecurring;
  }

  public set setIsRecurring(isRecurring: boolean) {
    this.isRecurring = isRecurring;
  }

  public get getIdGroupVentra(): number {
    return this.idGroupVentra;
  }

  public set setIdGroupVentra(idProduct: number) {
    this.idGroupVentra = idProduct;
  }

  public get getShoppingCartProduct(): CartProductDTOExtended {
    return this.shoppingCartproduct;
  }

  public set setShoppingCartProduct(product: CartProductDTOExtended) {
    this.shoppingCartproduct = product;
  }

  public get getTransportationType(): TransportationTypeDTO {
    return this.transportationType;
  }

  public set setTransportationType(transportationType: TransportationTypeDTO) {
    this.transportationType = transportationType;
  }
  public get getPreTransportationType(): TransportationTypeDTO {
    return this.preTransportationType;
  }

  public set setPreTransportationType(transportationType: TransportationTypeDTO) {
    this.preTransportationType = transportationType;
  }
  constructor(private http: HttpClient) {}

  /**
   * @param dtStartDate (optional)
   * @param dtEndDate (optional)
   * @return Success
   */
  getPopularProducts(
    zipHome?: string,
    zipWork?: string,
    idCompany?: number,
    commutingTypes?: string,
    source?: string
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/PopularProducts?';
    url = url + 'IdCompany=' + idCompany;
    if (zipHome) {
      url = url + '&ZipHome=' + zipHome;
    }
    if (zipWork) {
      url = url + '&ZipWork=' + zipWork;
    }
    url = url + '&CommutingTypes=' + commutingTypes;
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  getLocalTransitAuthorityProducts(
    zipHome?: string,
    zipWork?: string,
    idCompany?: number,
    commutingTypes?: string,
    enableStrippedDownOrderFlow?: boolean,
    source?: string
  ): Observable<TransitAuthorityDetailExtended[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/LocalTransitAuthorities?';
    if (idCompany) {
      url = url + 'IdCompany=' + idCompany;
    }
    if (zipHome) {
      url = url + '&ZipHome=' + zipHome;
    }
    if (zipWork) {
      url = url + '&ZipWork=' + zipWork;
    }
    if (commutingTypes) {
      url = url + '&CommutingTypes=' + commutingTypes;
    }
    url = url + '&IncludeWCTA=' + enableStrippedDownOrderFlow;
    return this.http.get<TransitAuthorityDetailExtended[]>(url);
  }
  getTATransitProducts(
    idCompany?: number,
    idProductOfferingPlatform?: number,
    idTransitAuthority?: number,
    commutingTypes?: string
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/TATransitProduct?';
    if (idCompany) {
      url = url + 'IdCompany=' + idCompany;
    }
    if (idProductOfferingPlatform) {
      url = url + '&IdProductOfferingPlatform=' + idProductOfferingPlatform;
    }
    if (idTransitAuthority) {
      url = url + '&IdTransitAuthority=' + idTransitAuthority;
    }
    if (commutingTypes) {
      url = url + '&CommutingTypes=' + commutingTypes;
    }
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  getProductSimpleInfo(idProduct?: number): Observable<ProductSimpleInfoWithTermAndCondition> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/GetProductSimpleInfo?idProduct=' + idProduct;
    return this.http.get<ProductSimpleInfoWithTermAndCondition>(url);
  }
  getProductCustomFields(idProduct: number): Observable<ProductCustomField[]> {
    const url: string =
      AppConfigService.apiURL(this.apiName) + '/Product/GetProductCustomFields?idProduct=' + idProduct;
    return this.http.get<ProductCustomField[]>(url);
  }
  getOfferedProductDetail(idCompany?: number, idProduct?: number): Observable<ProductDetailInfo> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/OfferedProductDetail?';
    url += 'idCompany=' + idCompany;
    url += '&idProduct=' + idProduct;
    return this.http.get<ProductDetailInfo>(url);
  }
  getStationLinesByTransitAuthority(idTransitAuthority: number): Observable<TransitStationLineDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/{idTransitAuthority}/StationLines';
    url = url.replace('{idTransitAuthority}', idTransitAuthority.toString());
    return this.http.get<TransitStationLineDTO>(url);
  }
  getTransitStationLine(idStationLine: number): Observable<string> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/{idStationLine}/TransitStationLine';
    url = url.replace('{idStationLine}', idStationLine.toString());
    return this.http.get<string>(url);
  }
  getStationNameByStationId(idStation: number): Observable<string> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/{idStation}/StationName';
    url = url.replace('{idStation}', idStation.toString());
    return this.http.get<string>(url);
  }
  getStations(
    idProduct?: number,
    filteredByIdStation?: number,
    filteredByIdStationLine?: number
  ): Observable<TransitStation> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/Stations';
    url = url + '?idProduct=' + idProduct;
    url = url + '&filteredByIdStation=' + filteredByIdStation;
    url = url + '&filteredByIdStationLine=' + filteredByIdStationLine;
    return this.http.get<TransitStation>(url);
  }
  getCurrentPrice(
    idProduct?: number,
    idStationA?: number,
    idStationB?: number,
    benefitMonth?: number,
    gender?: string
  ): Observable<number> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/CurrentPrice';
    if (idProduct) {
      url = url + '?ProductId=' + idProduct;
    }
    if (idStationA) {
      url = url + '&StationIdA=' + idStationA;
    }
    if (idStationB) {
      url = url + '&StationIdB=' + idStationB;
    }
    if (benefitMonth) {
      url = url + '&BenefitMonth=' + benefitMonth;
    }
    if (gender) {
      url = url + '&gender=' + gender;
    }
    return this.http.get<number>(url);
  }
  getTransitAuthority(idTransitAuthority?: number): Observable<TransitAuthorityDetail> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/' + idTransitAuthority + '/Get';
    return this.http.get<TransitAuthorityDetail>(url);
  }
  getTAPreferredProduct(idTransitAuthority?: number, idCompany?: number): Observable<TAPreferredProductDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Product/GetTAPreferredProduct';
    if (idTransitAuthority) {
      url = url + '?idTransitAuthority=' + idTransitAuthority;
    }
    if (idCompany) {
      url = url + '&idCompany=' + idCompany;
    }
    return this.http.get<TAPreferredProductDTO[]>(url);
  }
  getTransittProductsByGroup(idCompany?: number, idProduct?: number): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/TransitAuthority/{idGroup}/groupproducts/get';
    if (idCompany) {
      url = url.replace('{idGroup}', idProduct.toString());
    }
    if (idProduct) {
      url = url + '?idCompany=' + idCompany;
    }
    return this.http.get<any>(url);
  }
  getCompanyPcccProducts(idCompany?: number): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/product/companypcccproduct';
    if (idCompany) {
      url = url + '?idCompany=' + idCompany;
    }

    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  getSameCodeProducts(idCompany?: number, idProduct?: number): Observable<any[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/product/samecodeproducts';
    if (idCompany) {
      url = url + '?idCompany=' + idCompany;
    }
    if (idProduct) {
      url = url + '&idProduct=' + idProduct;
    }

    return this.http.get<any[]>(url);
  }
  getOfferedMbbProducts(
    idCompany?: number,
    transportationTypeCode?: string
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/product/offeredmbbproducts';
    if (idCompany) {
      url = url + '?idCompany=' + idCompany;
    }
    url = url + '&TransportationTypeCode=' + transportationTypeCode;
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  getOfferedBikingProducts(
    idCompany?: number,
    transportationTypeCode?: string
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/product/' + idCompany + '/OfferedBikingProducts';
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  searchProducts(
    zipHome?: string,
    zipWork?: string,
    idCompany?: number,
    commutingTypes?: string,
    taName?: string
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/product/taproductsearch';
    if (idCompany) {
      url = url + '?idCompany=' + idCompany;
    }
    if (zipHome) {
      url = url + '&zipHome=' + zipHome;
    }
    if (zipWork) {
      url = url + '&zipWork=' + zipWork;
    }
    if (commutingTypes) {
      url = url + '&CommutingTypes=' + commutingTypes;
    }
    if (taName) {
      url = url + '&taName=' + taName;
    }
    url = url + '&IncludeWCTA=false';
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }

  getProductLostReasons(idProduct: number): Observable<ProductLostReasonDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/product/getproductlostreasons{idProduct}';
    if (idProduct) {
      url_ = url_.replace('{idProduct}', idProduct.toString());
    }
    return this.http.get<ProductLostReasonDTO[]>(url_);
  }
  getProductDeliveryMethod(
    idProduct: number,
    idTPA: number,
    idCompany: number
  ): Observable<ProductDeliveryMethodDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/product/getproductdeliverymethod';
    if (idProduct) {
      url_ = url_ + '?idProduct=' + idProduct;
    }
    if (idTPA) {
      url_ = url_ + '&idTPA=' + idTPA;
    }
    if (idCompany) {
      url_ = url_ + '&idCompany=' + idCompany;
    }
    return this.http.get<ProductDeliveryMethodDTO[]>(url_);
  }
  submitSCRequest(replacementRequest: SmartCardReplacementDTO): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/product/reportsmartcardreplacement';
    return this.http.post<boolean>(url_, replacementRequest);
  }
}
