import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';

import { SubsidyDTO } from 'src/app/core/models/subsidy';
import { SubsidyService } from 'src/app/core/services/subsidy.service';
import { AppState } from '../app.reducer';
import * as subsidyAction from './subsidy.action';

@Injectable()
export class SubsidyEffect {
  constructor(private subsidyService: SubsidyService, private action$: Actions, private store: Store<AppState>) {}

  loadSubsidy$ = createEffect(() =>
    this.action$.pipe(
      ofType(subsidyAction.SubsidyActionTypes.SUBSIDY_LOAD),
      switchMap((action: any) => {
        return this.subsidyService.getSubsidyData(action.idMember).pipe(
          map((subsidyData: SubsidyDTO[]) => {
            return new subsidyAction.SubsidyLoadSuccess(subsidyData);
          }),
          catchError(err => of(new subsidyAction.SubsidyLoadFail(err)))
        );
      })
    )
  );
}
