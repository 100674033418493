import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs/internal/observable/of';

import { ContentManagementDTO } from 'src/app/core/models/content-management';
import { ContentManagementService } from 'src/app/core/services/content-management.service';
import * as contentManagementAction from './content-management.action';
@Injectable()
export class ContentManagementEffect {
  constructor(private contentManagementService: ContentManagementService, private action$: Actions) {}

  loadContent$ = createEffect(() =>
    this.action$.pipe(
      ofType(contentManagementAction.ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD),
      switchMap((action: any) => {
        return this.contentManagementService.getPageContent('All').pipe(
          map((contentData: ContentManagementDTO[]) => {
            return new contentManagementAction.ContentManagementLoadSuccess(contentData);
          }),
          catchError(err => of(new contentManagementAction.ContentManagementLoadFail(err)))
        );
      })
    )
  );
}
