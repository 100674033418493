import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUtils } from '../core/app-utils';
import { MemberDTO } from '../core/models/member';
import { MemberService } from '../core/services/member.service';
import { CardDispatcher } from './card/card.dispatcher';
import { ClaimDispatcher } from './claim/claim.dispatcher';
import { CompanyDispatcher } from './company/company.dispatcher';
import { ContentManagementDispatcher } from './content-management/content-management.dispatcher';
import { MemberDispatcher } from './member/member.dispatcher';
import { ShoppingCartDispatcher } from './shopping-cart/shopping-cart.dispatcher';
import { SubsidyDispatcher } from './subsidy/subsidy.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class AppDispatcher implements OnDestroy {
  memberSubscription: Subscription;
  constructor(
    private companyDispatcher: CompanyDispatcher,
    private memberDispatcher: MemberDispatcher,
    private cardDispatcher: CardDispatcher,
    private claimDispatcher: ClaimDispatcher,
    private contentManagementDispatcher: ContentManagementDispatcher,
    private memberService: MemberService,
    private shoppingCartDispatcher: ShoppingCartDispatcher,
    private subsidyDispatcher: SubsidyDispatcher
  ) {
    this.initializeStore();
  }
  ngOnDestroy(): void {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
  }
  initializeStore(): void {
    this.contentManagementDispatcher.dispatchContent();
    this.cardDispatcher.dispatchCards(Number(AppUtils.memberId));
    this.claimDispatcher.dispatchClaims(Number(AppUtils.memberId));
    this.memberDispatcher.dispatchMember(Number(AppUtils.memberId));
    this.memberDispatcher.selectStore();
    this.memberSubscription = this.memberService.member$?.subscribe((response: MemberDTO) => {
      if (response) {
        this.companyDispatcher.dispatchCompany(response.idCompany);
        this.subsidyDispatcher.dispatchSubsidy(response.idMember);
      }
    });
    this.shoppingCartDispatcher.dispatchShoppingCartDetails(Number(AppUtils.memberId));
    this.companyDispatcher.selectStore();
    this.contentManagementDispatcher.selectStore();
    this.cardDispatcher.selectStore();
    this.claimDispatcher.selectStore();
    this.shoppingCartDispatcher.selectStore();
    this.subsidyDispatcher.selectStore();
  }
}
