<div class="eb-cards row mx-0 pl-0 pr-0">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="mx-0 p-2 pb-lg-3 row">
        <div class="col-6 col-xl-6">
          <h3 class="modal-title pt-2" id="messageModalLabel">
            <span class="font-weight-700"> Alerts</span>
          </h3>
        </div>
        <div class="col-6 col-xl-6 text-right mt-2">
          <img src="assets/images/eye-icon.svg" height="16" width="16" />
          <span class="font-weight-400 text-blue underline font-12 cursor-pointer" (click)="markAllAsRead()">
            Mark all as read</span
          >
        </div>
        <!-- <div class="col-xl-1 col-1 text-right pl-0 pl-lg-0 pr-lg-0 pr-0">
          <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="closeModal()">
            <img src="assets/images/close.png" class="close-focus" alt="icon" />
          </button>
        </div> -->
      </div>

      <div class="mt-2 pb-0">
        <mdb-tabs class="notification-tab mr-lg-2 mt-lg-2">
          <mdb-tab>
            <ng-template mdbTabTitle>
              <span
                class="badge rounded-pill badge-notification notification_icon_color text-white"
                *ngIf="activeNotificationCount > 0"
                >{{ activeNotificationCount }}</span
              ><span class="ml-4 notification-tab-title">Active</span></ng-template
            >
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-lg-3 mb-xl-3 mt-4 mt-lg-3 mt-xl-4 pl-0 pr-0 ng-star-inserted"
              *ngIf="notificationList?.length === 0">
              <div class="border-0">
                <div class="mx-0 p-2 pb-3 pt-3 row mt-3" class="error-message-container ml-3 mr-3">
                  <div class="col-xl-12 col-12 pt-3 pb-3 alerts-error">
                    <div class="ml-4">You do not have any notifications.</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row mx-0 p-3 notification-content cursor-pointer"
              *ngFor="let notification of notificationList"
              [ngClass]="{ 'read-notification': !notification?.isReadByMember }"
              (click)="markAsRead(notification)">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <span class="font-weight-700 font-12">{{ notification?.subject }}</span>
              </div>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <span class="font-weight-400 font-12">{{ notification?.body + '...' }}</span>
                <p *ngIf="!notification?.idDocuments" class="font-weight-700 font-12 mb-0 mt-1">
                  <a aria-label="View More" class="text-blue" (click)="openMessageModal(notification)">View More ></a>
                </p>
                <p *ngIf="notification?.idDocuments" class="font-weight-700 font-12 text-underline mb-0 mt-1">
                  <a>View Document</a>
                </p>
                <span class="font-weight-400 font-12 text-gray">{{ notification?.publishDate | dateAgo }}</span>
              </div>
            </div>
          </mdb-tab>
          <mdb-tab [disabled]="true">
            <ng-template mdbTabTitle> <span class="ml-4 notification-tab-title"> </span></ng-template>
            <div
              class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-lg-3 mb-xl-3 mt-4 mt-lg-3 mt-xl-4 pl-0 pr-0 ng-star-inserted"
              *ngIf="notificationArchivedList?.length === 0">
              <div class="border-0">
                <div class="mx-0 p-2 pb-3 pt-3 row mt-3" class="error-message-container ml-3 mr-3">
                  <div class="col-xl-12 col-12 pt-3 pb-3 alerts-error">
                    <div class="ml-4">You do not have any archived notifications.</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row mx-0 p-3 notification-content"
              *ngFor="let notificationArchived of notificationArchivedList">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <span class="font-weight-700 font-12">{{ notificationArchived?.subject }}</span>
              </div>
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <span class="font-weight-400 font-12">{{ notificationArchived?.body + '...' }}</span
                ><span class="font-weight-700 font-12 ml-1">View More >></span>
              </div>
              <!-- <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <span class="font-weight-400 font-12" [innerHTML]="notification?.boday"></span>
              </div> -->
            </div>
          </mdb-tab>
        </mdb-tabs>
      </div>
    </div>
  </div>
</div>
