import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { AppUtils } from 'src/app/core/app-utils';

@Directive({
  selector: '[ebAppRole]',
})
export class EbAppRoleDirective implements OnInit {
  @Input('ebAppRole') roleName: string = '';
  @Input('ebAppRoleShow') show: boolean = true;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    const hasRole = AppUtils.isInRoles(this.roleName);
    if ((hasRole && this.show) || (!hasRole && !this.show)) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
