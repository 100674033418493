<div class="container">
  <div class="row eb-cards mt-4 mx-0">
    <div class="col-md-3 col-xl-4"></div>
    <div class="col-md-6 col-xl-4">
      <div class="border-0 card login-info p-2 p-md-4 pb-lg-4 pl-lg-4 pr-lg-4 pt-lg-4">
        <div class="align-items-baseline mx-0 row">
          <div class="col-lg-12 text-lg-left pl-lg-0 reset-pwd">
            <h5 class="font-weight-bold text-lg-center">Your verification link expired</h5>
            <p class="font-weight-normal mt-lg-2">
              We’ll need to verify your account via your email address to complete your registration.
            </p>
          </div>
        </div>
        <div class="align-items-center mt-3 mx-0 row">
          <div class="col-xl-12 text-center employee_verification">
            <button
              aria-label="emailResent"
              class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
              type="submit"
              (click)="emailResent()">
              Send New Verification Email
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</div>
