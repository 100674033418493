import { Action } from '@ngrx/store';
import { ProductSimpleInfoWithTermAndCondition } from 'src/app/core/models/product';

export enum ProductActionTypes {
  PRODUCT_LOAD = 'PRODUCT_LOAD',
  PRODUCT_LOAD_SUCCESS = 'PRODUCT_LOAD_SUCCESS',
  PRODUCT_LOAD_FAIL = 'PRODUCT_LOAD_FAIL',
}

export class ProductLoad implements Action {
  readonly type = ProductActionTypes.PRODUCT_LOAD;
  constructor(public idCompany: number, public transportationTypeCode: string) {}
}

export class ProductLoadSuccess implements Action {
  readonly type = ProductActionTypes.PRODUCT_LOAD_SUCCESS;
  constructor(public payload: ProductSimpleInfoWithTermAndCondition[]) {}
}

export class ProductLoadFail implements Action {
  readonly type = ProductActionTypes.PRODUCT_LOAD_FAIL;
  constructor(public payload: { error: string }) {}
}

export type ProductActions = ProductLoad | ProductLoadSuccess | ProductLoadFail;
