import { Action } from '@ngrx/store';
import { SubsidyDTO } from 'src/app/core/models/subsidy';

export enum SubsidyActionTypes {
  SUBSIDY_LOAD = 'SUBSIDY_LOAD',
  SUBSIDY_LOAD_SUCCESS = 'SUBSIDY_LOAD_SUCCESS',
  SUBSIDY_LOAD_FAIL = 'SUBSIDY_LOAD_FAIL',
}

export class SubsidyLoad implements Action {
  readonly type = SubsidyActionTypes.SUBSIDY_LOAD;
  constructor(public idMember: number) {}
}

export class SubsidyLoadSuccess implements Action {
  readonly type = SubsidyActionTypes.SUBSIDY_LOAD_SUCCESS;
  constructor(public data: SubsidyDTO[]) {}
}

export class SubsidyLoadFail implements Action {
  readonly type = SubsidyActionTypes.SUBSIDY_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}
// Union the valid types
export type SubsidyActions = SubsidyLoad | SubsidyLoadSuccess | SubsidyLoadFail;
