<div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-lg-3 mb-xl-2 mt-3 mt-lg-3 mt-xl-0 pl-0 pr-0">
  <div class="border-0">
    <div class="mx-0 p-2 pb-3 pt-3 row mt-3" [ngClass]="getMessageTypeClass(messageType)">
      <div class="col-xl-9 col-9">
        <p class="font-16 font-weight-normal mb-0 pt-2" [ngClass]="getMessageIconClass(messageType)">
          <span [innerHTML]="errorMessage | safeHTML"></span>
        </p>
      </div>
      <div class="col-xl-3 text-right col-3">
        <button type="button" aria-label="Close" class="bg-transparent border-0" (click)="onClose()">
          <img src="assets/images/close.svg" alt="close" />
        </button>
      </div>
    </div>
  </div>
</div>
