<div>
  <eb-navbar> </eb-navbar>
</div>
<div class="mt-4">
  <div class="f-inner">
    <div class="eb-cards row mx-0">
      <div class="col-xl-12">
        <div class="card border-0">
          <div class="row mx-0">
            <div class="col-lg-12">
              <div class="pt-lg-4 pl-lg-4" style="min-height: calc(100vh - 160px)">
                <h1 class="font-weight-bold mb-0 pt-1 heading">Access Denied</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
