import { Action } from '@ngrx/store';
import { CartDetailDTO } from 'src/app/core/models/shopping-cart';

export enum ShoppingCartActionTypes {
  SHOPPINGCART_LOAD = 'SHOPPINGCART_LOAD',
  SHOPPINGCART_LOAD_SUCCESS = 'SHOPPINGCART_LOAD_SUCCESS',
  SHOPPINGCART_LOAD_FAIL = 'SHOPPINGCART_LOAD_FAIL',
  SHOPPINGCART_LOAD_UPDATE = 'SHOPPINGCART_LOAD_UPDATE',
}

export class ShoppingCartLoad implements Action {
  readonly type = ShoppingCartActionTypes.SHOPPINGCART_LOAD;
  constructor(public idMember: number) {}
}

export class ShoppingCartLoadSuccess implements Action {
  readonly type = ShoppingCartActionTypes.SHOPPINGCART_LOAD_SUCCESS;
  constructor(public data: CartDetailDTO[]) {}
}

export class ShoppingCartLoadFail implements Action {
  readonly type = ShoppingCartActionTypes.SHOPPINGCART_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

export class ShoppingCartDetailsUpdate implements Action {
  readonly type = ShoppingCartActionTypes.SHOPPINGCART_LOAD_UPDATE;
  constructor(public data: CartDetailDTO[]) {}
}
export type ShoppingCartActions =
  | ShoppingCartLoad
  | ShoppingCartLoadSuccess
  | ShoppingCartLoadFail
  | ShoppingCartDetailsUpdate;
