import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUtils } from '../core/app-utils';

@Component({
  selector: 'eb-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})

export class AuthCallbackComponent implements OnInit {
 
  constructor(private router: Router) {}

  private appUrl = 'edenredusabenefits://app.in'; // Your custom URI scheme

  ngOnInit() {
    alert("Before OpenApp()")
    this.openApp();
    alert("After OpenApp()")
  }

  isMobile(): boolean {
    let isMob = /Mobi/i.test(navigator.userAgent);
    alert(navigator.userAgent);
    alert("isMob:=" + isMob);
    return isMob;
  }

  isSafari(): boolean {
    const userAgent = navigator.userAgent;
    let isSaf = /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent);

    alert("isMob:=" + isSaf);

    return isSaf;
  }

  navigateToAppRoot() {
    alert("Navigating to App Root");
    this.router.navigateByUrl('/');
  }

  private openApp(): void {
    alert("openApp");
    if (this.isMobile && !this.isSafari()) {
      alert("Iside check openApp");
      window.location.href = this.appUrl;
      alert("after first href: " + this.appUrl);
      setTimeout(() => {
        alert("Iside check openApp after timeout");
        window.location.href = "https://www.google.com"
      }, 2000); // Adjust this delay as needed
    }
    else {
      alert("else check openApp after timeout");
      this.navigateToAppRoot();
    }
  }
}



