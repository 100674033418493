import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationConfig, MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ErrorMessageComponent } from 'src/app/shared/components/error-message/error-message.component';
import { MessageComponent } from 'src/app/shared/modal/message/message.component';
import { ToastComponent } from '../../../shared/components/toast/toast.component';
import { ToastrType } from '../../../shared/components/toast/toast.modal';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { EmailQueueDTO, EmailQueueDetailDTO } from '../../models/notification';
import { HttpClient } from '@angular/common/http';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationRef: MdbNotificationRef<ToastComponent> | null = null;
  toastrConfig: MdbNotificationConfig = {};
  modalRef: any | MdbModalRef<MessageComponent>;
  targetElement: ViewContainerRef;
  globalTargetElement: ViewContainerRef;
  globalError = false;
  rootViewContainer: any;
  globalRootViewContainer: any;
  components = [];
  globalComponent = [];
  apiName = 'notificationService';
  constructor(
    private mdbNotificationService: MdbNotificationService,
    private modalService: MdbModalService,
    private factoryResolver: ComponentFactoryResolver,
    private router: Router,
    private http: HttpClient
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.resetNotification();
      }
    });
  }
  setConfig(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.toastrConfig = AppConfigService.settings.toastrConfig;
      resolve();
    });
  }
  success(message: string): void {
    this.toastrConfig.data = { text: message, toastr: ToastrType.Success };
    this.notificationRef = this.mdbNotificationService.open(ToastComponent, this.toastrConfig);
  }
  error(message: string): void {
    this.toastrConfig.data = { text: message, toastr: ToastrType.Error };
    this.notificationRef = this.mdbNotificationService.open(ToastComponent, this.toastrConfig);
  }
  info(message: string): void {
    this.toastrConfig.data = { text: message, toastr: ToastrType.Info };
    this.notificationRef = this.mdbNotificationService.open(ToastComponent, this.toastrConfig);
  }
  warning(message: string): void {
    this.toastrConfig.data = { text: message, toastr: ToastrType.Warning };
    this.notificationRef = this.mdbNotificationService.open(ToastComponent, this.toastrConfig);
  }
  openPopupMessage = (
    title: string,
    message: string,
    showCancelButton: boolean,
    cancelText: string,
    cssClass: string,
    isHTML?: boolean,
    showActionButton?: boolean,
    btnText?: string,
    messageToSendOnClose?: string
  ) => {
    return this.modalService.open(MessageComponent, {
      data: {
        title: title,
        isHTML: isHTML ? isHTML : false,
        message: message,
        showCancelButton: showCancelButton,
        showActionButton: showActionButton,
        cancelText: cancelText,
        btnText: btnText,
        messageToSendOnClose: messageToSendOnClose,
      },
      modalClass: cssClass,
      ignoreBackdropClick: true,
      keyboard: false,
    });
  };

  public displayMessage(message: string, messageType: string) {
    this.setRootViewContainerRef();
    this.addErrorComponent(message, messageType);
  }
  public displayGlobalMessage(message: string, messageType: string) {
    this.setGlobalRootViewContainerRef();
    this.addGlobalErrorComponent(message, messageType);
  }
  public setRootViewContainerRef() {
    this.rootViewContainer = this.targetElement;
  }
  public setGlobalRootViewContainerRef() {
    this.globalRootViewContainer = this.globalTargetElement;
  }
  public addErrorComponent(message: string, messageType: string) {
    if (this.rootViewContainer) {
      const factory = this.factoryResolver.resolveComponentFactory(ErrorMessageComponent);
      const component = factory.create(this.rootViewContainer.parentInjector);
      component.instance.errorMessage = message;
      component.instance.messageType = messageType;

      if (AppConfigService.settings.errorConfig.stacking) {
        this.rootViewContainer.insert(component.hostView);
      } else {
        // const componentTest = this.components.find(comp => component.instance instanceof ErrorMessageComponent);
        const componentTest = this.components.find(comp => comp.componentType === component.componentType);
        const componentIndex = this.components.indexOf(componentTest);
        if (componentIndex < 0) {
          this.rootViewContainer.insert(component.hostView);
          this.components.push(component);
        }
      }

      if (AppConfigService.settings.errorConfig.autohide) {
        setTimeout(() => {
          this.removeComponent();
        }, AppConfigService.settings.errorConfig.delay);
      }
    }
  }
  public addGlobalErrorComponent(message: string, messageType: string) {
    if (this.globalRootViewContainer) {
      const factory = this.factoryResolver.resolveComponentFactory(ErrorMessageComponent);
      const component = factory.create(this.globalRootViewContainer.parentInjector);
      component.instance.errorMessage = message;
      component.instance.messageType = messageType;

      if (AppConfigService.settings.errorConfig.stacking) {
        this.globalRootViewContainer.insert(component.hostView);
      } else {
        const componentTest = this.globalComponent.find(
          component => component.instance instanceof ErrorMessageComponent
        );

        const componentIndex = this.globalComponent.indexOf(componentTest);
        if (componentIndex < 0) {
          this.globalRootViewContainer.insert(component.hostView);
          this.globalComponent.push(component);
        }
      }

      if (AppConfigService.settings.errorConfig.autohide) {
        setTimeout(() => {
          this.removeGlobalComponent();
        }, AppConfigService.settings.errorConfig.delay);
      }
    }
  }
  public removeComponent() {
    const component = this.components.find(component => component.instance instanceof ErrorMessageComponent);
    const componentIndex = this.components.indexOf(component);
    if (componentIndex > -1 && this.targetElement.indexOf(component.hostView) > -1) {
      this.targetElement.remove(this.targetElement.indexOf(component.hostView));
      this.components.splice(componentIndex, 1);
    } else {
      this.components.splice(componentIndex, 1);
    }
  }
  public removeGlobalComponent() {
    const component = this.globalComponent.find(component => component.instance instanceof ErrorMessageComponent);
    const componentIndex = this.globalComponent.indexOf(component);
    if (componentIndex > -1 && this.globalTargetElement.indexOf(component.hostView) > -1) {
      this.globalTargetElement.remove(this.globalTargetElement.indexOf(component.hostView));
      this.globalComponent.splice(componentIndex, 1);
    } else {
      this.globalComponent.splice(componentIndex, 1);
    }
  }
  public resetNotification() {
    this.globalComponent = [];
    this.components = [];
  }

  getMemberEmailHistory(memberId: number): Observable<EmailQueueDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/email/getmemberemailhistory/{memberId}';
    url = url.replace('{memberId}', memberId.toString());
    return this.http.get<EmailQueueDTO[]>(url);
  }
  getEmailByEmailId(idEmail: string): Observable<EmailQueueDetailDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/email/getemailbyidemail/{idEmail}';
    url = url.replace('{idEmail}', idEmail.toString());
    return this.http.get<EmailQueueDetailDTO>(url);
  }

  openConfirmModal(
    title: string,
    showCancelButton: boolean,
    cancelText: string,
    cssClass: string,
    message?: string,
    isHTML?: boolean,
    showActionButton?: boolean,
    btnText?: string,
    imageSrc?: string
  ) {
    return this.modalService.open(ConfirmModalComponent, {
      data: {
        title: title,
        isHTML: isHTML ? isHTML : false,
        message: message,
        showCancelButton: showCancelButton,
        showActionButton: showActionButton,
        cancelText: cancelText,
        btnText: btnText,
        imageSrc: imageSrc,
      },
      modalClass: cssClass,
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }
}
