import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ParkingMapCriteria } from '../models/app';
import {
  MemberMobileParkingHistoryDTO,
  ParkingProviderDetails,
  ParkingProviderSimple,
  ProductSimpleInfoWithTermAndCondition,
} from '../models/parking';
import { AppConfigService } from './shared-services/app-config.service';
import { OrderProductInfo } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class ParkingService {
  apiName = 'parkingService';
  parkingProviders: any;
  parkingMapCriteria: ParkingMapCriteria;
  selectedParkingProvider: ParkingProviderSimple;
  selectedParkingProviderDetail: ParkingProviderDetails;
  searchType: string;
  URDetails: OrderProductInfo;
  isExisting: boolean;
  idParking: MemberMobileParkingHistoryDTO;
  constructor(private http: HttpClient) {}
  /**
   * @return Success
   */
  getParking(
    idCompany: number,
    idProductOfferingPlatform?: number
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Parking/Company/{idCompany}';
    if (idProductOfferingPlatform) {
      url = url + '&IdProductOfferingPlatform=' + idProductOfferingPlatform;
    }
    url = url.replace('{idCompany}', idCompany.toString());
    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }
  getParkingProductList(
    idCompany: number,
    tpaCode?: string,
    tpaCompanyId?: string,
    zipHome?: string,
    zipWork?: string,
    longitude?: number,
    latitude?: number,
    idMember?: number
  ): Observable<ProductSimpleInfoWithTermAndCondition[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Parking/getparkingproductlist?idCompany=' + idCompany;
    if (tpaCode) {
      url = url + '&tpaCode=' + tpaCode;
    }
    if (tpaCompanyId) {
      url = url + '&tpaCompanyId=' + tpaCompanyId;
    }
    if (zipHome) {
      url = url + '&zipHome=' + zipHome;
    }
    if (zipWork) {
      url = url + '&zipWork=' + zipWork;
    }
    if (longitude) {
      url = url + '&longitude=0'; //+ longitude.toString();
    }
    if (latitude) {
      url = url + '&latitude=0'; // + latitude.toString();
    }
    if (idMember) {
      url = url + '&IdMember=' + idMember;
    }

    return this.http.get<ProductSimpleInfoWithTermAndCondition[]>(url);
  }

  getParkingProviderDetail(idCompany: number, idMember?: number): Observable<ParkingProviderDetails> {
    let url = AppConfigService.apiURL(this.apiName) + '/Parking/provider/{idCompany}/member/{idMember}';
    url = url.replace('{idCompany}', idCompany.toString());
    url = url.replace('{idMember}', idMember.toString());
    return this.http.get<ParkingProviderDetails>(url);
  }

  getParkingProviderList(
    idMember?: number,
    zipRadius?: any,
    longitudeFrom?: any,
    latitudeFrom?: any,
    providerName?: string,
    isExclusiveForEmployerParking?: boolean
  ): Observable<ParkingProviderSimple[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Parking/providers?';
    if (idMember) {
      url = url + 'idMember=' + idMember.toString();
      // url = url + 'idMember=7846443';
    }
    if (zipRadius) {
      url = url + '&zipRadius=' + zipRadius.toString();
    }
    if (longitudeFrom) {
      url = url + '&longitudeFrom=' + longitudeFrom.toString();
    }
    if (latitudeFrom) {
      url = url + '&latitudeFrom=' + latitudeFrom.toString();
    }
    if (providerName) {
      url = url + '&providerName=' + providerName.toString();
    }
    url = url + '&isExclusiveForEmployerParking=' + isExclusiveForEmployerParking.toString();
    return this.http.get<ParkingProviderSimple[]>(url);
  }
  getExclusiveParkingProviderList(idCompany?: number): Observable<ParkingProviderSimple[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Parking/company/{idCompany}/exclusiveprovider';
    url = url.replace('{idCompany}', idCompany.toString());
    return this.http.get<ParkingProviderSimple[]>(url);
  }
  isOnParkingProviderWaitList(idMember: number, idParkingProvider: number) {
    const url: string =
      AppConfigService.apiURL(this.apiName) +
      '/Parking/' +
      'IsOnWaitList?idParkingProvider=' +
      idParkingProvider.toString() +
      '&IdMember=' +
      idMember.toString();
    return this.http.get(url);
  }
  getParkingBooking(idMember: string): Observable<MemberMobileParkingHistoryDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/parking/getparkingbookingbymember/{idMember}';
    if (idMember) {
      url_ = url_.replace('{idMember}', idMember);
    }
    return this.http.get<MemberMobileParkingHistoryDTO[]>(url_);
  }
  set setParkingProviders(parkingProviders: ParkingProviderSimple[]) {
    this.parkingProviders = parkingProviders;
  }
  get getParkingProviders(): ParkingProviderSimple[] {
    return this.parkingProviders;
  }
  set setParkingProvidersSearchCriteria(parkingMapCriteria: ParkingMapCriteria) {
    this.parkingMapCriteria = parkingMapCriteria;
  }
  get getParkingProvidersSearchCriteria(): ParkingMapCriteria {
    return this.parkingMapCriteria;
  }
  set setSelectedParkingProvider(parkingProvider: ParkingProviderSimple) {
    this.selectedParkingProvider = parkingProvider;
  }
  get getSelectedParkingProvider(): ParkingProviderSimple {
    return this.selectedParkingProvider;
  }

  set setSelectedParkingProviderDetail(parkingProvider: ParkingProviderDetails) {
    this.selectedParkingProviderDetail = parkingProvider;
  }
  get getSelectedParkingProviderDetail(): ParkingProviderDetails {
    return this.selectedParkingProviderDetail;
  }

  set setProviderSearchType(searchType: string) {
    this.searchType = searchType;
  }
  get getProviderSearchType(): string {
    return this.searchType;
  }
  set setUploadReceiptDetails(item: OrderProductInfo) {
    this.URDetails = item;
  }
  get getUploadReceiptDetails() {
    return this.URDetails;
  }
  set setIsExisting(item: boolean) {
    this.isExisting = item;
  }
  get getIsExisting() {
    return this.isExisting;
  }
  set setIdParking(item: MemberMobileParkingHistoryDTO) {
    this.idParking = item;
  }
  get getIdParking() {
    return this.idParking;
  }
}
