export enum Hours {
  TWENTYFOUR = 24,
}

export enum Days {
  SEVEN = 7,
}

export enum Months {
  ONE = 1,
  THREE = 3,
  SIX = 6,
  TWELVE = 12,
}

export enum ProductTypeAdminCode {
  DF = 1,
  SC = 2,
  V = 3,
  CCE = 4,
  CP = 5,
  VVV = 6,
  TRE = 7,
  RPCI = 8,
  SCC = 9,
  MLMR = 10,
  STV = 11,
  S2S = 12,
  ZONE2ZONE = 13,
  LIRR = 14,
  TLINK = 15,
  TLINKS = 16,
  CHARLIE = 17,
  VCTC = 18,
  VCTCS = 19,
  FCARD = 20,
  GSER = 21,
  PPC = 23,
  CCCPM = 24,
  VVDP = 25,
  GROUP = 26,
  VDP = 27,
  SMLD = 28,
  SNUM = 29,
  COMPCARDP = 30,
  COMPCARDNP = 31,
  AMC = 33,
  VGROUP = 35,
}

export enum ParkingTypes {
  PCCC = 7,
  SelfElect = 2,
  CashReimbursement = 3,
  DirectPay = 4,
  EmployerParking = 8,
  StackedCard = 9,
  ParkingCashout = 10,
}
export enum CartApprovalStatus {
  NEW = 1,
  PENDING = 2,
  APPROVED = 3,
  REJECT = 4,
  WAITINGLIST = 5,
}
export enum ParkingAdminCode {
  PCCC = 1,
  CHECK = 2,
  CR = 3,
  DP = 4,
  AUTOSWEEP = 5,
  PSC = 9,
}
export enum OrderTypeEnum {
  Transit = 1,
  Parking = 2,
  Bicycle = 3,
  Transit_Reimbursements = 4,
  Parking_Reimbursements = 5,
  Transit_Saved_Value = 6,
  Parking_Saved_Value = 7,
  Employer_Parking = 8,
  MBB = 10,
  BIKEMBB = 11,
  TicketRestaurant = 12,
  RIDEMBB = 13,
  BIKESHAREMBB = 14,
  PARKING_CASHOUT = 15,
}

export enum ClaimType {
  PRKCR = 1,
  GRH = 2,
  BIK = 4,
  CR = 5,
}

export enum ClaimReceiptType {
  Mail = 1,
  Fax = 2,
  eFax = 3,
  Web = 4,
}
export enum ClaimStatus {
  RCV = 4001,
  APR = 4002,
  PAPR = 4003,
  DND = 4004,
  PPMT = 4005,
  PFLR = 4006,
  PCHK = 4007,
  CLMP = 4008,
  APCLM = 4009,
  BRCV = 10001, //New
  BRAS = 10002, //Assigned
}

export enum ReimbursementOptions {
  NotSet = '0',
  Check = '1',
  DirectDeposit = '2',
}

export enum SmartCardDeliveryMethod {
  MAIL = 1,
  PICKUP = 2,
}
export enum LostPassPolicy {
  ZEROTOLERANCE = 0,
  ONCEPERLIFETIME = 1,
  ONCEPERYEAR = 2,
  TPAPAYINGMONTHLYPPTFEE = 3,
  ONCEEVERY2YEARS = 4,
  TWICEPERYEAR = 5,
}

export enum ProviderType {
  PRK = 1,
  GRH = 2,
}

export enum CRMIssueReceiptType {
  Mail = 1,
  Fax = 2,
  EFax = 3,
  WebUI = 4,
  Admin = 5,
}

export enum CRMIssueSubTypeEnum {
  NeverReceivedPass_IncorrectPass = 1,
  ParkingPaymentNotReceived = 2,
  ParkingVendorNotAvailable = 3,
  Parking_Other = 4,
  PPTCreditCard = 5,
  TransitAuthorityTransitPassNotAvailable = 6,
  TransitAuthority_SmartTripDC = 7,
  TransitAuthority_ChicagoCardPlus = 8,
  TransitAuthority_LongIslandRailRoad = 9,
  TransitAuthority_MetroNorthRailroad = 10,
  TransitAuthority_Other = 11,
  TechnicalProblem_SingleSignon = 12,
  TechnicalProblem_AllOther = 13,
  Other = 14,
  LostPass = 15,
  TransitAuthority_CharlieCard = 16,
  TransitAuthority_TransLink = 17,
  PassReturnedByUSPS = 18,
  IncorrectPass = 19,
  CCD_PRKCR = 20,
  TransitAuthority_VPSI = 21,
  TRSupport = 9001,
  TREnrollment = 9002,
  TROthers = 9003,
}

export enum CRMIssueTypeEnum {
  CRM = 1000,
  LostPass = 2000,
  PostCycleOrder = 3000,
  CommuterCard = 4000,
  TicketRestaurant = 9000,
}

export enum CRMIssueStatusEnum {
  GeneralNew = 1001,
  GeneralAssigned = 1002,
  GeneralClosed = 1003,
  GeneralCancelled = 1004,
  WaitingForTpaResponse = 1005,
  WaitingForMerchantResponse = 1006,
  WaitingForWiredCommuteResponse = 1007,
  ReOpen = 1008,
  NeedsPPTAction = 1009,
  LostPassNew = 2001,
  LostPassAssigned = 2002,
  LostPassClosed = 2003,
  LostPassCancelled = 2004,
  LostPassIssueCheck = 2005,
  LostPassApproved = 2006,
  LostPassDenied = 2007,
  TRNew = 9001,
  TRAssigned = 9002,
  TRClosed = 9003,
  TRCancelled = 9004,
}

export enum CRMLostPassClaimType {
  NoRefund = 5,
  RefundPPT = 4,
  OrderDiscrepency = 2,
  PassReturnedByPPT = 3,
  PassReturnedByUSPS = 1,
  PCCC = 6,
}
export enum DocumentTypeCode {
  OTHER = 1,
  W9 = 2,
  BL = 3,
  AI = 4,
  TE = 5,
  CRM = 6,
  PRKCR = 7,
  BIKCR = 8,
  ANM = 9,
  PRKAFFLCLM = 10,
}

export enum ClaimSubmissionStatus {
  CRT = 1,
  WTDOC = 2,
  SCMP = 3,
  GCRT = 7,
  GWTDOC = 8,
  GVCRT = 9,
  GSCMP = 10,
  BCRT = 12,
  BSCMP = 13,
}
export enum PMIType {
  ERP = 1,
  ERT = 2,
  ERE = 3,
  EBP = 4,
  EBT = 8,
  EBE = 9,
  EBS = 10,
  EBR = 12,
  EBN = 13,
  ECP = 14,
  ECE = 15,
  BCP = 16,
  BCT = 17,
  BCE = 18,
  BCS = 19,
  BCN = 20,
  EBA = 21,
  BCO = 22,
  ABN = 23,
}
export enum VendorType {
  MBB = 1,
  SF = 2,
  BU = 3,
  TR = 4,
}
export enum PCommuterCheckCardStatus {
  Active = 1,
  HotList = 2,
  Restricted = 4,
  New = 7,
  Closed = 9,
  RequestedReissue = 10,
}
export enum FulfillmentMethod {
  InternalMailed = 10,
  InternalElectronic = 11,
  ExternalMailed = 20,
  ExternalDirectFulfillment = 4,
  ExternalElectronic = 5,
  ExternalMobileFulfillment = 25,
}
export enum CardStatus {
  Active = 'Active',
  HotList = 'Hot Listed',
  Restricted = 'Restricted',
  New = 'New',
  Closed = 'Closed',
  RequestedReissue = 'RequestedReissue',
}
export enum TermsType {
  PPandTOFU = 1,
  PrepaidCardEsign = 2,
}
export enum CIPBankCode {
  BANCORP = 'BANCORP',
  CBKC = 'CBKC',
}
export enum CIPStatus {
  NONE = 0,
  NEW = 1,
  PENDING = 2,
  PASS = 3,
  FAIL = 4,
  REVOKE = 5,
  REVERIFY = 6,
}

export enum BBAccountType {
  Both = 1,
  PRKCR = 2,
  PRK = 3,
}

export enum CompanyCycleDay {
  One = '1',
  Two = '2',
  Three = '3',
}

export enum DayExtension {
  ST = 'st',
  ND = 'nd',
  RD = 'rd',
  TH = 'th',
}

export enum RegExpression {
  RoutingNumber = '^\\.\\.\\.\\d{4}$|^\\d{9}$',
  AccountNumber = '^\\.\\.\\.\\d{4}$|^\\d{5,17}$',
  CardNumber = '(^\\d{15,16}$)|(^^\\.\\.\\.\\d{4}$)',
  ZipCode = '^.{5,10}$',
  PhoneNumber = '^.{14}$',
  EmailAddress = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  Password = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%*&]).{8,}$',
  RegistrationZipCode = '[0-9]{5}(?:-[0-9]{4})?$',
  DateRegEx = '^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19[0-9]{2}|20[0-9]{2}|X{4}))*$',
}

export enum TPACode {
  NYC = 'NYC',
  CNY = 'CNY',
  CCD = 'CCD',
}

export enum CreditCardTextAlert {
  Default = "We ask for your credit card information in case your order total exceeds your company's pre-tax deduction cap.",
  TpaCode = 'Providing your debit or credit card information is optional. We request it in the event that your order amount exceeds your commuter account balance. If required, your card would be charged on the 11th of the month.',
  LyftRide = 'We ask for your credit card information in case the cost of your Lyft ride exceeds your available balance.',
}

export enum ReimbursementMethod {
  Check = 'Check',
  DirectDeposit = 'Direct Deposit',
}

export enum MailText {
  Default = 'Please allow 3-5 business days for your new Smart Card to arrive.',
  DynamicMailText = 'Your new smart card will be mailed via expedited mail service.',
}

export enum PCCCAccountType {
  TTX = 'TTX', //Post Tax
  TRN = 'TRN', //Transit
  PTX = 'PTX', //Parking Post Tax
  PRK = 'PRK', //Parking
  MBS = 'MBS', //MOBILITY SUBSIDY
  MBE = 'MBE',
}

export enum productNameEnum {
  AUTOSWEEP = 'autosweep',
}

export enum DueDiligenceStatus {
  NEW = 1,
  PND = 2,
  PASS = 3,
  RVK = 5,
  Addi = 6,
  REV = 7,
}
