<div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
  <div class="border-0">
    <div class="mx-0 p-2 row div-container">
      <div class="col-xl-9 col-md-9 col-lg-9 col-9 pb-2 pt-2">
        <h5 id="messageModalLabel" class="font-24 font-ubuntu font-weight-600 modal-title font-style-normal">
          {{ title }}
        </h5>
      </div>
      <div class="col-xl-3 col-md-3 col-lg-3 col-3 pr-0 text-right">
        <button type="button" aria-label="Close" class="bg-transparent border-0" (click)="closePopup()">
          <img src="assets/images/close.png" />
        </button>
      </div>
    </div>
    <div class="mt-2 modal-body pb-0 pl-2 pr-2" *ngIf="imageSrc">
      <div class="mt-lg-2 mx-0 row">
        <div class="col-xl-12 text-center mb-2">
          <img [src]="imageSrc" />
        </div>
      </div>
    </div>
    <div class="modal-body" *ngIf="!isHTML">
      <div class="row mt-3">
        <p class="font-14 font-weight-normal mb-0">{{ message }}</p>
      </div>
    </div>
    <div class="modal-body contentMessageModal m-4 custom-scroll-table-scheduled" *ngIf="isHTML">
      <div [innerHTML]="message | safeHTML"></div>
    </div>
  </div>
  <div class="mt-0 mt-lg-3 mx-0 pl-4 row mx-0">
    <div class="col-lg-6 col-xl-6 text-lg-start mb-4 pt-2"></div>
    <div class="col-lg-12 col-xl-6 text-lg-end">
      <div class="mx-0 row justify-content-end mb-4">
        <button
          *ngIf="showCancelButton"
          id="linkBack"
          name="linkBack"
          class="btn btn-secondary btn-custom ms-3 pe-4 ps-4 ml-3 text-black w-auto font-12"
          (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
