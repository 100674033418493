import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SubsidyService } from 'src/app/core/services/subsidy.service';
import { AppState } from '../app.reducer';
import * as fromSubsidy from '../subsidy/index';
import * as subsidyActions from '../subsidy/subsidy.action';

@Injectable({
  providedIn: 'root',
})
export class SubsidyDispatcher {
  constructor(private store: Store<AppState>, private subsidy: SubsidyService) {}
  dispatchSubsidy(idMember: number): void {
    this.store.dispatch(new subsidyActions.SubsidyLoad(idMember));
  }
  selectStore(): void {
    this.subsidy.subsidyData$ = this.store.select(fromSubsidy.getSubsidyData);
  }
}
