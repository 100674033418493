import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartDetailDTO } from 'src/app/core/models/shopping-cart';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import * as fromShoppingCart from '../shopping-cart/index';
import * as shoppingCartActions from '../shopping-cart/shopping-cart.action';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartDispatcher {
  constructor(private store: Store, private shoppingCartService: ShoppingCartService) {}
  dispatchShoppingCartDetails(idMember: number): void {
    this.store.dispatch(new shoppingCartActions.ShoppingCartLoad(idMember));
  }
  updateShoppingCartDetails(cart: CartDetailDTO[]): void {
    this.store.dispatch(new shoppingCartActions.ShoppingCartDetailsUpdate(cart));
  }
  selectStore(): void {
    this.store.select(fromShoppingCart.getShoppingCartByIdMember).subscribe((cartDetails: CartDetailDTO[]) => {
      if (cartDetails) {
        const productList = [];
        if (cartDetails) {
          cartDetails.forEach(cartDetailItem => {
            cartDetailItem.products.forEach(product => {
              productList.push(product);
            });
          });
          this.shoppingCartService.cartProductsCount$.next(productList.length);
          this.shoppingCartService.shoppingCartDetails$.next(cartDetails);
        }
      }
    });
  }
}
