import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { AppConfigService } from './services/shared-services/app-config.service';
@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            // Add the error status code in if condition if we want to retry a request for a specific HTTP Code.
            if (error.status != 400) {
              // Don't retry when we have 400 status error
              if (count < AppConfigService.settings.apiServer.retry) {
                return of(error);
              }
            }
            return throwError(error);
          }),
          delay(AppConfigService.settings.apiServer.retryWaitMilliSeconds)
        )
      )
    );
  }
}
