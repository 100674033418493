import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'eb-alert-modal.component',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    public modalRef: MdbModalRef<AlertModalComponent>
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
