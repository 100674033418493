import { CompanyDTO, CompanySettingDTO } from 'src/app/core/models/company';
import { CompanyActionTypes, CompanyActions } from './company.action';

export interface CompanyState {
  company: CompanyDTO[];
  idMember: number;
  companySettings: CompanySettingDTO;
  error: string;
}

const initialState: CompanyState = {
  company: [],
  idMember: undefined,
  companySettings: undefined,
  error: '',
};

export function companyReducer(state = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.COMPANY_LOAD_SUCCESS:
      return {
        ...state,
        company: [...action.data],
        error: '',
      };
    case CompanyActionTypes.COMPANY_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        companySettings: { ...action.data },
        error: '',
      };
    case CompanyActionTypes.COMPANY_SETTINGS_LOAD_FAIL:
    case CompanyActionTypes.COMPANY_LOAD_FAIL:
      return Object.assign({}, state, {
        error: action.data.error,
      });
    default:
      return state;
  }
}
