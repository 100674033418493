import { Action } from '@ngrx/store';
import { MemberDTO } from 'src/app/core/models/member';

export enum MemberActionTypes {
  MEMBER_LOAD = 'MEMBER_LOAD',
  MEMBER_LOAD_SUCCESS = 'MEMBER_LOAD_SUCCESS',
  MEMBER_LOAD_FAIL = 'MEMBER_LOAD_FAIL',
  MEMBER_UPDATE = 'MEMBER_UPDATE',
}

export class MemberLoad implements Action {
  readonly type = MemberActionTypes.MEMBER_LOAD;
  constructor(public idMember: number) {}
}

export class MemberLoadSuccess implements Action {
  readonly type = MemberActionTypes.MEMBER_LOAD_SUCCESS;
  constructor(public data: MemberDTO) {}
}

export class MemberLoadFail implements Action {
  readonly type = MemberActionTypes.MEMBER_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

export class MemberUpdate implements Action {
  readonly type = MemberActionTypes.MEMBER_UPDATE;
  constructor(public data: MemberDTO) {}
}
export type MemberActions = MemberLoad | MemberLoadSuccess | MemberLoadFail | MemberUpdate;
