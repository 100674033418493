import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';

import { ProductService } from 'src/app/core/services/product.service';
import { AppState } from '../app.reducer';
import * as productAction from './product.action';
@Injectable()
export class ProductEffect {
  constructor(private productService: ProductService, private action$: Actions, private store: Store<AppState>) {}

  loadProducts$ = createEffect(() =>
    this.action$.pipe(
      ofType(productAction.ProductActionTypes.PRODUCT_LOAD),
      switchMap((action: any) => {
        return this.productService
          .searchProducts(undefined, undefined, action.idCompany, action.transportationTypeCode, undefined)
          .pipe(
            map(productData => {
              return new productAction.ProductLoadSuccess(productData);
            }),
            catchError(err => of(new productAction.ProductLoadFail(err)))
          );
      })
    )
  );
}
