<div>
  <header>
    <div class="navbar f-navbar navbar-expand-lg onboarding-navbar d-none d-xl-block d-md-none d-lg-block">
      <nav class="f-navbar navbar navbar-expand-lg">
        <div class="mdb-navbar-brand">
          <a class="navbar-brand" (keyup.enter)="navigateToPage('Dashboard')" (click)="navigateToPage('Dashboard')">
            <img src="{{ logoUrl }}" style="font-weight: 600 !important" alt="" class="cursor-pointer" /><span
              class="program-label">
              Employee Benefits
            </span>
          </a>
        </div>
        <ul role="list" class="navbar-nav ml-auto navbar-icons" *ngIf="!amazonNotEligible">
          <li
            *ngIf="isAuthenticated && !isAmznDiffLocation"
            role="listitem"
            class="cart nav-item pb-lg-2 pl-lg-3 pr-lg-4 pt-lg-2"
            (keyup.enter)="navigateToReviewOrder()"
            (click)="navigateToReviewOrder()">
            <span class="nav-link"
              ><span class="font-12 pl-lg-2 pr-lg-3 text-gray text-nowrap" style="border-right: 1px solid #6d7787"
                ><p class="font-12 mb-lg-0 text-gray">Benefit Deadline</p>
                <p class="font-12 font-weight-bold mb-lg-0 ml-lg-4 mt-lg-2 text-gray">{{ fullDate }}</p></span
              ><br /><a class="font-12 font-weight-bold pl-lg-3 text-gray text-nowrap" tabindex="2"
                ><img src="assets/images/cart.png" alt="icon" class="mb-lg-1 ml-lg-3 mt-lg-1 mb-xl-0" />
                <a
                  [ngClass]="{ 'd-none': cartProductsCount === 0 }"
                  class="badge rounded-pill badge-notification cart_icon_color text-white">
                  {{ cartProductsCount }}
                </a>
                <br />
                Order Now
              </a>
            </span>
          </li>

          <li
            role="listitem"
            class="nav-item"
            (keyup.enter)="showHelpMenuPopup()"
            (click)="showHelpMenuPopup()"
            tabindex="3">
            <button class="nav-link bg-transparent border-0 text-white" aria-label="preserver">
              <!-- TODO:- <i class="fc fc-life-preserver"></i> -->
              <img src="assets/images/NewHelpMenu.svg" alt="icon" style="height: 40px" />
            </button>
          </li>
          <li
            role="listitem"
            class="nav-item"
            (keyup.enter)="showNotificationPopup()"
            (click)="showNotificationPopup()"
            tabindex="4"
            *ngIf="!isAmznDiffLocation">
            <button class="nav-link bg-transparent border-0 text-white" aria-label="preserver">
              <a
                class="badge rounded-pill badge-alert-notification notification_icon_color text-white"
                *ngIf="activeNotificationCount > 0">
                {{ activeNotificationCount }}
              </a>
              <img src="assets/images/notifications_svg.svg" alt="icon" style="height: 40px" />
            </button>
          </li>
          <li
            role="listitem"
            class="nav-item"
            style="display: none"
            (keyup.enter)="showAlertPopup()"
            (click)="showAlertPopup()"
            tabindex="5">
            <button class="bg-transparent border-0 nav-link text-white"><i class="fc fc-bell"></i></button>
          </li>
          <li
            role="listitem"
            class="nav-item"
            (keyup.enter)="showUserDetailPopup()"
            (click)="showUserDetailPopup()"
            tabindex="6">
            <button class="bg-transparent border-0 nav-link text-white">
              <i class="fc fas fc-person-circle"></i
              ><span class="user d-none d-md-flex d-sm-none d-md-none">
                <span *ngIf="isAuthenticated" class="name">
                  Hello,
                  {{ fullName }}
                </span>
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <div class="bg-white mx-0 row" *ngIf="isAuthenticated && isNavBarVisible && !amazonNotEligible">
    <div class="col-xl-1"></div>
    <div class="col-xl-10 col-12 pl-0 pr-0">
      <div class="d-none d-lg-block d-md-none">
        <ul class="list-inline pt-md-3 mb-lg-2 text-center topmenu">
          <li
            class="list-inline-item"
            tabindex="7"
            (keyup.enter)="navigateToPage('Dashboard')"
            (click)="navigateToPage('Dashboard')">
            <a class="sidenav-link" [ngClass]="{ active_link: isDashboardMenu }">
              <i class="fc fc-home font-20-xl font-17-md"></i
              ><span
                [attr.slim]="false"
                class="pl-2 font-16-xl font-15-md font-weight-600"
                [ngClass]="{ active_link: isDashboardMenu }"
                >Dashboard</span
              >
            </a>
          </li>
          <li
            *ngIf="isTrnPrkPCCCAllow && !isAmznDiffLocation"
            class="list-inline-item"
            tabindex="8"
            (keyup.enter)="navigateToPage('Cards')"
            (click)="navigateToPage('Cards')">
            <a class="sidenav-link" [ngClass]="{ active_link: isCardMenu }">
              <i class="fc fc-cards font-20-xl font-17-md"></i
              ><span class="pl-2 font-16-xl font-16-md font-weight-600" [ngClass]="{ active_link: isCardMenu }"
                >Card</span
              >
            </a>
          </li>
          <li
            *ngIf="!hideElectBenefits && !isAmznDiffLocation"
            class="list-inline-item"
            tabindex="9"
            (keyup.enter)="navigateToPage('ElectBenefits')"
            (click)="navigateToPage('ElectBenefits')">
            <a class="sidenav-link" [ngClass]="{ active_link: isElectBenefitMenu }">
              <i class="fc fc-transactions font-20-xl font-17-md"></i>
              <span class="pl-2 font-16-xl font-16-md font-weight-600" [ngClass]="{ active_link: isElectBenefitMenu }"
                >Elect Benefits</span
              >
            </a>
          </li>
          <li
            *ebAppRole="'ParkingCRRole|BikeCRRole|GRHRole|CRRole'"
            class="list-inline-item"
            tabindex="10"
            (keyup.enter)="navigateToPage('Claims')"
            (click)="navigateToPage('Claims')">
            <a class="sidenav-link" [ngClass]="{ active_link: isClaimMenu }">
              <i class="fc fc-money font-20-xl font-17-md"></i>
              <span class="pl-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isClaimMenu }"
                >Claims</span
              >
            </a>
          </li>
          <!-- <li
            class="list-inline-item"
            tabindex="4"
            (keyup.enter)="navigateToPage('Marketplace')"
            (click)="navigateToPage('Marketplace')">
            <a class="sidenav-link" style="display: none" [ngClass]="{ active_link: isMarketPlaceMenu }">
              <i class="fc fc-shoppingbag font-20-xl font-17-md"></i
              ><span class="pl-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isMarketPlaceMenu }"
                >Marketplace</span
              >
            </a>
          </li>
          <li
            class="list-inline-item"
            tabindex="5"
            (keyup.enter)="navigateToPage('Parking')"
            (click)="navigateToPage('Parking')">
            <a class="sidenav-link" style="display: none" [ngClass]="{ active_link: isParkingMenu }">
              <i class="fc fc-carparking font-20-xl font-17-md"></i
              ><span class="pl-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isParkingMenu }"
                >Parking</span
              >
            </a>
          </li> -->
        </ul>
      </div>
      <!-- tablet start menu -->
      <header>
        <div class="d-block d-lg-none d-md-block d-xl-none f-navbar navbar-expand-lg onboarding-navbar">
          <nav class="f-navbar navbar navbar-expand-lg position-fixed">
            <div class="mdb-navbar-brand w-50">
              <a
                class="d-flex mr-0 mt-3 navbar-brand pl-2 pr-0"
                (keyup.enter)="navigateToPage('Dashboard')"
                (click)="navigateToPage('Dashboard')">
                <img src="{{ logoUrl }}" style="font-weight: 600 !important" alt="icon" />
                <!-- <p class="font-endenred mb-0" style="color: #6d7787 !important; font-size: 18px">Benefits</p> -->
              </a>
            </div>

            <ul role="list" class="d-flex mb-0 ml-auto mr-auto navbar-icons pl-3">
              <li *ngIf="isAuthenticated && !isAmznDiffLocation" role="listitem" class="mr-3 nav-item">
                <a class="" (click)="navigateToReviewOrder()"
                  ><span class="font-12 font-weight-bold pl-lg-3 text-nowrap"
                    ><img src="assets/images/cart-white.svg" style="height: 20px" />
                    <span *ngIf="cartProductsCount > 0" class="badge rounded-pill badge-notification bg-danger">{{
                      cartProductsCount
                    }}</span>
                  </span></a
                >
              </li>
              <li
                role="listitem"
                class="nav-item"
                (keyup.enter)="showNotificationPopup()"
                (click)="showNotificationPopup()"
                tabindex="4"
                *ngIf="isAmznDiffLocation">
                <button class="nav-link bg-transparent border-0 text-white" aria-label="preserver">
                  <a
                    class="badge rounded-pill badge-alert-notification notification_icon_color text-white"
                    *ngIf="activeNotificationCount > 0">
                    {{ activeNotificationCount }}
                  </a>
                  <img src="assets/images/notifications_svg.svg" alt="icon" style="height: 20px" />
                </button>
              </li>
              <li role="listitem" class="nav-item">
                <a class="" (click)="showHelpMenuPopup()"
                  ><img src="assets/images/NewHelpMenu.svg" alt="icon" style="height: 20px"
                /></a>
              </li>
              <li role="listitem" class="nav-item" style="display: none">
                <a class="" (click)="showAlertPopup()"
                  ><img src="assets/images/notifications_svg.svg" alt="icon" class="ml-1"
                /></a>
              </li>
              <li role="listitem" class="nav-item" (click)="showUserDetailPopup()" style="margin-top: 3px">
                <a class="d-md-flex">
                  <img src="assets/images/profile_svg.svg" alt="icon" style="height: 20px" /><span
                    class="user d-none d-md-flex d-sm-none d-md-none">
                    <span *ngIf="isAuthenticated" class="name text-white">
                      Hello,
                      {{ fullName }}
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div class="d-none d-lg-none d-md-block">
        <ul class="list-inline mb-md-0 mt-md-4 pt-md-5 text-center topmenu">
          <li class="list-inline-item">
            <a class="sidenav-link" (click)="navigateToPage('Dashboard')">
              <i class="fc fc-home font-20-xl font-17-md" [ngClass]="{ active_link: isDashboardMenu }"></i
              ><span
                [attr.slim]="false"
                class="ml-2 font-16-xl font-15-md font-weight-600"
                [ngClass]="{ active_link: isDashboardMenu }"
                >Dashboard</span
              >
            </a>
          </li>
          <li class="list-inline-item" *ngIf="isTrnPrkPCCCAllow && !isAmznDiffLocation">
            <a class="sidenav-link" (click)="navigateToPage('Cards')">
              <i class="fc fc-cards font-20-xl font-17-md" [ngClass]="{ active_link: isCardMenu }"></i
              ><span class="ml-2 font-16-xl font-16-md font-weight-600" [ngClass]="{ active_link: isCardMenu }"
                >Card</span
              >
            </a>
          </li>
          <li class="list-inline-item" *ngIf="!hideElectBenefits && !isAmznDiffLocation">
            <a class="sidenav-link" (click)="navigateToPage('ElectBenefits')">
              <i class="fc fc-transactions font-20-xl font-17-md" [ngClass]="{ active_link: isElectBenefitMenu }"></i>
              <span class="ml-2 font-16-xl font-16-md font-weight-600" [ngClass]="{ active_link: isElectBenefitMenu }"
                >Elect Benefits</span
              >
            </a>
          </li>
          <li class="list-inline-item">
            <a
              class="sidenav-link"
              (click)="navigateToPage('Claims')"
              *ebAppRole="'ParkingCRRole|BikeCRRole|GRHRole|CRRole'">
              <i class="fc fc-money font-20-xl font-17-md" [ngClass]="{ active_link: isClaimMenu }"></i
              ><span class="ml-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isClaimMenu }"
                >Claims</span
              >
            </a>
          </li>
          <!-- <li class="list-inline-item">
            <a class="sidenav-link" (click)="navigateToPage('Marketplace')" style="display: none">
              <i class="fc fc-shoppingbag font-20-xl font-17-md" [ngClass]="{ active_link: isMarketPlaceMenu }"></i
              ><span class="ml-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isMarketPlaceMenu }"
                >Marketplace</span
              >
            </a>
          </li>
          <li class="list-inline-item">
            <a class="sidenav-link" (click)="navigateToPage('Parking')" style="display: none">
              <i class="fc fc-carparking font-20-xl font-17-md" [ngClass]="{ active_link: isParkingMenu }"></i
              ><span class="ml-2 font-16-xl font-15-md font-weight-600" [ngClass]="{ active_link: isParkingMenu }"
                >Parking</span
              >
            </a>
          </li> -->
        </ul>
      </div>
      <!-- tablet end menu -->
      <!-- Mobile topmenu start -->
      <header>
        <div class="navbar f-navbar navbar-expand-lg onboarding-navbar d-block d-xl-none d-md-none d-lg-none">
          <nav class="f-navbar navbar navbar-expand-lg position-fixed">
            <div class="mdb-navbar-brand w-50">
              <a
                class="d-flex mr-0 mt-3 navbar-brand pl-2 pr-0"
                (keyup.enter)="navigateToPage('Dashboard')"
                (click)="navigateToPage('Dashboard')">
                <img src="{{ logoUrl }}" style="font-weight: 600 !important" alt="icon" />
                <!-- <p class="font-endenred mb-0" style="color: #6d7787 !important; font-size: 18px">Benefits</p> -->
              </a>
            </div>

            <ul role="list" class="d-flex mb-0 ml-auto mr-auto navbar-icons pl-3">
              <li *ngIf="isAuthenticated && !isAmznDiffLocation" role="listitem" class="mr-3 nav-item">
                <a class="" (click)="navigateToReviewOrder()"
                  ><span class="font-12 font-weight-bold pl-lg-3 text-nowrap"
                    ><img src="assets/images/cart-white.svg" alt="icon" style="height: 20px" />
                    <span *ngIf="cartProductsCount > 0" class="badge rounded-pill badge-notification bg-danger">{{
                      cartProductsCount
                    }}</span>
                  </span></a
                >
              </li>
              <li
                role="listitem"
                class="nav-item"
                (keyup.enter)="showNotificationPopup()"
                (click)="showNotificationPopup()"
                tabindex="4"
                *ngIf="isAmznDiffLocation">
                <button class="nav-link bg-transparent border-0 text-white" aria-label="preserver">
                  <a
                    class="badge rounded-pill badge-alert-notification notification_icon_color text-white"
                    *ngIf="activeNotificationCount > 0">
                    {{ activeNotificationCount }}
                  </a>
                  <img src="assets/images/notifications_svg.svg" alt="icon" style="height: 20px" />
                </button>
              </li>
              <li role="listitem" class="nav-item">
                <a class="" (click)="showHelpMenuPopup()"
                  ><img src="assets/images/NewHelpMenu.svg" alt="icon" style="height: 20px"
                /></a>
              </li>
              <li role="listitem" class="nav-item" style="display: none">
                <a class="" (click)="showAlertPopup()"
                  ><img src="assets/images/notifications_svg.svg" alt="icon" class="ml-1"
                /></a>
              </li>
              <li role="listitem" class="nav-item" (click)="showUserDetailPopup()">
                <a class="">
                  <img src="assets/images/profile_svg.svg" alt="icon" style="height: 20px" /><span
                    class="user d-none d-md-flex d-sm-none d-md-none">
                    <!-- <span *ngIf="isAuthenticated" class="name">
                      Hello,
                      {{ fullName }}
                    </span> -->
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <!-- Mobile topmenu start -->
      <!-- Mobile menu start-->

      <div class="d-block d-lg-none d-md-none">
        <ul class="list-inline mb-0 pt-2 pb-2 text-center mbl_menu">
          <li class="list-inline-item mr-4">
            <a class="sidenav-link" (click)="navigateToPage('Dashboard')">
              <i class="fc fc-home" [ngClass]="{ active_link: isDashboardMenu }" style="font-size: 28px"></i
              ><span [attr.slim]="false" class="font-weight-600" [ngClass]="{ active_link: isDashboardMenu }"></span>
            </a>
          </li>
          <li class="list-inline-item mr-4" *ngIf="isTrnPrkPCCCAllow && !isAmznDiffLocation">
            <a class="sidenav-link" (click)="navigateToPage('Cards')">
              <i class="fc fc-cards" [ngClass]="{ active_link: isCardMenu }" style="font-size: 28px"></i
              ><span class="font-weight-600" [ngClass]="{ active_link: isCardMenu }"></span>
            </a>
          </li>
          <li class="list-inline-item mr-4" *ngIf="!hideElectBenefits && !isAmznDiffLocation">
            <a class="sidenav-link" (click)="navigateToPage('ElectBenefits')">
              <i class="fc fc-transactions" [ngClass]="{ active_link: isElectBenefitMenu }" style="font-size: 28px"></i>
              <span class="font-weight-600" [ngClass]="{ active_link: isElectBenefitMenu }"></span>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a
              class="sidenav-link"
              (click)="navigateToPage('Claims')"
              *ebAppRole="'ParkingCRRole|BikeCRRole|GRHRole|CRRole'">
              <i class="fc fc-money" [ngClass]="{ active_link: isClaimMenu }" style="font-size: 28px"></i
              ><span class="font-weight-600" [ngClass]="{ active_link: isClaimMenu }"></span>
            </a>
          </li>
          <!-- <li class="list-inline-item">
            <a class="sidenav-link" (click)="navigateToPage('Marketplace')" style="display: none">
              <i class="fc fc-shoppingbag" [ngClass]="{ active_link: isMarketPlaceMenu }" style="font-size: 28px"></i
              ><span class="font-weight-600" [ngClass]="{ active_link: isMarketPlaceMenu }"></span>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="sidenav-link" (click)="navigateToPage('Parking')" style="display: none">
              <i class="fc fc-carparking" [ngClass]="{ active_link: isParkingMenu }" style="font-size: 28px"></i
              ><span class="font-weight-600" [ngClass]="{ active_link: isParkingMenu }"></span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- Mobile menu start-->
    </div>
    <div class="col-xl-1"></div>
  </div>
</div>
<div class="row mx-0 pl-0 pr-0">
  <ng-template #globalErrorContainer></ng-template>
</div>
