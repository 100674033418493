import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardService } from 'src/app/core/services/card.service';
import { AppState } from '../app.reducer';
import * as cardActions from '../card/card.action';
import * as fromCard from '../card/index';

@Injectable({
  providedIn: 'root',
})
export class CardDispatcher {
  constructor(private store: Store<AppState>, private card: CardService) {}
  dispatchCards(idMember: number): void {
    this.store.dispatch(new cardActions.CardLoad(idMember));
  }
  selectStore(): void {
    this.card.cards$ = this.store.select(fromCard.getCards);
  }
}
