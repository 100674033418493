<div class="container">
  <div class="row eb-cards mt-4 mb-4">
    <div class="col-xl-4"></div>
    <div class="col-xl-4">
      <div class="border-0 card login-info p-2">
        <h4 class="font-weight-bold text-lg-center mt-4 mt-lg-4 font-24">Your Profile</h4>
        <div class="row mx-0 mt-lg-3">
          <div class="col-lg-12">
            <p class="font-16">
              Please note that your name and address cannot be changed in our system. If you need to make a change to
              your name or address, please reach out to your benefits provider.
            </p>
            <form [formGroup]="MyProfileForm">
              <mdb-form-control>
                <input mdbInput type="text" formControlName="email" class="form-control mt-4 mt-lg-4 input-height" />
                <div
                  class="error edenred-border-8"
                  *ngIf="MyProfileForm.get('email').invalid && MyProfileForm.get('email').touched">
                  <span *ngIf="MyProfileForm.get('email').hasError('required')">Email is required.</span>
                  <span *ngIf="MyProfileForm.get('email').hasError('email')">Please enter a valid email address.</span>
                </div>
              </mdb-form-control>
              <mdb-form-control>
                <input mdbInput type="number" formControlName="phone" class="form-control mt-4 mt-lg-4 input-height" />
                <div
                  class="error edenred-border-8"
                  *ngIf="MyProfileForm.get('phone').invalid && MyProfileForm.get('phone').touched">
                  <span *ngIf="MyProfileForm.get('phone').hasError('required')">Phone number is required.</span>
                  <span
                    *ngIf="
                      MyProfileForm.get('phone').hasError('pattern') && !MyProfileForm.get('phone').hasError('required')
                    "
                    >Please enter a valid 10-digit phone number.</span
                  >
                </div>
              </mdb-form-control>
              <mdb-form-control>
                <input mdbInput type="text" formControlName="address1" class="form-control" readonly />
                <div
                  class="error edenred-border-8"
                  *ngIf="MyProfileForm.get('address1').invalid && MyProfileForm.get('address1').touched">
                  <span *ngIf="MyProfileForm.get('address1').hasError('required')">Address 1 is required.</span>
                </div>
              </mdb-form-control>
              <mdb-form-control>
                <input mdbInput type="text" formControlName="address2" class="form-control" readonly />
                <div
                  class="error edenred-border-8"
                  *ngIf="MyProfileForm.get('address2').invalid && MyProfileForm.get('address2').touched">
                  <span *ngIf="MyProfileForm.get('address2').hasError('required')">Address 2 is required.</span>
                </div>
              </mdb-form-control>
              <mdb-form-control>
                <input mdbInput type="text" formControlName="city" class="form-control" readonly />
                <div
                  class="error edenred-border-8"
                  *ngIf="MyProfileForm.get('city').invalid && MyProfileForm.get('city').touched">
                  <span *ngIf="MyProfileForm.get('city').hasError('required')">City is required.</span>
                </div>
              </mdb-form-control>
              <div class="row align-items-center">
                <div class="col-xl-4">
                  <mdb-form-control>
                    <select mdbSelect class="form-select" id="stateid" formControlName="state">
                      <option selected="" role="option">MA</option>
                    </select>
                    <div mdbLabel for="floatingSelectGrid">State</div>
                  </mdb-form-control>
                </div>
                <div class="col-xl-4">
                  <mdb-form-control>
                    <input mdbInput type="number" class="form-control" id="zipid" value="12345" formControlName="zip" />
                    <div mdbLabel for="zipid">Zip</div>
                  </mdb-form-control>
                </div>
                <div class="col-xl-4">
                  <mdb-form-control>
                    <input
                      mdbInput
                      type="number"
                      class="form-control"
                      id="workzipid"
                      value="12345"
                      formControlName="workZip" />
                    <div mdbLabel for="workzipid">Work Zip</div>
                    <span class="infoiconCID" style="float: right; margin-top: -30px; margin-right: 10px"
                      ><i class="fc fc-info"></i
                    ></span>
                  </mdb-form-control>
                </div>
              </div>
              <div class="text-lg-end float-lg-end mt-4 mt-lg-4 mb-4 mb-lg-4">
                <a href="#" aria-label="Skip" class="font-16" [routerLink]="'/profile-setup'">Skip</a>
                <button
                  class="bold btn btn-primary d-inline font-16 text-center w-auto pl-4 pr-4"
                  type="submit"
                  aria-label="submit"
                  (click)="onUpdateMyProfile()"
                  [disabled]="MyProfileForm.invalid">
                  Finish Setup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4"></div>
  </div>
</div>
