<div class="container">
    <div class="row eb-cards mt-4 mx-0">
      <div class="col-lg-2 col-md-2 col-xl-4"></div>
      <div class="col-12 col-lg-8 col-md-8 col-xl-4">
        <div class="border-0 card login-info p-2 border_radius_15px">
          <h3 class="font-weight-bold mt-2 mt-lg-4 text-center text-lg-center text-md-center">New User Registration</h3>
          <div class="mt-3 mt-lg-3 mt-md-3 mx-0 row">
            <div class="col-lg-3 col-md-2 col-xl-2"></div>
            <div class="col-lg-6 col-md-8 col-xl-8">
              <div class="step-container d-flex justify-content-between mt-3 mt-lg-4">
                <div class="step-circle-wb mb-0">1</div>
                <div class="step-line">-----</div>
                <div class="step-circle mb-0">2</div>
                <div class="step-line">-----</div>
                <div class="step-without-circle mb-0">3</div>
              </div>
            </div>
            <div class="col-lg-3 col-md-2 col-xl-2"></div>
            <div class="col-lg-12 col-md-12 employee_verification">
              <h4 class="font-weight-bold text-center text-lg-center text-md-center">Tell us about you</h4>
            </div>
            <div class="row mx-0 pl-0 pr-0 col-lg-12">
              <ng-template #errorContainer> </ng-template>
            </div>
            <p class="mt-2 mt-lg-3 mt-md-3 mt-xl-2">
              Be sure to enter information as it currently appears in your employer's records.
            </p>
            <p>You may refer your paycheck to verify.</p>
            <form [formGroup]="nysemployeeVerificationForm">
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="employeeID"
                    type="text"
                    id="employeeID"
                    name="employeeID"
                    class="form-control mt-2 mt-md-3 mt-xl-3 mt-lg-3 input-height"
                    required />
                  <i
                    class="fc fc-info fc-info-gray employee-tooltip"
                    mdbTooltip="Employee ID can be found on your paystub or NYS payroll online.">
                  </i>
                  <label mdbLabel class="form-label" for="employeeID"> Employee ID</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="
                    nysemployeeVerificationForm.get('employeeID').invalid &&
                    nysemployeeVerificationForm.get('employeeID').touched
                  ">
                  <span *ngIf="nysemployeeVerificationForm.get('employeeID').hasError('required')">
                    Employee ID is required.
                  </span>
                </div>
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="departmentID"
                    type="text"
                    id="departmentID"
                    name="departmentID"
                    class="form-control mt-2 mt-md-3 mt-xl-3 mt-lg-3 input-height"
                    required />
                  <i
                    class="fc fc-info fc-info-gray employee-tooltip"
                    mdbTooltip="Department ID can be found on your paystub or NYS payroll online.">
                  </i>
                  <label mdbLabel class="form-label" for="departmentID"> Department ID</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="
                    nysemployeeVerificationForm.get('departmentID').invalid &&
                    nysemployeeVerificationForm.get('departmentID').touched
                  ">
                  <span *ngIf="nysemployeeVerificationForm.get('departmentID').hasError('required')">
                    Department ID is required.
                  </span>
                </div>
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="negotiatingUnit"
                    type="text"
                    id="negotiatingUnit"
                    name="negotiatingUnit"
                    class="form-control mt-2 mt-md-3 mt-xl-3 mt-lg-3 input-height"
                    required />
                  <i
                    class="fc fc-info fc-info-gray employee-tooltip"
                    mdbTooltip="Negotiating Unit can be found on your paystub or NYS payroll online.">
                  </i>
                  <label mdbLabel class="form-label" for="negotiatingUnit"> Negotiating Unit</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="
                    nysemployeeVerificationForm.get('negotiatingUnit').invalid &&
                    nysemployeeVerificationForm.get('negotiatingUnit').touched
                  ">
                  <span *ngIf="nysemployeeVerificationForm.get('negotiatingUnit').hasError('required')">
                    Negotiating Unit is required.
                  </span>
                </div>
              <div
                class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
                <a
                  style="color: #000; padding: 11px"
                  class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                  [routerLink]="'/employee-verification'">
                  Back
                </a>
                <button
                  class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
                  type="submit"
                  aria-label="Next"
                  (click)="verifyEnrollment()"
                  [disabled]="!nysemployeeVerificationForm.valid">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-4"></div>
    </div>
  </div>
  