import { MemberDTO } from 'src/app/core/models/member';
import { MemberActionTypes, MemberActions } from './member.action';

export interface MemberState {
  members: MemberDTO;
  idMember: number;
  error: string;
}

const initialState: MemberState = {
  members: undefined,
  idMember: undefined,
  error: '',
};

export function memberReducer(state = initialState, action: MemberActions): MemberState {
  switch (action.type) {
    case MemberActionTypes.MEMBER_LOAD_SUCCESS:
    case MemberActionTypes.MEMBER_UPDATE:
      return {
        ...state,
        members: { ...action.data },
        error: '',
      };
    case MemberActionTypes.MEMBER_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.data.error,
      });
    }
    default:
      return state;
  }
}
