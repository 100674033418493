import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';

import { MemberService } from 'src/app/core/services/member.service';
import { AppState } from '../app.reducer';
import * as memberAction from './member.action';
@Injectable()
export class MemberEffect {
  constructor(private memberService: MemberService, private action$: Actions, private store: Store<AppState>) {}

  loadMember$ = createEffect(() =>
    this.action$.pipe(
      ofType(memberAction.MemberActionTypes.MEMBER_LOAD),
      switchMap((action: any) => {
        return this.memberService.member(action.idMember).pipe(
          map(memberData => {
            return new memberAction.MemberLoadSuccess(memberData);
          }),
          catchError(err => of(new memberAction.MemberLoadFail(err)))
        );
      })
    )
  );
}
