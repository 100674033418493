import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ShoppingCartMode } from 'src/app/shared/app.constants';
import { AppUtils } from '../app-utils';
import {
  CartDetailDTO,
  CartSummaryDTO,
  CreateOrUpdateTempCartDTO,
  DeleteCartProductDTO,
  UpdateCartDTO,
} from '../models/shopping-cart';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  apiName = 'shoppingCartService';
  cartProductsCount$ = new BehaviorSubject<number>(0);
  public cartProductsCount = this.cartProductsCount$.asObservable();
  private idShoppingcartTemp: number;
  private shoppingCartUpdate: number;
  private shoppingCartMode = ShoppingCartMode.Create;
  private shoppingCart: CartDetailDTO[];
  private cartType: string;
  public shoppingCartDetails$ = new BehaviorSubject<CartDetailDTO[]>([]);
  constructor(private http: HttpClient) {}

  public get getIdShoppingcartTemp() {
    return this.idShoppingcartTemp;
  }

  public set setIdShoppingcartTemp(idShoppingcartTemp: number) {
    this.idShoppingcartTemp = idShoppingcartTemp;
  }

  public get getCartProductsCount() {
    return this.cartProductsCount;
  }

  public set setCartProductsCount(count: number) {
    this.cartProductsCount$.next(count);
  }

  public get getShoppingCartMode(): string {
    return this.shoppingCartMode;
  }

  public set setShoppingCartMode(mode: string) {
    this.shoppingCartMode = mode;
  }

  public get getShoppingCart(): CartDetailDTO[] {
    return this.shoppingCart;
  }

  public set setShoppingCart(shoppingCart: CartDetailDTO[]) {
    this.shoppingCart = shoppingCart;
  }

  public set setShoppingCartUpdate(shoppingCart: number) {
    this.shoppingCartUpdate = shoppingCart;
  }
  public get getShoppingCartUpdate() {
    return this.shoppingCartUpdate;
  }
  public set setShoppingCartType(cartType: string) {
    this.cartType = cartType;
  }
  public get getShoppingCartType(): string {
    return this.cartType;
  }
  /**
   * @return Success
   */
  getShoppingCartByIdMember(idMember: number): Observable<CartSummaryDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/{idMember}';
    url = url.replace('{idMember}', idMember.toString());
    return this.http.get<CartSummaryDTO[]>(url);
  }
  /**
   * @return Success
   */
  getShoppingCartByIdCart(idShoppingCart: number): Observable<CartDetailDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/cart/{idShoppingCart}';
    url = url.replace('{idShoppingCart}', idShoppingCart.toString());
    return this.http.get<CartDetailDTO>(url);
  }
  /**
   * @return Success
   */
  getTempShoppingCartByIdCart(idShoppingCart: number): Observable<CartDetailDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-cart-details/{idShoppingCart}';
    url = url.replace('{idShoppingCart}', idShoppingCart.toString());
    return this.http.get<CartDetailDTO>(url);
  }
  /**
   * @return Success
   */
  getTempShoppingCartByIdMember(idMember: number): Observable<CartDetailDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-carts/{idMember}';
    url = url.replace('{idMember}', idMember.toString());
    return this.http.get<CartDetailDTO[]>(url);
  }
  /**
   * @return Success
   */
  createTempShoppingCart(createTempCartDTO: CreateOrUpdateTempCartDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-cart';

    return this.http.post<any>(url, createTempCartDTO);
  }
  /**
   * @return Success
   */
  createTempShoppingCartMultiple(createTempCartDTO: CreateOrUpdateTempCartDTO[]): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-carts';
    return this.http.post<any>(url, createTempCartDTO);
  }
  updateShoppingCart(updateCartDTO: UpdateCartDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/cart/update';

    return this.http.post<any>(url, updateCartDTO);
  }

  /**
   * @return Success
   */
  createShoppingCart(idShoppingCartTemp: number): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/carts?idShoppingCartTemp={idShoppingCartTemp}';
    url = url.replace('{idShoppingCartTemp}', idShoppingCartTemp.toString());
    return this.http.post<any>(url, null);
  }

  getShoppingCartTemp(): Observable<CartDetailDTO[]> {
    return this.getTempShoppingCartByIdMember(Number(AppUtils.memberId));
  }

  getTempCartSummaryByMemberId(idMember: number): Observable<CartSummaryDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/tempcartsummary/{idMember}';
    url = url.replace('{idMember}', idMember.toString());
    return this.http.get<CartSummaryDTO[]>(url);
  }

  deleteTempCartProduct(cartProduct: DeleteCartProductDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-cart/product/delete';
    return this.http.post<any>(url, cartProduct);
  }

  deleteProductDashboard(cartProduct: DeleteCartProductDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/product/delete';
    return this.http.post<any>(url, cartProduct);
  }
  validateProduct(idMember: number, idProduct: number, idCompany: number): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/validateproduct';
    url = url + '?idMember=' + idMember;
    url = url + '&idProduct=' + idProduct;
    url = url + '&idCompany=' + idCompany;
    return this.http.get<any>(url);
  }
  deleteTempCartVentraProduct(cartProduct: DeleteCartProductDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/ShoppingCart/temp-cart/ventra/product/delete';
    return this.http.post<any>(url, cartProduct);
  }
  deleteShoppingCartVentraProduct(cartProduct: DeleteCartProductDTO): Observable<any> {
    let url = AppConfigService.apiURL(this.apiName) + '/shoppingcart/ventra/product/delete';
    return this.http.post<any>(url, cartProduct);
  }
  getWaitList(idMember: number): Observable<any> {
    let url =
      AppConfigService.apiURL(this.apiName) +
      '/ShoppingCart/tempcartsimple/waitlist?idMember=' +
      idMember +
      '&idOrderType=8';
    return this.http.get<any>(url);
  }
}
