import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClaimService } from 'src/app/core/services/claim.service';
import { AppState } from '../app.reducer';
import * as claimActions from '../claim/claim.action';
import * as fromClaim from '../claim/index';

@Injectable({
  providedIn: 'root',
})
export class ClaimDispatcher {
  constructor(private store: Store<AppState>, private claim: ClaimService) {}
  dispatchClaims(idMember: number): void {
    this.store.dispatch(new claimActions.ClaimLoad(idMember));
  }
  selectStore(): void {
    this.claim.claims$ = this.store.select(fromClaim.getClaim);
  }
}
