import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMember from '../member/member.reducer';

export interface State {
  memberFeature: fromMember.MemberState;
}
export const MemberReducers: ActionReducerMap<State> = {
  memberFeature: fromMember.memberReducer,
};

const getMemberFeatureState = createFeatureSelector<fromMember.MemberState>('memberFeature');

export const getMember = createSelector(getMemberFeatureState, state => {
  return state.members;
});
export const idMember = createSelector(getMemberFeatureState, state => {
  return state.idMember;
});
