import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eb-username-recover',
  templateUrl: './username-recover.component.html',
  styleUrls: ['./username-recover.component.scss'],
})
export class UserNameRecoverComponent implements OnDestroy, OnInit {
  userNameRecoverForm!: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {
    console.info('hit HomeComponent');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  subscriptions: Subscription[] = [];

  ngOnInit(): void {}

  navigateToDashboard = (val: any) => {};
}
