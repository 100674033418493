import { Component } from '@angular/core';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { MessageType } from '../../app.constants';

@Component({
  selector: 'eb-error-message',
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {
  errorMessage = '';
  messageType = '';

  constructor(private notificationService: NotificationService) {}
  onClose(): void {
    this.notificationService.removeComponent();
  }
  getMessageTypeClass(messageType: string): string {
    let messageCssClass: string = 'error-message-container';
    switch (messageType) {
      case MessageType.Success:
        messageCssClass = 'success-message-container';
        break;
      case MessageType.Error:
        messageCssClass = 'error-message-container';
        break;
      case MessageType.Warning:
        messageCssClass = 'warning-message-container';
        break;
    }
    return messageCssClass;
  }

  getMessageIconClass(messageType: string): string {
    let messageIconCssClass: string = '';
    switch (messageType) {
      case MessageType.Success:
        messageIconCssClass = 'success-text';
        break;
      case MessageType.Error:
        messageIconCssClass = 'error-text';
        break;
      case MessageType.Warning:
        messageIconCssClass = 'warning-text';
        break;
    }
    return messageIconCssClass;
  }
}
