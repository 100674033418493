<div class="container">
    <div class="row eb-cards mt-4 mx-0">
      <div class="col-lg-2 col-md-2 col-xl-3"></div>
      <div class="col-12 col-lg-8 col-md-8 col-xl-6">
        <div class="border-0 card login-info p-2 border_radius_15px">
          <h3 class="font-weight-bold mt-2 mt-lg-4 text-center text-lg-center text-md-center">New User Registration</h3>
          <div class="m-3 m-lg-3 m-md-3 mx-0 row" *ngIf="!enableWebAppVersionV6">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-lg-3 mb-xl-2 mt-3 mt-lg-3 mt-xl-0 pl-0 pr-0">
              <div class="border-0">
                <div class="mx-0 p-2 pb-3 pt-3 row mt-3 error-message-container">
                  <div class="col-xl-12 col-12">
                    <p class="font-16 font-weight-normal mb-0 pt-2 error-text">
                      <span
                        >You are not authorized to access this version of the site at this time. Please contact your
                        company administrator.</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
              <a
                style="color: #000; padding: 11px"
                class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                [routerLink]="'/'">
                Back
              </a>
            </div>
          </div>
          <div class="row mx-0 pl-0 pr-0 col-lg-12">
            <ng-template #errorContainer> </ng-template>
          </div>
          <div class="mt-3 mt-lg-3 mt-md-3 mx-0 row" *ngIf="enableWebAppVersionV6">
            <div class="col-lg-3 col-md-2 col-xl-2"></div>
            <div class="col-lg-6 col-md-8 col-xl-8">
              <div class="step-container d-flex justify-content-between mt-3 mt-lg-4">
                <div class="step-circle-wb mb-0">1</div>
                <div class="step-line">-----</div>
                <div class="step-circle mb-0">2</div>
                <div class="step-line">-----</div>
                <div class="step-without-circle mb-0">3</div>
              </div>
            </div>
            <div class="col-lg-3 col-md-2 col-xl-2"></div>
            <div class="col-lg-12 col-md-12 employee_verification">
              <h4 class="font-weight-bold text-center text-lg-center text-md-center">Tell us about you</h4>
            </div>
            <form [formGroup]="nysTellUsAboutYouForm">
                <div class="row">
                  <div class="col-md-6">
                    <mdb-form-control>
                      <input
                        mdbInput
                        formControlName="firstName"
                        type="text"
                        id="firstName"
                        name="firstName"
                        class="form-control mt-4 mt-lg-4 input-height"/>
                      <label mdbLabel class="form-label" for="firstName">First Name</label>
                    </mdb-form-control>
                    <div
                      class="error edenred-border-8"
                      *ngIf="nysTellUsAboutYouForm.get('firstName').invalid && nysTellUsAboutYouForm.get('firstName').touched">
                      <span *ngIf="nysTellUsAboutYouForm.get('firstName').hasError('required')">First Name is required.</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <mdb-form-control>
                      <input
                        mdbInput
                        formControlName="lastName"
                        type="text"
                        id="lastName"
                        name="lastName"
                        class="form-control mt-4 mt-lg-4 input-height"/>
                      <label mdbLabel class="form-label" for="lastName">Last Name</label>
                    </mdb-form-control>
                    <div
                      class="error edenred-border-8"
                      *ngIf="nysTellUsAboutYouForm.get('lastName').invalid && nysTellUsAboutYouForm.get('lastName').touched">
                      <span *ngIf="nysTellUsAboutYouForm.get('lastName').hasError('required')">Last Name is required.</span>
                    </div>
                  </div>
                </div>
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="email"
                    type="text"
                    id="email"
                    name="email"
                    class="form-control mt-3 mt-lg-3 input-height"/>
                  <label mdbLabel class="form-label" for="email">Email</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="nysTellUsAboutYouForm.get('email').invalid && nysTellUsAboutYouForm.get('email').touched">
                  <span *ngIf="nysTellUsAboutYouForm.get('email').hasError('required')">Email is required.</span>
                </div>
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="address1"
                    type="text"
                    id="address1"
                    name="address1"
                    class="form-control mt-3 mt-lg-3 input-height"/>
                  <label mdbLabel class="form-label" for="address1">Address 1</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="nysTellUsAboutYouForm.get('address1').invalid && nysTellUsAboutYouForm.get('address1').touched">
                  <span *ngIf="nysTellUsAboutYouForm.get('address1').hasError('required')">Address 1 is required.</span>
                </div>
                <mdb-form-control>
                  <input
                    mdbInput
                    formControlName="address2"
                    type="text"
                    id="address2"
                    name="address2"
                    class="form-control mt-3 mt-lg-3 input-height"/>
                  <label mdbLabel class="form-label" for="address2">Address 2</label>
                </mdb-form-control>
                <div
                  class="error edenred-border-8"
                  *ngIf="nysTellUsAboutYouForm.get('address2').invalid && nysTellUsAboutYouForm.get('address2').touched">
                  <span *ngIf="nysTellUsAboutYouForm.get('address2').hasError('required')">Address 1 is required.</span>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <mdb-form-control>
                      <input
                        mdbInput
                        formControlName="city"
                        type="text"
                        id="city"
                        name="city"
                        class="form-control mt-3 mt-lg-3 input-height"/>
                      <label mdbLabel class="form-label" for="city">City</label>
                    </mdb-form-control>
                    <div
                      class="error edenred-border-8"
                      *ngIf="nysTellUsAboutYouForm.get('city').invalid && nysTellUsAboutYouForm.get('city').touched">
                      <span *ngIf="nysTellUsAboutYouForm.get('city').hasError('required')">City is required.</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control>
                      <input
                        mdbInput
                        formControlName="state"
                        type="text"
                        id="state"
                        name="state"
                        class="form-control mt-3 mt-lg-3 input-height"/>
                      <label mdbLabel class="form-label" for="state">State</label>
                    </mdb-form-control>
                    <div
                      class="error edenred-border-8"
                      *ngIf="nysTellUsAboutYouForm.get('state').invalid && nysTellUsAboutYouForm.get('state').touched">
                      <span *ngIf="nysTellUsAboutYouForm.get('state').hasError('required')">State is required.</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <mdb-form-control>
                      <input
                        mdbInput
                        formControlName="zipCode"
                        type="text"
                        class="form-control mt-3 mt-lg-3 input-height"
                        minlength="5"
                        maxlength="10"
                        ebOnlyNumber
                        ebZipCode
                        id="zipCode"
                        name="zipCode"
                        (input)="dynamicFieldValidation()" />
                      <label mdbLabel class="form-label" for="zipCode">Zip Code</label>
                    </mdb-form-control>
                    <div
                      class="error edenred-border-8"
                      *ngIf="nysTellUsAboutYouForm.get('zipCode').invalid && nysTellUsAboutYouForm.get('zipCode').touched">
                      <span *ngIf="nysTellUsAboutYouForm.get('zipCode').hasError('required')">Zip Code is required.</span>
                      <span *ngIf="nysTellUsAboutYouForm.get('zipCode').hasError('pattern')">Invalid Zip Code.</span>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!isDeptIDMappedToPayCyle">
                  <div class="col-xl-12">
                    <p class="mt-lg-2 text-lg-left pl-0 pr-0 font-weight-600">When do you currently get paid?</p>
                    <div class="d-flex mt-lg-3">
                      <div class="d-flex custom-radio">
                        <input
                          type="radio"
                          name="payCycle"
                          id="option1"
                          formControlName="option1"
                          (change)="onPayCycleChange('Wednesday')"/>
                        <label for="option1" class="ml-lg-2 font-16">Wednesday</label>
                      </div>
                      <div class="d-flex custom-radio ml-lg-4">
                        <input
                          type="radio"
                          name="payCycle"
                          id="option2"
                          formControlName="option2"
                          (change)="onPayCycleChange('Thursday')"/>
                        <label for="option2" class="ml-lg-2 font-16">Thursday</label>
                      </div>
                    </div>
                  </div>
                </div>                
                <div class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
                  <a
                    style="color: #000; padding: 11px"
                    class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                    [routerLink]="'/nys-employee-verification'">
                    Back
                  </a>
                  <button
                    aria-label="verifyEnrollment"
                    class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
                    type="submit"
                    (click)="verifyEnrollment()"
                    [disabled]="isDeptIDMappedToPayCyle ? !nysTellUsAboutYouForm.valid : !isPayCycleSelected() || !nysTellUsAboutYouForm.valid">
                    Next
                  </button>
                </div>
              </form>              
            </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
  