import { Action } from '@ngrx/store';
import { CompanyDTO, CompanySettingDTO } from 'src/app/core/models/company';

export enum CompanyActionTypes {
  COMPANY_LOAD = 'COMPANY_LOAD',
  COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS',
  COMPANY_LOAD_FAIL = 'COMPANY_LOAD_FAIL',
  COMPANY_SETTINGS_LOAD = 'COMPANY_SETTINGS_LOAD',
  COMPANY_SETTINGS_LOAD_SUCCESS = 'COMPANY_SETTINGS_LOAD_SUCCESS',
  COMPANY_SETTINGS_LOAD_FAIL = 'COMPANY_SETTINGS_LOAD_FAIL',
}

export class CompanySettingsLoad implements Action {
  readonly type = CompanyActionTypes.COMPANY_SETTINGS_LOAD;
  constructor(public idMember: number) {}
}

export class CompanySettingsLoadSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_SETTINGS_LOAD_SUCCESS;
  constructor(public data: CompanySettingDTO) {}
}

export class CompanySettingsLoadFail implements Action {
  readonly type = CompanyActionTypes.COMPANY_SETTINGS_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

export class CompanyLoad implements Action {
  readonly type = CompanyActionTypes.COMPANY_LOAD;
  constructor() {}
}

export class CompanyLoadSuccess implements Action {
  readonly type = CompanyActionTypes.COMPANY_LOAD_SUCCESS;
  constructor(public data: CompanyDTO[]) {}
}

export class CompanyLoadFail implements Action {
  readonly type = CompanyActionTypes.COMPANY_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}
// Union the valid types
export type CompanyActions =
  | CompanyLoad
  | CompanyLoadSuccess
  | CompanyLoadFail
  | CompanySettingsLoad
  | CompanySettingsLoadSuccess
  | CompanySettingsLoadFail;
