import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ResendEmailDTO } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { PageTitle } from 'src/app/shared/app.constants';

@Component({
  selector: 'eb-resend-email',
  templateUrl: './resend-email.component.html',
})
export class ResendEmailComponent implements OnInit {
  emailAddress: string | undefined;
  userName: string;
  emailResend: ResendEmailDTO | undefined;
  tpaCode: string;

  constructor(
    private userRegistrationService: UserRegistrationService,
    private authService: AuthService,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(PageTitle.Registration);
    if (this.route.snapshot.queryParamMap.get('e') && this.route.snapshot.queryParamMap.get('e') != null) {
      const email = this.route.snapshot.queryParamMap.get('e');
      this.emailAddress = atob(email);
    }
    if (this.route.snapshot.queryParamMap.get('u') && this.route.snapshot.queryParamMap.get('u') != null) {
      const userName = this.route.snapshot.queryParamMap.get('u');
      this.userName = atob(userName);

      this.authService
        .resendEmail({
          username: this.userName,
          redirectUri: window.location.origin + '/profile-setup',
        })
        .subscribe(() => {});
    }
  }

  ngOnInit() {
    this.emailAddress = this.userRegistrationService.getEmail;
  }

  emailResent() {
    let emailResend = {
      username: this.userRegistrationService.getUpdatedUsername,
      redirectUri: window.location.origin + '/profile-setup',
    };
    this.authService.resendEmail(emailResend).subscribe(() => {});
  }
}
