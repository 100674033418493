import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClaim from '../claim/claim.reducer';

export interface State {
  claimFeature: fromClaim.ClaimState;
}
export const Claimreducers: ActionReducerMap<State> = {
  claimFeature: fromClaim.claimReducer,
};

const getClaimFeatureState = createFeatureSelector<fromClaim.ClaimState>('claimFeature');

export const getClaim = createSelector(getClaimFeatureState, state => {
  return state.claims;
});

export const idMember = createSelector(getClaimFeatureState, state => {
  return state.idMember;
});
