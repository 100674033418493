import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MemberEnrollmentDTO, ValidateMemberDTO } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { PageTitle } from 'src/app/shared/app.constants';

@Component({
  selector: 'eb-nys-employee-verification',
  templateUrl: './nys-employee-verification.component.html',
})
export class NYSEmployeeVerificationComponent implements OnInit {
  @ViewChild('errorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  nysemployeeVerificationForm!: FormGroup;
  idCompany: number;
  tpaCode: string;
  tpaCompanyID: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userRegistrationService: UserRegistrationService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.titleService.setTitle(PageTitle.Registration);
  }

  ngOnInit() {
    this.nysemployeeVerificationForm = this.fb.group({
      employeeID: new FormControl('',[Validators.required]),
      departmentID: new FormControl('',[Validators.required]),
      negotiatingUnit: new FormControl('', [Validators.required])
    });
    let companyData = this.userRegistrationService.getCompanyData;
    if (companyData) {
      this.idCompany = companyData?.idCompany;
      this.tpaCode = companyData?.tpaCode;
      this.tpaCompanyID = companyData?.tpaCompanyID;
    }
  }
  verifyEnrollment() {
    this.notificationService.targetElement = this.errorContainer;
    const enrollment: MemberEnrollmentDTO = {
      idCompany: this.idCompany,
      tpaCode: this.tpaCode,
      tpaCompanyID: this.tpaCompanyID,
      tpaEmployeeID: this.nysemployeeVerificationForm.get('employeeID')?.value,
      departmentID: this.nysemployeeVerificationForm.get('departmentID')?.value,
      negotiatingUnit: this.nysemployeeVerificationForm.get('negotiatingUnit')?.value,
    };
    this.userRegistrationService.setEmployeeData(
      enrollment.tpaEmployeeID,
      enrollment.departmentID,
      enrollment.negotiatingUnit
    );
    this.authService.validateMemberEnrollment(enrollment).subscribe((response: ValidateMemberDTO) => {
      if (response) {
        this.userRegistrationService.setUserNameSubject = response;
        if(this.tpaCode === 'NYS') {
        this.router.navigate(['/nys-tell-us-about-you']);
        }
      }
    });
  }  
}