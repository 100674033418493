import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppConfig } from '../../models/appConfig';

@Injectable()
export class AppConfigService {
  static settings: any | undefined = {};
  apiName: string = '';
  constructor(private http: HttpClient) {}
  load(): Promise<AppConfig> {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(jsonFile)
        .toPromise()
        .then(response => {
          AppConfigService.settings = <AppConfig>response;
          resolve(response);
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
  static apiURL(apiName: string): string {
    return this.settings.apiServer.apiBaseURL + this.settings.apiServer[apiName];
  }
  static authApiURl(apiName: string): string {
    return this.settings.authApiServer.authApiBaseURL + this.settings.authApiServer[apiName];
  }
  static webApiURL(apiName: string): string {
    return this.settings.webApiServer.webApiBaseURL + this.settings.webApiServer[apiName];
  }
}
