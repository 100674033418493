import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSubsidy from '../subsidy/subsidy.reducer';

export interface State {
  subsidyFeature: fromSubsidy.SubsidyState;
}
export const subsidyReducers: ActionReducerMap<State> = {
  subsidyFeature: fromSubsidy.subsidyReducer,
};

const getSubsidyFeatureState = createFeatureSelector<fromSubsidy.SubsidyState>('subsidyFeature');

export const getSubsidyData = createSelector(getSubsidyFeatureState, state => {
  return state.subsidy;
});
export const idMember = createSelector(getSubsidyFeatureState, state => {
  return state.idMember;
});
