import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { oldWebsiteDTO } from '../models/app';
import {
  AcceptTerms,
  AchMemberSettingsDTO,
  AddressDTO,
  HolidayCalendarDTO,
  MemberCarryForwardDTO,
  MemberContactDTO,
  MemberCreditCardDTO,
  MemberCreditCardTypeDTO,
  MemberCreditDebitDTO,
  MemberDTO,
  MemberMBBTransactionDTO,
  MemberMobileWalletInfo,
  MemberNotificationDTO,
  MemberPhoneNo,
  SendVerifyCode,
  TransportationTypeDTO,
  VerifyMobileNumber,
} from '../models/member';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  apiName = 'memberService';
  member$!: Observable<MemberDTO>;
  memberCompanyId = '';
  workZipCode = '';
  homeZipCode = '';
  memberSubscription: Subscription;
  memberData: MemberDTO;
  markAsReadNotification$ = new BehaviorSubject(false);
  constructor(private http: HttpClient) {}
  ngOnDestroy(): void {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
  }
  member(idMember: string): Observable<MemberDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/{idMember}';
    url = url.replace('{idMember}', idMember);
    return this.http.get<MemberDTO>(url);
  }
  getTransportationTypes(idMember: string): Observable<TransportationTypeDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/MemberTransportation/{idMember}/TransportationTypes';
    url = url.replace('{idMember}', idMember);
    return this.http.get<TransportationTypeDTO[]>(url);
  }
  getAddress(idMember: string): Observable<AddressDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Address/{idMember}';
    url = url.replace('{idMember}', idMember);
    return this.http.get<AddressDTO[]>(url);
  }
  getMemberCompanyId(): number {
    let companyId: number;
    this.memberSubscription = this.member$.subscribe(res => {
      companyId = res?.idCompany;
    });
    return companyId;
  }
  getMemberTpaCode(): string {
    let tpaCode: string;
    this.memberSubscription = this.member$.subscribe(res => {
      tpaCode = res?.tpaCode;
    });
    return tpaCode;
  }
  getMemberData(): MemberDTO {
    let memberData: MemberDTO;
    this.memberSubscription = this.member$.subscribe(res => {
      memberData = res;
    });
    return memberData;
  }
  getMemberContactByMemberId(idMember: string): Observable<MemberContactDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/GetMemberContact/{idMember}';
    url = url.replace('{idMember}', idMember);
    return this.http.get<MemberContactDTO>(url);
  }
  updateMemberContact(memberContact: MemberContactDTO): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/UpdateMemberContact';
    return this.http.post<boolean>(url, memberContact);
  }
  public get getWorkZipCode(): string {
    return this.workZipCode;
  }

  public set setWorkZipCode(zipCode: string) {
    this.workZipCode = zipCode;
  }
  public get getHomeZipCode(): string {
    return this.homeZipCode;
  }

  public set setHomeZipCode(zipCode: string) {
    this.homeZipCode = zipCode;
  }
  getMemberCreditCard(idMember: string): Observable<MemberCreditCardDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/GetMemberCreditCard/{idMember}';
    url = url.replace('{idMember}', idMember);
    return this.http.get<MemberCreditCardDTO>(url);
  }
  getCreditCardType(): Observable<MemberCreditCardTypeDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/GetMemberCreditCardType';
    return this.http.get<MemberCreditCardTypeDTO>(url);
  }
  getMemberACHDetails(idMember: string, idClaimType: number = 1): Observable<AchMemberSettingsDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/GetMemberACHDetails?';
    url += 'memberId=' + idMember;
    url += '&idClaimType=' + idClaimType;
    return this.http.get<AchMemberSettingsDTO>(url);
  }
  updateMemberCreditInfo(MemberCreditCard: MemberCreditCardDTO): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/UpdateMemberCreditCardDetails';
    return this.http.post<boolean>(url, MemberCreditCard);
  }
  updateMemberACHInfo(AchMemberSettings: AchMemberSettingsDTO): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/UpdateMemberACHInformation';
    return this.http.post<boolean>(url, AchMemberSettings);
  }
  getMemberSmartCardSerialNumber(
    idMember: string,

    idTransitAuthority?: number,
    idProduct?: number
  ): Observable<string> {
    let url = AppConfigService.apiURL(this.apiName) + '/member/getmembersmartcardserialnumber?';
    url += 'IdMember=' + idMember;
    url += '&IdTransitAuthority=' + idTransitAuthority;
    url += '&IdProduct=' + idProduct;
    return this.http.get<string>(url);
  }
  getUpdateMemberFirstLogin(idMember: number, isFirstTimeLogin: boolean): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/member/updatememberfirstlogin?';
    url += 'IdMember=' + idMember;
    url += '&IsFirstTimeLogin=' + isFirstTimeLogin;
    return this.http.post<boolean>(url, null);
  }
  getMemberTAMobileFulfillmentInfo(idMember: string, idProduct?: number): Observable<AchMemberSettingsDTO> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/GetMemberACHDetails?';
    url += 'memberId=' + idMember;
    return this.http.get<AchMemberSettingsDTO>(url);
  }
  updateMemberZipCode(idMember: number, homeZipCode: string, workZipCode: string): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/updatememberorderzipcodes';
    const payload = {
      idMember: idMember,
      homeZipCode: homeZipCode.toString(),
      workZipCode: workZipCode.toString(),
    };
    return this.http.post<boolean>(url, payload);
  }
  getMemberCipStatus(idMember: number): Observable<number> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/{idMember}/getmembercipstatus';
    url = url.replace('{idMember}', idMember.toString());
    return this.http.get<number>(url);
  }
  // TODO :-
  // acceptTerms(idMember: number, termsType: number, IsPpandTofuaccepted: boolean): Observable<boolean> {
  //   let url = AppConfigService.apiURL(this.apiName) + '/Member/acceptterms';
  //   url += '?IdMember=' + idMember;
  //   url += '&termsType=' + termsType;
  //   url += '&isMarketingCommunicationsAccepted=' + IsPpandTofuaccepted;
  //   return this.http.post<boolean>(url, undefined);
  // }
  acceptTerms(acceptTerms: AcceptTerms): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/acceptterms';
    return this.http.post<boolean>(url, acceptTerms);
  }
  updateCreditCardInformation(creditCardInformation: MemberCreditCardDTO): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/updatemembercreditcarddetails';
    return this.http.post<boolean>(url, creditCardInformation);
  }
  validateMemberCIP(memberCIP: any): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/Member/validatecip';
    return this.http.post<boolean>(url, memberCIP);
  }
  getAllHolidays() {
    let url_ = AppConfigService.apiURL(this.apiName) + '/member/getallholidays';
    return this.http.get<HolidayCalendarDTO[]>(url_);
  }
  updateMemberMobileWallet(memberWalletInfo: MemberMobileWalletInfo): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/membermobile/updatemembermobilewalletinfo';
    if (memberWalletInfo.idMember) {
      url_ += '?IdMember=' + memberWalletInfo.idMember;
    }
    if (memberWalletInfo.mobilePhoneNumber) {
      url_ += '&MobilePhoneNumber=' + memberWalletInfo.mobilePhoneNumber;
    }
    if (memberWalletInfo.personalEmailAddress) {
      url_ += '&PersonalEmailAddress=' + memberWalletInfo.personalEmailAddress;
    }
    if (memberWalletInfo.lastFourDigits) {
      url_ += '&LastFourDigits=' + memberWalletInfo.lastFourDigits;
    }
    return this.http.post<boolean>(url_, memberWalletInfo);
  }

  updateMemberMobilePhone(memberPhoneNumber: MemberPhoneNo): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/membermobile/updatemembermobilephone';
    if (memberPhoneNumber?.idMember) {
      url_ += '?IdMember=' + memberPhoneNumber.idMember;
    }
    if (memberPhoneNumber?.mobilePhoneNumber) {
      url_ += '&MobilePhoneNumber=' + memberPhoneNumber.mobilePhoneNumber;
    }
    return this.http.post<boolean>(url_, memberPhoneNumber);
  }

  verifyMobileNumber(verifyMobileNumber: VerifyMobileNumber): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/membermobile/verifymobilenumber';
    if (verifyMobileNumber?.idMember) {
      url_ += '?IdMember=' + verifyMobileNumber.idMember;
    }
    if (verifyMobileNumber?.mobileVerificationCode) {
      url_ += '&MobileVerificationCode=' + verifyMobileNumber.mobileVerificationCode;
    }
    if (verifyMobileNumber?.productName) {
      url_ += '&ProductName=' + verifyMobileNumber.productName;
    }
    return this.http.post<boolean>(url_, verifyMobileNumber);
  }

  sendMobileVerificationCode(mobileCode: SendVerifyCode): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/membermobile/sendmobileverificationcode';
    if (mobileCode?.idMember) {
      url_ += '?IdMember=' + mobileCode.idMember;
    }
    if (mobileCode?.mobilePhoneNumber) {
      url_ += '&MobilePhoneNumber=' + mobileCode.mobilePhoneNumber;
    }
    return this.http.post<boolean>(url_, mobileCode);
  }

  getMemberCarryForwards(idMember: number): Observable<MemberCarryForwardDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/member/carryforwards';
    if (idMember) {
      url_ += '?IdMember=' + idMember;
    }
    return this.http.get<MemberCarryForwardDTO[]>(url_);
  }
  getMemberCreditDebit(idMember: number): Observable<MemberCreditDebitDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/member/creditdebit';
    if (idMember) {
      url_ += '?IdMember=' + idMember;
    }
    return this.http.get<MemberCreditDebitDTO>(url_);
  }
  deleteCreditCardInformation(idMemberCreditCard: number): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/member/deletecreditcardinformation';
    if (idMemberCreditCard) {
      url_ += '?IdMemberCreditCard=' + idMemberCreditCard;
    }
    return this.http.post<boolean>(url_, null);
  }
  getMemberSSOUrl(oldWebsiteDTO: oldWebsiteDTO): Observable<string> {
    let url = AppConfigService.apiURL(this.apiName) + '/memberprofile/getmemberssourl/{memberUniqueId}';
    if (oldWebsiteDTO?.MemberUniqueId) {
      url = url.replace('{memberUniqueId}', oldWebsiteDTO.MemberUniqueId);
    }
    return this.http.post<string>(url, null);
  }
  getMemberNotifications(idMember: string, idTpa: number, idCompany: number): Observable<MemberNotificationDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/member/getmembernotifications/{idMember}/{idTpa}/{idCompany}';
    url = url.replace('{idMember}', idMember.toString());
    url = url.replace('{idTpa}', idTpa.toString());
    url = url.replace('{idCompany}', idCompany.toString());

    return this.http.get<any>(url);
  }
  updateMemberSubsidyBucketType(idMember: number, idSubsidyBucket: number): Observable<boolean> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/member/membersubsidybuckettype';
    if (idMember) {
      url_ += '?IdMember=' + idMember;
    }
    if (idSubsidyBucket) {
      url_ += '&IdSubsidyBucketType=' + idSubsidyBucket;
    }
    return this.http.post<boolean>(url_, null);
  }
  getSayNotificationRead(idNotification: number, idMember: string): Observable<boolean> {
    let url = AppConfigService.apiURL(this.apiName) + '/member/saynotificationread/{idNotification}/{idMember}';
    url = url.replace('{idMember}', idMember.toString());
    url = url.replace('{idNotification}', idNotification.toString());

    return this.http.get<any>(url);
  }
  getMemberMBBTransactions(idMember: string): Observable<MemberMBBTransactionDTO[]> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/mbb/{idMember}/getmembermbbtransactions';
    if (idMember) {
      url_ = url_.replace('{idMember}', idMember);
    }
    return this.http.get<MemberMBBTransactionDTO[]>(url_);
  }
}
