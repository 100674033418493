<div class="eb-cards row mx-0 pl-0 pr-0">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="mx-0 p-2 pt-lg-3 pl-xl-3 pb-lg-3 row help-menu-header bg-header-color pt-3 pb-3">
        <div class="col-9 col-xl-9">
          <h5 class="modal-title font-weight-600" id="messageModalLabel">
            <i class="fc fc-bell font-40 text-red"></i><span class="font-weight-bold"> Alerts</span>
          </h5>
        </div>
        <div class="col-3 col-xl-3 pr-0 pr-lg-0 text-right">
          <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="modalRef.close('')">
            <img src="assets/images/close.png" class="close-focus" alt="icon" />
          </button>
        </div>
      </div>
      <div class="modal-body mt-2 p-0 pb-0">
        <div class="alert-filtering bg-header-color mt-lg-3 mx-0 pt-lg-3 row pt-2">
          <div class="col-6 font-weight-600">
            <div>
              All Alerts
              <i tabindex="0" class="fc fc-filter align-text-bottom ml-1 cursor-pointer"></i>
            </div>
          </div>
          <div class="col-6 text-right font-weight-600">
            Sort By <i class="fc fc-sort align-text-bottom cursor-pointer"></i>
          </div>
        </div>
        <div class="mx-0 row">
          <p class="bg-header-color mb-0 mb-lg-0 pb-2 pb-lg-2 pt-2 pt-lg-4">02/03/2021</p>
        </div>
        <div class="mx-0 pt-lg-2 row transaction-border">
          <div class="col-lg-12">
            <p class="font-12 mb-lg-2">Transaction</p>
          </div>
          <div class="col-lg-11">
            <p class="font-14 font-weight-bold mb-lg-0">Declined transaction on Josh’s Card ····1234</p>
            <p class="font-14">Attempt non-fuel item purchase on fuel only card</p>
          </div>
          <div class="col-lg-3"><i class="fc fc-delete"></i></div>
        </div>
        <div class="mx-0 pt-lg-2 row mt-lg-1 transaction-border">
          <div class="col-lg-12">
            <p class="font-12 mb-lg-2">Transaction</p>
          </div>
          <div class="col-lg-11">
            <p class="font-14 font-weight-bold mb-lg-0">Declined transaction on Kaley's Card ····1234</p>
            <p class="font-14">Invalid ID entered</p>
          </div>
          <div class="col-lg-3"><i class="fc fc-delete"></i></div>
        </div>
        <div class="mx-0 pt-lg-2 row mt-lg-1 Fraud-border">
          <div class="col-lg-12">
            <p class="font-12 mb-lg-2">Fraud</p>
          </div>
          <div class="col-lg-11">
            <p class="font-14 font-weight-bold mb-lg-0">Declined transaction on Mercedez Benz ····1234</p>
            <p class="font-14">Invalid ID entered</p>
          </div>
          <div class="col-lg-3"><i class="fc fc-delete"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>
