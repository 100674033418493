import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs/internal/observable/of';
import { MemberBBAdjustmentDTO } from 'src/app/core/models/claim';
import { ClaimService } from 'src/app/core/services/claim.service';
import { AppState } from '../app.reducer';
import * as claimAction from './claim.action';

@Injectable()
export class ClaimEffect {
  constructor(private claimService: ClaimService, private action$: Actions, private store: Store<AppState>) {}

  loadClaim$ = createEffect(() =>
    this.action$.pipe(
      ofType(claimAction.ClaimActionTypes.CLAIM_LOAD),
      switchMap((action: any) => {
        return this.claimService.getClaimBBAdjustment(action.idMember).pipe(
          map((claimsData: MemberBBAdjustmentDTO) => {
            return new claimAction.ClaimLoadSuccess(claimsData);
          }),
          catchError(err => of(new claimAction.ClaimLoadFail(err)))
        );
      })
    )
  );
}
