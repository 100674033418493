import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MemberDTO } from 'src/app/core/models/member';
import { MemberService } from 'src/app/core/services/member.service';
import * as memberActions from '../../../app/store/member/member.action';
import { AppState } from '../app.reducer';
import * as fromMember from '../member/index';

@Injectable({
  providedIn: 'root',
})
export class MemberDispatcher {
  constructor(private store: Store<AppState>, private member: MemberService) {}

  dispatchMember(idMember: number): void {
    this.store.dispatch(new memberActions.MemberLoad(idMember));
  }
  updateMemberStore(member: MemberDTO): void {
    this.store.dispatch(new memberActions.MemberUpdate(member));
  }
  selectStore(): void {
    this.member.member$ = this.store.select(fromMember.getMember);
  }
}
