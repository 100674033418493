import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { ResendEmailDTO } from 'src/app/core/models/auth';

@Component({
  selector: 'eb-verify-account',
  templateUrl: './verify-account.component.html',
})
export class VerifyAccountComponent implements OnInit {
  emailAddress: string | undefined;
  userName: string | undefined;
  emailResend: ResendEmailDTO | undefined;

  constructor(
    private userRegistrationService: UserRegistrationService,
    private authService: AuthService,
  ) {}
  
  ngOnInit() {
    let userName = this.userRegistrationService.getUserNameData
    this.userName = userName
      this.emailResend = {
        username: this.userName,
        redirectUri: window.location.origin + '/profile-setup'
    };
  };

  emailResent() {
    this.authService.resendEmail(this.emailResend).subscribe(()  => {
    });
  }
}