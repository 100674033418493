import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/core/app-utils';
import { oldWebsiteDTO } from 'src/app/core/models/app';
import { CompanySettingDTO } from 'src/app/core/models/company';
import { MemberDTO } from 'src/app/core/models/member';
import { CompanyService } from 'src/app/core/services/company.sevice';
import { MemberService } from 'src/app/core/services/member.service';
import { AppConfigService } from 'src/app/core/services/shared-services/app-config.service';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { AppDispatcher } from 'src/app/store/app.dispatcher';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MessageComponent } from 'src/app/shared/modal/message/message.component';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { ModalCssClass } from 'src/app/shared/app.constants';

@Component({
  selector: 'eb-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnDestroy, OnInit {
  isAuthenticated: boolean = false;
  subscriptions: Subscription[] = [];
  companySettingsSubscription: Subscription;
  memberSubscription: Subscription;
  companySettingsData: CompanySettingDTO | undefined;
  memberSettings: MemberDTO;
  authSubscription: Subscription;
  companyIdsToRedirectToOldSite = [];
  modalMessageRef: any | MdbModalRef<MessageComponent>;
  constructor(
    private router: Router,
    private authService: AuthService,
    private appDispatcher: AppDispatcher,
    private companyService: CompanyService,
    private memberService: MemberService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.companyIdsToRedirectToOldSite =
      AppConfigService.settings.globalConfig.companyIdsToRedirectToOldSite.split(',');
    if (this.authService.isAuthenticated) {
      this.memberSubscription = this.memberService.member$.subscribe(memberDetails => {
        if (memberDetails) {
          this.memberSettings = memberDetails;
          this.companySettingsSubscription = this.companyService.companySettings$?.subscribe(
            (companySettingsData: CompanySettingDTO) => {
              if (companySettingsData) {
                if (this.companyIdsToRedirectToOldSite.includes(companySettingsData?.tpaCompanyId)) {
                  let oldWebsiteDTO: oldWebsiteDTO = {};
                  oldWebsiteDTO.MemberUniqueId = this.memberSettings?.uniqueId;
                  oldWebsiteDTO.V = '1';

                  this.memberService.getMemberSSOUrl(oldWebsiteDTO).subscribe((response: any) => {
                    if (response) {
                      window.location.href = response.url;
                      this.showRedirectPopup(response.url);
                    }
                  });
                } else {
                  this.router.navigateByUrl(AppUtils.getDashboardUrl());
                }
              }
            }
          );
        }
      });
    } else {
      this.authService.login();
    }
  }

  showRedirectPopup(url: string) {
    this.modalMessageRef = this.notificationService.openPopupMessage(
      'Redirecting...',
      "You will be redirected to our home page shortly. If the redirection does not occur automatically, please click on this <a 'font-ubuntu font-weight-bold small text-decoration-underline text-muted' href='" + url + "'> <b> <i> Home Page Link </i> </b> </a>.",
      false, "",
      ModalCssClass.MessageModal, true, false, "",""
    );
  }
  ngOnDestroy() {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.companySettingsSubscription) {
      this.companySettingsSubscription.unsubscribe();
    }
  }
}
