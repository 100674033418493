import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor() {}
  private count = 0;
  public notify = new BehaviorSubject<any>('');
  public active = new BehaviorSubject<any>('');
  private completed = false;
  notifyObservable$ = this.notify.asObservable();
  activeObservable$ = this.active.asObservable();
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  public isActive(data: any) {
    if (data) {
      this.active.next(data);
    }
  }
  public set setPreviousStepsCompleted(stepCompletion: any) {
    this.completed = stepCompletion;
  }

  public get getPreviousStepsCompleted() {
    return this.completed;
  }
  public set setStepNumber(stepNo: number) {
    this.count = stepNo;
  }

  public get getStepNumber() {
    return this.count;
  }
}
