import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyDTO, ValidateMemberDTO } from '../models/auth';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  apiName = 'UserRegistrationService';
  companyData: any;
  usernamesubject: any;
  userNameUpdated: any;
  userNameData: string;
  employeeData: { employeeID: string; departmentID: string; negotiatingUnit: string };
  personalInfo: { firstName: string; lastName: string; email: string };
  updatedEmail: string;

  constructor(private http: HttpClient) {}

  public get getCompanyData(): CompanyDTO {
    return this.companyData;
  }
  public set setCompanyData(data: CompanyDTO) {
    this.companyData = data;
  }
  public get getUserNameSubject(): ValidateMemberDTO {
    return this.usernamesubject;
  }
  public set setUserNameSubject(username: ValidateMemberDTO) {
    this.usernamesubject = username;
  }
  public set setUpdatedUsername(username: string) {
    this.userNameUpdated = username;
  }
  public get getUpdatedUsername(): string {
    return this.userNameUpdated;
  }
  public get getUserNameData(): string {
    return this.userNameData;
  }

  public set setUserNameData(user: string) {
    this.userNameData = user;
  }
  public setEmployeeData(employeeID: string, departmentID: string, negotiatingUnit: string): void {
    this.employeeData = { employeeID, departmentID, negotiatingUnit };
  }
  public getEmployeeData(): { employeeID: string; departmentID: string; negotiatingUnit: string } {
    return this.employeeData;
  }
  public setPersonalInfo(firstName: string, lastName: string, email: string): void {
    this.personalInfo = { firstName, lastName, email };
  }
  public getPersonalInfo(): { firstName: string; lastName: string; email: string } {
    return this.personalInfo;
  }
  public setEmail(email: string): void {
    this.updatedEmail = email;
  }
  public get getEmail(): string {
    return this.updatedEmail;
  }
}
