import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'eb-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  title!: string;
  message!: string;
  footerNote!: string;
  isHTML = false;
  showActionButton = false;
  showCancelButton = false;
  btnText!: string;
  cancelText!: string;
  imageSrc!: string;

  closeAllPopUp = true;
  constructor(public modalRef: MdbModalRef<ConfirmModalComponent>) {}
  ngOnInit(): void {}
  closePopup(): void {
    this.modalRef.close(this.closeAllPopUp);
  }
}
