import { Component, OnInit } from '@angular/core';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';

@Component({
  selector: 'eb-introduce-user',
  templateUrl: './introduce-user.component.html',
})
export class IntroduceUserComponent implements OnInit {
  name: string;

  constructor(
    private userRegistrationService: UserRegistrationService  
  ) {}

  ngOnInit() {
    let userFirstName = this.userRegistrationService.getUserNameSubject;
    this.name = userFirstName?.firstName;
  }
}
