import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyService } from 'src/app/core/services/company.sevice';
import * as companyActions from '../../../app/store/company/company.action';
import * as fromCompany from '../../../app/store/company/index';
import { AppState } from '../app.reducer';

@Injectable({
  providedIn: 'root',
})
export class CompanyDispatcher {
  constructor(private store: Store<AppState>, private company: CompanyService) {}
  dispatchCompany(idMember: number): void {
    this.store.dispatch(new companyActions.CompanySettingsLoad(idMember));
  }
  selectStore(): void {
    this.company.companySettings$ = this.store.select(fromCompany.getCompanySettings);
  }
  // getCompanyData(): any {
  //   this.store.dispatch(new companyActions.CompanySettingsLoad());
  //   this.store.dispatch(new companyActions.CompanyLoad());
  //   return combineLatest([
  //     this.store.select(fromCompany.getCompanySettings),
  //     this.store.select(fromCompany.getCompany),
  //   ]);
  // }
}
