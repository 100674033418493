import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CompanyDTO, MemberEnrollmentDTO, ValidateMemberDTO, MemberEnrollmentRequest, MemberEnrollmentResponse} from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { PageTitle } from 'src/app/shared/app.constants';
import { RegExpression } from 'src/app/shared/app.enums';

@Component({
  selector: 'eb-nys-tell-us-about-you',
  templateUrl: './nys-tell-us-about-you.component.html',
})
export class NYSTellUsAboutYouComponent implements OnInit {
  @ViewChild('errorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  nysTellUsAboutYouForm!: FormGroup;
  company: CompanyDTO | undefined;
  member: MemberEnrollmentDTO | undefined;
  idCompany: number | undefined;
  tpaCode: string | undefined;
  tpaCompanyID: string | undefined;
  enableWebAppVersionV6: boolean;
  employeeID: string | undefined;
  departmentID: string | undefined;
  isDeptIDMappedToPayCyle: boolean;
  selectedPayCycle: string = '';
  userData: ValidateMemberDTO;
  idMember: number;
  code: number;
  message: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userRegistrationService: UserRegistrationService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.titleService.setTitle(PageTitle.Registration);
  }

  ngOnInit() {
    this.notificationService.targetElement = this.errorContainer;
    this.nysTellUsAboutYouForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
    });
    let companyData = this.userRegistrationService.getCompanyData;
    if (companyData) {
      this.idCompany = companyData?.idCompany;
      this.tpaCode = companyData?.tpaCode;
      this.tpaCompanyID = companyData?.tpaCompanyID;
      this.enableWebAppVersionV6 = companyData?.enableWebAppVersionV6;
    }
    this.userData = this.userRegistrationService.getUserNameSubject;
    if (this.userData) {
      this.isDeptIDMappedToPayCyle = this.userData?.isDeptIDMappedToPayCyle;
      this.prefillForm(this.userData);
      let employeeData = this.userRegistrationService.getEmployeeData();
      if (employeeData) {
        this.employeeID = employeeData?.employeeID;
        this.departmentID = employeeData?.departmentID;
      }
    }
  }
  
  dynamicFieldValidation(): void {
    if (this.nysTellUsAboutYouForm.get('zipCode').value?.length > 0) {
      this.nysTellUsAboutYouForm.get('zipCode').addValidators([Validators.pattern(RegExpression.RegistrationZipCode)]);
    } else {
      this.nysTellUsAboutYouForm.get('zipCode').removeValidators([Validators.pattern(RegExpression.RegistrationZipCode)]);
    }
  }

  onPayCycleChange(payCycle: string) {
    this.selectedPayCycle = payCycle;
  }

  isPayCycleSelected(): boolean {
    return this.selectedPayCycle === 'Wednesday' || this.selectedPayCycle === 'Thursday';
  }

  prefillForm(userData: ValidateMemberDTO) {
    this.nysTellUsAboutYouForm.patchValue({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.emailAddress,
      address1: userData?.address1,
      address2: userData?.address2,
      city: userData?.city,
      state: userData?.state,
      zipCode: userData?.zip,
    });
  }
  verifyEnrollment() {
    if (
      this.nysTellUsAboutYouForm.get('firstName')?.value !== this.userData.firstName ||
      this.nysTellUsAboutYouForm.get('lastName')?.value !== this.userData.lastName ||
      this.nysTellUsAboutYouForm.get('email')?.value !== this.userData.emailAddress ||
      this.nysTellUsAboutYouForm.get('address1')?.value !== this.userData.address1 ||
      this.nysTellUsAboutYouForm.get('address2')?.value !== this.userData.address2 ||
      this.nysTellUsAboutYouForm.get('city')?.value !== this.userData.city ||
      this.nysTellUsAboutYouForm.get('state')?.value !== this.userData.state ||
      this.nysTellUsAboutYouForm.get('zip')?.value !== this.userData.zip
    ) {
      const enrollmentRequest: MemberEnrollmentRequest = {
        tpaCode: this.tpaCode,
        tpaCompanyID: this.tpaCompanyID,
        tpaEmployeeID: this.employeeID,
        member: {
            firstName: this.nysTellUsAboutYouForm.get('firstName')?.value,
            lastName: this.nysTellUsAboutYouForm.get('lastName')?.value,
            emailAddress: this.nysTellUsAboutYouForm.get('email')?.value,
            division: this.departmentID
          },
        address: {
            address1: this.nysTellUsAboutYouForm.get('address1')?.value,
            address2: this.nysTellUsAboutYouForm.get('address2')?.value,
            city: this.nysTellUsAboutYouForm.get('city')?.value,
            state: this.nysTellUsAboutYouForm.get('state')?.value,
            zip: this.nysTellUsAboutYouForm.get('zipCode')?.value
          }
      };
      this.userRegistrationService.setPersonalInfo (
        this.nysTellUsAboutYouForm.get('firstName')?.value,
        this.nysTellUsAboutYouForm.get('lastName')?.value,
        this.nysTellUsAboutYouForm.get('email')?.value
      )
      this.authService.addUpdateMember(enrollmentRequest).subscribe((response: MemberEnrollmentResponse) => {
        if (response) {
          this.idMember = response?.idMember;
          this.code = response?.code;
          this.message = response?.message;
          this.router.navigate(['/set-up-your-account'])
        }
      });
    }
    if (!this.isDeptIDMappedToPayCyle) {
      this.authService.addOrUpdateCompanyPayroll(0, this.idCompany!, this.departmentID!, this.selectedPayCycle).subscribe(() => {});
    }
  }
}

