import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { ToastrType } from './toast.modal';

@Component({
  selector: 'eb-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  text: string | null = null;
  toastr: string = '';
  constructor(public notificationRef: MdbNotificationRef<ToastComponent>) {}

  getToastrTypeClass(toastrType: string): string {
    let toastrCssClass: string = 'toast-primary';
    switch (toastrType) {
      case ToastrType.Success:
        toastrCssClass = 'toast-success';
        break;
      case ToastrType.Error:
        toastrCssClass = 'toast-danger';
        break;
      case ToastrType.Warning:
        toastrCssClass = 'toast-warning';
        break;
      case ToastrType.Info:
        toastrCssClass = 'toast-info';
        break;
      default:
        toastrCssClass = 'toast-primary';
        break;
    }
    return toastrCssClass;
  }

  getToastrIconClass(toastrType: string): string {
    let toastrIconCssClass: string = '';
    switch (toastrType) {
      case ToastrType.Success:
        toastrIconCssClass = 'fa-check';
        break;
      case ToastrType.Error:
        toastrIconCssClass = 'fa-exclamation-circle';
        break;
      case ToastrType.Warning:
        toastrIconCssClass = 'fa-exclamation-triangle';
        break;
      case ToastrType.Info:
        toastrIconCssClass = 'fa-info-circle';
        break;
      default:
        toastrIconCssClass = '';
        break;
    }
    return toastrIconCssClass;
  }
}
