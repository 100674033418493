import { ContentManagementDTO } from 'src/app/core/models/content-management';
import { ContentManagementActionTypes, ContentManagementActions } from './content-management.action';

export interface ContentManagementState {
  content: ContentManagementDTO[];
  error: string;
}

const initialState: ContentManagementState = {
  content: [],
  error: '',
};

export function contentManagementReducer(
  state = initialState,
  action: ContentManagementActions
): ContentManagementState {
  switch (action.type) {
    case ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD_SUCCESS:
      return {
        ...state,
        content: [...action.data],
        error: '',
      };
    case ContentManagementActionTypes.CONTENT_MANAGEMENT_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.data.error,
      });
    }
    default:
      return state;
  }
}
