import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';

@Component({
  selector: 'eb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
  loginDisplay = false;
  termsAccepted: boolean = false;
  loginForm: FormGroup;
  userName: string = '';
  password: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    //localStorage.setItem('login', '0');
    this.loginForm = this.fb.group({
      userName: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      userName: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  onSubmit(data: any) {
    //     this.userName = data.userName;
    //     this.password = data.password;
    //     this.authService.login(this.userName,this.password)
    //        .subscribe((data: any) => {
    //           console.log("Is Login Success: " + data);
    //          if(data) this.router.navigate(['/dashboard']);
    //     });
  }
}
