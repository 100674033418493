import { MemberBBAdjustmentDTO } from 'src/app/core/models/claim';
import { ClaimActionTypes, ClaimActions } from './claim.action';

export interface ClaimState {
  claims: MemberBBAdjustmentDTO;
  idMember: number;
  error: string;
}

const initialState: ClaimState = {
  claims: {},
  idMember: undefined,
  error: '',
};

export function claimReducer(state = initialState, action: ClaimActions): ClaimState {
  switch (action.type) {
    case ClaimActionTypes.CLAIM_LOAD_SUCCESS:
      return {
        ...state,
        claims: { ...action.data },
        error: '',
      };
    case ClaimActionTypes.CLAIM_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.data.error,
      });
    }
    default:
      return state;
  }
}
