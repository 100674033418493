import { DebitCardDTO } from 'src/app/core/models/card';
import { CardActionTypes, CardActions } from './card.action';

export interface CardState {
  cards: DebitCardDTO[];
  idMember: number;
  error: string;
}

const initialState: CardState = {
  cards: undefined,
  idMember: undefined,
  error: '',
};

export function cardReducer(state = initialState, action: CardActions): CardState {
  switch (action.type) {
    case CardActionTypes.CARD_LOAD_SUCCESS:
      return {
        ...state,
        cards: [...action.data],
        error: '',
      };
    case CardActionTypes.CARD_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.data.error,
      });
    }
    default:
      return state;
  }
}
