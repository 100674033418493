import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CompanyDTO, MemberEnrollmentDTO, ValidateMemberDTO } from 'src/app/core/models/auth';
import { AuthService } from 'src/app/core/services/shared-services/auth.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';
import { UserRegistrationService } from 'src/app/core/services/user-registration.service';
import { MessageType, Messages, PageTitle } from 'src/app/shared/app.constants';
import { RegExpression } from 'src/app/shared/app.enums';

@Component({
  selector: 'eb-tell-us-about-you',
  templateUrl: './tell-us-about-you.component.html',
})
export class TellUsAboutYouComponent implements OnInit {
  @ViewChild('errorContainer', { static: true, read: ViewContainerRef }) errorContainer: ViewContainerRef;
  tellUsAboutYouForm!: FormGroup;
  company: CompanyDTO | undefined;
  member: MemberEnrollmentDTO | undefined;
  idCompany: number | undefined;
  tpaCode: string | undefined;
  tpaCompanyID: string | undefined;
  isFormValid = false;
  enableWebAppVersionV6: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userRegistrationService: UserRegistrationService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.titleService.setTitle(PageTitle.Registration);
  }

  ngOnInit() {
    this.notificationService.targetElement = this.errorContainer;
    this.tellUsAboutYouForm = this.fb.group({
      tpaEmployeeId: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      homeZipCode: new FormControl(''),
    });
    let companyData = this.userRegistrationService.getCompanyData;
    if (companyData) {
      this.idCompany = companyData?.idCompany;
      this.tpaCode = companyData?.tpaCode;
      this.tpaCompanyID = companyData?.tpaCompanyID;
      this.enableWebAppVersionV6 = companyData?.enableWebAppVersionV6;
    }
  }
  dynamicFieldValidation(): void {
    // TODO:-Checking validity of form on event.Rest of the validation on submit button.
    if (this.tellUsAboutYouForm.get('homeZipCode').value?.length > 0) {
      this.tellUsAboutYouForm.get('homeZipCode').addValidators([Validators.pattern(RegExpression.RegistrationZipCode)]);
    } else {
      this.tellUsAboutYouForm
        .get('homeZipCode')
        .removeValidators([Validators.pattern(RegExpression.RegistrationZipCode)]);
    }
    if (
      (this.tellUsAboutYouForm.get('firstName').value?.length > 0 &&
        this.tellUsAboutYouForm.get('lastName').value?.length > 0 &&
        this.tellUsAboutYouForm.get('homeZipCode').value?.length > 0) ||
      this.tellUsAboutYouForm.get('tpaEmployeeId').value.length > 0
    ) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  verifyEnrollment() {
    if (
      (this.tellUsAboutYouForm.get('firstName')?.value.length > 0 ||
        this.tellUsAboutYouForm.get('lastName')?.value.length > 0 ||
        this.tellUsAboutYouForm.get('homeZipCode')?.value.length > 0) &&
      this.tellUsAboutYouForm.get('tpaEmployeeId')?.value.length > 0
    ) {
      this.notificationService.displayMessage(Messages.empIDOrFirstName, MessageType.Error);
      return;
    }
    const enrollment: MemberEnrollmentDTO = {
      idCompany: this.idCompany,
      tpaCode: this.tpaCode,
      tpaCompanyID: this.tpaCompanyID,
      fname: this.tellUsAboutYouForm.get('firstName')?.value,
      lName: this.tellUsAboutYouForm.get('lastName')?.value,
      zip: this.tellUsAboutYouForm.get('homeZipCode')?.value,
      tpaEmployeeID: this.tellUsAboutYouForm.get('tpaEmployeeId')?.value,
    };
    this.authService.validateMemberEnrollment(enrollment).subscribe((response: ValidateMemberDTO) => {
      if (response) {
        this.userRegistrationService.setUserNameSubject = response;
        this.router.navigate(['/set-up-your-account']);
      }
    });
  }
}
