import { Component, Input, OnInit } from '@angular/core';

const DOCUMENT_PATH = 'assets/images/documents';

@Component({
  selector: 'eb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() IsVisible: boolean = true;
  year: string;
  constructor() {}

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
  }
}
