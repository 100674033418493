import { Action } from '@ngrx/store';
import { DebitCardDTO } from '../../core/models/card';

export enum CardActionTypes {
  CARD_LOAD = 'CARD_LOAD',
  CARD_LOAD_SUCCESS = 'CARD_LOAD_SUCCESS',
  CARD_LOAD_FAIL = 'CARD_LOAD_FAIL',
}

export class CardLoad implements Action {
  readonly type = CardActionTypes.CARD_LOAD;
  constructor(public idMember: number) {}
}

export class CardLoadSuccess implements Action {
  readonly type = CardActionTypes.CARD_LOAD_SUCCESS;
  constructor(public data: DebitCardDTO[]) {}
}

export class CardLoadFail implements Action {
  readonly type = CardActionTypes.CARD_LOAD_FAIL;
  constructor(public data: { error: string }) {}
}

// Union the valid types
export type CardActions = CardLoad | CardLoadSuccess | CardLoadFail;
