import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, isDevMode } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';
import { CardEffect } from './card/card.effects';
import { ClaimEffect } from './claim/claim.effects';
import { CompanyEffect } from './company/company.effects';
import { ContentManagementEffect } from './content-management/content-management.effects';
import { MemberEffect } from './member/member.effects';
import { ProductEffect } from './product/product.effects';
import { ShoppingCartEffect } from './shopping-cart/shopping-cart.effects';
import { SubsidyEffect } from './subsidy/subsidy.effects';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      ContentManagementEffect,
      MemberEffect,
      CardEffect,
      ClaimEffect,
      CompanyEffect,
      ProductEffect,
      ShoppingCartEffect,
      SubsidyEffect,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
  ],
  exports: [],
  providers: [],
})
export class AppStoreModule {
  constructor(@Optional() @SkipSelf() parentModule: AppStoreModule) {
    if (parentModule) {
      throw new Error('AppStoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
