<footer *ngIf="IsVisible" class="footer-bg d-xl-block d-md-block d-lg-block d-none">
  <div class="container-fluid p-3 mb-8 mb-lg-0 mb-md-0">
    <div class="row mx-0">
      <div class="col-xl-8 col-md-7">
        <div class="d-flex">
          <div class="">
            <a
            target="_blank"
            tabindex="37"
            aria-label="CA Residents"
            href="https://www.edenredbenefits.com/wp-content/uploads/2020/05/ECBS_CCPA_Notice.pdf"
            class="small text-dark text-decoration-underline font-12"
            >For CA Residents</a
          >
          </div>
          <div class="ml-4">
            <a
            target="_blank"
            aria-label="Privacy Policy"
            tabindex="38"
            href="https://edenredbenefits.com/wp-content/uploads/2020/03/ECBSPrivacyPolicy.pdf"
            class="small text-dark text-decoration-underline font-12"
            >Edenred Privacy Policy</a
          >
          </div>
          <div class="ml-4">
            <a
              target="_blank"
              tabindex="39"
              aria-label="Terms and Conditions"
              href="https://www.edenredbenefits.com/wp-content/uploads/2020/03/ECBSTermsofUse.pdf"
              class="small text-dark text-decoration-underline font-12"
              >Terms of Use</a
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-5 d-flex justify-content-sm-end">
        <p class="font-12 text-right mb-0 mt-lg-2">© {{ year }} Edenred Benefits LLC. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>

<!---For Mobile version-->
<footer *ngIf="IsVisible" class="footer-mbl-bg d-xl-none d-md-none d-lg-none d-block">
  <div class="container-fluid p-3 mb-8 mb-lg-0 mb-md-0">
    <div class="row mx-0">
      <div class="col-xl-8 col-md-7">
        <div class="d-flex">
          <div class="">
            <a
              target="_blank"
              aria-label="CA Residents"
              href="https://www.edenredbenefits.com/wp-content/uploads/2020/05/ECBS_CCPA_Notice.pdf"
              class="small text-dark text-decoration-underline font-12"
              >For CA Residents</a
            >
          </div>
          <div class="ml-4">
            <a
              target="_blank"
              aria-label="Privacy Policy"
              href="https://edenredbenefits.com/wp-content/uploads/2020/03/ECBSPrivacyPolicy.pdf"
              class="small text-dark text-decoration-underline font-12"
              >Edenred Privacy Policy</a
            >
          </div>
          <div class="ml-4">
            <a
              target="_blank"
              aria-label="Terms & Conditions"
              href="https://www.edenredbenefits.com/wp-content/uploads/2020/03/ECBSTermsofUse.pdf"
              class="small text-dark text-decoration-underline font-12"
              >Terms of Use</a
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-5 d-flex justify-content-sm-end">
        <p class="font-12 text-right mb-0 mt-lg-2">© {{ year }} Edenred Benefits LLC. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</footer>
