<div class="container">
  <div class="row eb-cards mt-4 mx-0">
    <div class="col-lg-3 col-md-2 col-xl-4"></div>
    <div class="col-lg-6 col-md-8 col-xl-4">
      <div class="border-0 card login-info p-2">
        <img src="assets/images/profile_setup.svg" alt="setup" class="img-fluid p-4 p-lg-4 p-md-4" />
        <h4 class="font-24 font-weight-bold mt-0 mt-lg-2 mt-md-0 text-lg-center text-center text-md-center">
          Your Profile Setup is Complete!
        </h4>
        <div class="row mx-0 mt-lg-3">
          <div class="col-lg-12 text-md-center text-center">
            <p class="font-16">Your registration is complete! Login to begin using your new account.</p>
          </div>
          <div class="align-items-center mb-lg-3 mb-md-3 mt-2 mb-3 mt-lg-2 mt-md-2 mx-0 row">
            <div class="col-xl-12 text-center employee_verification">
              <button
                class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
                aria-label="login"
                [routerLink]="'/'">
                Continue to login
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-2 col-xl-4"></div>
    </div>
  </div>
</div>
