<div class="container">
  <div class="row eb-cards mt-4 mx-0">
    <div class="col-lg-2 col-md-2 col-xl-2"></div>
    <div class="col-12 col-lg-8 col-md-8 col-xl-8">
      <div class="border-0 card login-info p-2 border_radius_15px">
        <h3 class="font-weight-bold mt-2 mt-lg-4 text-center text-lg-center text-md-center">New User Registration</h3>
        <div class="mt-3 mt-lg-3 mt-md-3 mx-0 row">
          <div class="col-lg-3 col-md-2 col-xl-4"></div>
          <div class="col-lg-6 col-md-8 col-xl-4">
            <div class="step-container d-flex justify-content-between mt-3 mt-lg-4">
              <div class="step-circle-wb mb-0">1</div>
              <div class="step-line">-----</div>
              <div class="step-circle-wb mb-0">2</div>
              <div class="step-line">-----</div>
              <div class="step-circle mb-0">3</div>
            </div>
          </div>
          <div class="col-lg-4 col-xl-4"></div>
          <div class="col-lg-12 employee_verification">
            <h4 class="font-weight-bold text-center text-lg-center text-md-center">Setup your account</h4>
          </div>
          <div class="row mx-0 pl-0 pr-0 col-lg-12">
            <ng-template #errorContainer> </ng-template>
          </div>
          <form [formGroup]="setUpYourAccountForm" class="col-lg-12">
            <div class="row">
              <div class="col-lg-5">
                <mdb-form-control *ngIf="!companySettings?.pptIsEmailAsUserName">
                  <input
                    mdbInput
                    formControlName="userName"
                    type="text"
                    class="form-control mt-4 mt-lg-4 input-height" />
                  <label mdbLabel class="form-label" for="userName">Username</label>
                  <div
                    class="error edenred-border-8"
                    *ngIf="
                      !companySettings?.pptIsEmailAsUserName &&
                      setUpYourAccountForm.get('userName').invalid &&
                      setUpYourAccountForm.get('userName').touched
                    ">
                    <span *ngIf="setUpYourAccountForm.get('userName').hasError('required')">Username is required.</span>
                    <span *ngIf="setUpYourAccountForm.get('userName').hasError('minlength')"
                      >Minimum length should be 8.</span
                    >
                  </div>
                </mdb-form-control>
                <mdb-form-control *ngIf="companySettings?.pptIsEmailAsUserName">
                  <input mdbInput formControlName="email" type="text" class="form-control mt-4 mt-lg-4 input-height" />
                  <label mdbLabel class="form-label" for="userName">Username</label>
                  <div
                    class="error edenred-border-8"
                    *ngIf="
                      companySettings?.pptIsEmailAsUserName &&
                      setUpYourAccountForm.get('email').invalid &&
                      setUpYourAccountForm.get('email').touched
                    ">
                    <span *ngIf="setUpYourAccountForm.get('email').hasError('required')">Username is required.</span>
                    <span *ngIf="setUpYourAccountForm.get('email').hasError('pattern')">Invalid Email Address.</span>
                  </div>
                </mdb-form-control>
                <mdb-form-control>
                  <input mdbInput formControlName="confirmEmail" type="text" class="form-control" />
                  <label mdbLabel class="form-label" for="Confirm Email">Confirm Email</label>
                  <div
                    class="error edenred-border-8"
                    *ngIf="
                      setUpYourAccountForm.get('confirmEmail').invalid &&
                      setUpYourAccountForm.get('confirmEmail').touched
                    ">
                    <span *ngIf="setUpYourAccountForm.get('confirmEmail').hasError('required')"
                      >confirm email is required.</span
                    >
                    <span *ngIf="setUpYourAccountForm.get('confirmEmail').hasError('pattern')"
                      >Invalid Email Address.</span
                    >
                  </div>
                </mdb-form-control>
                <mdb-form-control>
                  <input mdbInput type="password" id="password" class="form-control" formControlName="password" />
                  <label mdbLabel class="form-label" for="password">Password</label>
                  <span
                    class="error edenred-border-8"
                    *ngIf="
                      !setUpYourAccountForm?.get('password').valid &&
                      setUpYourAccountForm?.get('password')?.errors['required'] &&
                      (setUpYourAccountForm.get('password').dirty || setUpYourAccountForm.get('password').touched)
                    ">
                    Password is required
                  </span>
                </mdb-form-control>
                <mdb-form-control>
                  <input
                    mdbInput
                    type="password"
                    id="confirmPassword"
                    class="form-control"
                    formControlName="confirmPassword" />
                  <label mdbLabel class="form-label" for="confirmPassword">Confirm Password</label>
                  <span
                    class="error edenred-border-8"
                    *ngIf="
                      !setUpYourAccountForm?.get('confirmPassword').valid &&
                      setUpYourAccountForm?.get('confirmPassword')?.errors['required'] &&
                      (setUpYourAccountForm.get('confirmPassword').dirty ||
                        setUpYourAccountForm.get('confirmPassword').touched)
                    ">
                    Confirm Password is required
                  </span>
                  <span
                    class="error edenred-border-8"
                    *ngIf="
                      setUpYourAccountForm?.get('confirmPassword').value?.length > 0 &&
                      setUpYourAccountForm?.get('password').value !== setUpYourAccountForm?.get('confirmPassword').value
                    ">
                    Passwords Do not Match
                  </span>
                </mdb-form-control>
              </div>
              <div class="col-lg-7">
                <div class="card mt-4 p-3 border-0 shadow">
                  <p class="font-weight-bold">Password must contain:</p>
                  <p class="font-16 mb-2">
                    <img
                      alt="circle"
                      [src]="
                        isPasswordValid('length')
                          ? 'assets/images/check-circle-fill.svg'
                          : 'assets/images/circle-round.svg'
                      "
                      width="20" />
                    &nbsp;At least 8 characters
                  </p>
                  <p class="font-16 mb-2">
                    <img
                      alt="circle"
                      [src]="
                        isPasswordValid(['uppercase', 'lowercase'])
                          ? 'assets/images/check-circle-fill.svg'
                          : 'assets/images/circle-round.svg'
                      "
                      width="20" />
                    &nbsp;At least 1 uppercase and 1 lowercase character
                  </p>
                  <p class="font-16 mb-2">
                    <img
                      alt="circle"
                      [src]="
                        isPasswordValid('number')
                          ? 'assets/images/check-circle-fill.svg'
                          : 'assets/images/circle-round.svg'
                      "
                      width="20" />
                    &nbsp;At least 1 number
                  </p>
                  <p class="font-16 mb-2">
                    <img
                      alt="circle"
                      [src]="
                        isPasswordValid('special')
                          ? 'assets/images/check-circle-fill.svg'
                          : 'assets/images/circle-round.svg'
                      "
                      width="20" />
                    &nbsp;At least 1 special character (!@#$%+=)
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-4 mt-md-5 mt-xl-5 mx-0 pl-0 pr-0 row">
              <p class="col-12 font-16 pl-0 pr-0 text-lg-center">
                By clicking next, I agree to the
                <a
                  class="text-decoration-underline"
                  aria-label="Privacy Policy"
                  href="https://edenredbenefits.com/wp-content/uploads/2020/03/ECBSPrivacyPolicy.pdf"
                  target="_blank"
                  >Privacy Policy</a
                >
                and
                <a
                  class="text-decoration-underline"
                  aria-label="Terms"
                  href="https://www.edenredbenefits.com/wp-content/uploads/2020/03/ECBSTermsofUse.pdf"
                  target="_blank"
                  >Terms of Use</a
                >
              </p>
            </div>
            <div class="row">
              <div class="col-lg-2 col-xl-3"></div>
              <div class="col-lg-8 col-xl-6">
                <div class="custom-checkbox ml-2 ml-lg-0 ml-md-2 ml-xl-0 set-up">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="idEdenredUSA"
                    value="yes"
                    (change)="onCheckBox($event)" />
                  <label class="custom-control-label" for="idEdenredUSA"
                    >I would like to receive marketing communications from Edenred USA or their third party
                    affiliates.</label
                  >
                </div>
              </div>
              <div class="col-lg-2 col-xl-3"></div>
            </div>
            <div
              class="float-lg-end float-right float-md-right float-xl-right mb-4 mb-lg-4 mt-4 mt-lg-4 text-lg-end employee_verification">
              <!-- <button
                class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                [routerLink]="'/tell-us-about-you'">
                Back
              </button> -->
              <a
                style="color: #000; padding: 11px"
                class="bold btn btn-secondary d-inline font-12 text-center w-auto pl-4 pr-4 mr-xl-2 mr-md-2 mr-lg-2 mr-2"
                [routerLink]="backRoute">
                Back
              </a>
              <button
                aria-label="onNext"
                class="bold btn btn-primary d-inline font-12 text-center w-auto pl-4 pr-4"
                type="submit"
                (click)="onNext()"
                [disabled]="
                  !setUpYourAccountForm.valid ||
                  !isPasswordValid('length') ||
                  !isPasswordValid(['uppercase', 'lowercase', 'number', 'special'])
                ">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-2"></div>
</div>
