import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/core/app-utils';
import { MemberContactDTO } from 'src/app/core/models/member';
import { MemberService } from 'src/app/core/services/member.service';
import { NotificationService } from 'src/app/core/services/shared-services/notification.service';


@Component({
  selector: 'eb-my-profile',
  templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnDestroy, OnInit {
  MyProfileForm!: FormGroup;
  contactInfo: MemberContactDTO;
  phone: string = '';
  email: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  officeZip?: string = '';


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private memberService: MemberService,
  ) {
    this.MyProfileForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
      workZip: ['', [Validators.pattern(/^\d{5}$/)]],
    });

    this.loadContactInfo();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.loadContactInfo();
    this.updateFormWithContactInfo();
    this.onUpdateMyProfile();
  }

  navigateToDashboard = (val: any) => {};

  loadContactInfo() {
    this.memberService.getMemberContactByMemberId(AppUtils.memberId).subscribe(userInfo => {
      if (userInfo) {
        this.contactInfo = userInfo;
        this.updateFormWithContactInfo();
      }
    });
  }

  updateFormWithContactInfo() {
    this.MyProfileForm.patchValue({
      email: this.contactInfo?.emailAddress,
      phone: this.contactInfo?.phoneNumber,
      address1: this.contactInfo?.deliveryAddress?.address1,
      address2: this.contactInfo?.deliveryAddress?.address2,
      city: this.contactInfo?.deliveryAddress?.city,
      state: this.contactInfo?.deliveryAddress?.state,
      zip: this.contactInfo?.deliveryAddress?.zip,
      workZip: this.contactInfo?.officeAddress?.zip,
    });
  }

  onUpdateMyProfile() {
    if (this.isFormChanged()) {
      this.contactInfo.emailAddress = this.MyProfileForm.get('email').value;
      this.contactInfo.phoneNumber = this.MyProfileForm.get('phone').value;
      this.contactInfo.deliveryAddress.address1 = this.MyProfileForm.get('address1').value;
      this.contactInfo.deliveryAddress.address2 = this.MyProfileForm.get('address2').value;
      this.contactInfo.deliveryAddress.city = this.MyProfileForm.get('city').value;
      this.contactInfo.deliveryAddress.state = this.MyProfileForm.get('state').value;
      this.contactInfo.deliveryAddress.zip = this.MyProfileForm.get('zip').value;
      this.contactInfo.officeAddress.zip = this.MyProfileForm.get('workZip').value;

      this.memberService.updateMemberContact(this.contactInfo).subscribe(result => {
        if (result) {
          this.router.navigate(['/profile-setup']);
        } 
      });
    }
  }

  isFormChanged() {
    return this.MyProfileForm.dirty;
  }
}
