import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContentManagementService } from 'src/app/core/services/content-management.service';
import * as contentManagementActions from '../../../app/store/content-management/content-management.action';
import { AppState } from '../app.reducer';
import * as fromContentManagement from '../content-management/index';

@Injectable({
  providedIn: 'root',
})
export class ContentManagementDispatcher {
  constructor(private store: Store<AppState>, private contentManagementService: ContentManagementService) {}
  dispatchContent(): void {
    this.store.dispatch(new contentManagementActions.ContentManagementLoad());
    // this.contentManagementService.pageContent$ = this.store.select(fromContentManagement.getContent);
  }
  selectStore(): void {
    this.contentManagementService.pageContent$ = this.store.select(fromContentManagement.getContent);
  }
}
