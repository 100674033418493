import { SubsidyDTO } from 'src/app/core/models/subsidy';
import { SubsidyActionTypes, SubsidyActions } from './subsidy.action';

export interface SubsidyState {
  subsidy: SubsidyDTO[];
  idMember: number;
  error: string;
}

const initialState: SubsidyState = {
  subsidy: undefined,
  idMember: undefined,
  error: '',
};

export function subsidyReducer(state = initialState, action: SubsidyActions): SubsidyState {
  switch (action.type) {
    case SubsidyActionTypes.SUBSIDY_LOAD_SUCCESS:
      return {
        ...state,
        subsidy: [...action.data],
        error: '',
      };

    case SubsidyActionTypes.SUBSIDY_LOAD_FAIL:
      return Object.assign({}, state, {
        error: action.data.error,
      });
    default:
      return state;
  }
}
