import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderHistoryDetailsDTO, OrderInfo, OrderLetterDTO, OrderProductInfo } from '../models/order';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  apiName = 'orderService';
  electedIdOrder: number;
  recentOrder: any;

  constructor(private http: HttpClient) {}

  /**
   * @param dtStartDate (optional)
   * @param dtEndDate (optional)
   * @return Success
   */
  getOrder(memberUniqueId: string, startDate: string, endDate: string, idOrderType: number, recordCount: number): Observable<OrderHistoryDetailsDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Order?';
    url_ += 'MemberUniqueId=' + memberUniqueId;
    url_ += '&StartDate=' + startDate;
    url_ += '&EndDate=' + endDate;
    url_ += '&IdOrderType=' + idOrderType;
    if (recordCount) {
      url_ += '&RecordCount=' + recordCount;
    }
    return this.http.get<OrderHistoryDetailsDTO>(url_);
  }

  getOrderHistoryDetails(memberUniqueId: string, startDate: string, endDate: string, idOrderType: number, recordCount: number): Observable<OrderLetterDTO> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Order/OrderLetter';
    url_ += '?MemberUniqueId=' + memberUniqueId;
    url_ += '&StartDate=' + startDate;
    url_ += '&EndDate=' + endDate;
    url_ += '&IdOrderType=' + idOrderType;
    if (recordCount) {
      url_ += '&RecordCount=' + recordCount;
    }
    return this.http.get<OrderLetterDTO>(url_);
  }

  getOrderDetails(idOrder: number): Observable<OrderInfo> {
    let url_ = AppConfigService.apiURL(this.apiName) + '/Order/GetOrderDetails/{idOrder}';
    url_ = url_.replace('{idOrder}', idOrder.toString());
    return this.http.get<OrderInfo>(url_);
  }

  public get getIdOrder(): number {
    return this.electedIdOrder;
  }

  public set setIdOrder(IdOrder: number) {
    this.electedIdOrder = IdOrder;
  }

  public get getRecentOrder(): any {
    return this.recentOrder;
  }

  public set setRecentOrder(order: any) {
    this.recentOrder = order;
  }
}
