import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class MapsService {
  apiKey: string = 'AIzaSyAtodGl5rCaJPbObw10mH-0inrbfBcZlZQ';
  constructor(private http: HttpClient) {}

  geocodeAddress(address: string) {
    const url: string = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + this.apiKey;
    return this.http.get<any>(url);
  }
}
