import { ProductSimpleInfoWithTermAndCondition } from 'src/app/core/models/product';
import { ProductActionTypes, ProductActions } from './product.action';

export interface ProductState {
  products: ProductSimpleInfoWithTermAndCondition[];
  idCompany: number;
  transportationTypeCode: string;
  error: string;
}

const initialState: ProductState = {
  products: undefined,
  idCompany: undefined,
  transportationTypeCode: undefined,
  error: '',
};

export function productReducer(state = initialState, action: ProductActions): ProductState {
  switch (action.type) {
    case ProductActionTypes.PRODUCT_LOAD_SUCCESS:
      return {
        ...state,
        products: { ...action.payload },
        error: '',
      };
    case ProductActionTypes.PRODUCT_LOAD_FAIL: {
      return Object.assign({}, state, {
        error: action.payload.error,
      });
    }
    default:
      return state;
  }
}
