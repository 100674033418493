import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'eb-video',
  templateUrl: './video.component.html',
})
export class VideoComponent {
  mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  title = '';
  videoUrl = '';
  constructor(public modalRef: MdbModalRef<VideoComponent>, private http: HttpClient) {}
  pauseVideo(videoplayer) {
    videoplayer.nativeElement.play();
  }

  closePopup(data?: any): void {
    this.modalRef.close(data);
  }
  ngAfterViewInit() {
    // if ('MediaSource' in window && MediaSource.isTypeSupported(this.mimeCodec)) {
    //   const mediaSource = new MediaSource();
    //   (this.videoplayer.nativeElement as HTMLVideoElement).src = URL.createObjectURL(mediaSource);
    //   mediaSource.addEventListener('sourceopen', () => this.sourceOpen(mediaSource));
    // } else {
    //   console.error('Unsupported MIME type or codec: ', this.mimeCodec);
    // }
  }
  // sourceOpen(mediaSource) {
  //   const sourceBuffer = mediaSource.addSourceBuffer(this.mimeCodec);
  //   return this.http.get(this.videoUrl, { responseType: 'blob' }).subscribe(blob => {
  //     sourceBuffer.addEventListener('updateend', () => {
  //       mediaSource.endOfStream();
  //       this.videoplayer.nativeElement.play();
  //     });
  //     blob.arrayBuffer().then(x => sourceBuffer.appendBuffer(x));
  //   });
  // }
}
